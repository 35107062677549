import { Component, EventEmitter, Output } from '@angular/core';
import { AppService } from '@app/core/services/app.service';

@Component({
  selector: 'app-toolbar2',
  templateUrl: './toolbar2.component.html',
})
export class Toolbar2Component {
  @Output() menuIconClickEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService: AppService) {}

  public sidenavToggle(): any {
    this.menuIconClickEvent.emit();
  }
}
