import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { Menu } from '@app/theme/components/menu/menu.model';
import { Subscription } from 'rxjs';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit, OnDestroy {
  @Input() menuParentId: number;
  public menuItems: Menu[];
  private subscriptions: Subscription[] = [];

  constructor(public menuService: MenuService, private authStore: AuthComponentStore, private router: Router) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  nav(url: string): any {
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    const authStoreSubscription = this.authStore.state$.subscribe(() => {
      this.menuItems = this.menuService.getHorizontalMenuItems();
      this.menuItems = this.menuItems.filter((item) => item.parentId == this.menuParentId);
    });
    this.subscriptions.push(authStoreSubscription);
  }
}
