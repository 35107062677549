import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PagedList } from '@app/core/models/paged-list.model';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { PixelService } from 'ngx-pixel';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { AuthAction, AuthState, Profile } from './auth.model';
import { AuthService } from './auth.service';

const DEFAULT_STATE: AuthState = {
  action: AuthAction.INIT,
  token: null,
  isLoggedIn: false,
  profile: null,
  isAdmin: false,
  createdProfile: null,
  createdUser: null,
  verifyLoggedIn: false,
  verifyToken: null,
  loading: true,
  containerLoaded: false,
  favourites: null,
};

@Injectable({ providedIn: 'root' })
export class AuthComponentStore extends ComponentStore<AuthState> {
  constructor(private router: Router, private authService: AuthService, private helperUtils: HelperUtils, private localStorageService: LocalStorageService, @Inject(PLATFORM_ID) private platform: any, private pixel: PixelService) {
    super(DEFAULT_STATE);

    if (isPlatformBrowser(platform)) {
      // * REMEMBER ME
      if (this.localStorageService.getItem('token')) {
        this.patchState({
          isLoggedIn: true,
          token: JSON.parse(this.localStorageService.getItem('token')),
        });
        this.refreshProfile();
      } else {
        this.patchState({ loading: false });
      }
    } else {
      this.patchState({ loading: false });
    }
  }

  readonly login = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        this.patchState({ loading: true });
        return this.authService.login(options).pipe(
          tapResponse(
            (response: any) => {
              // console.warn(response);
              this.localStorageService.setItem('token', JSON.stringify(response));
              this.patchState({
                action: AuthAction.LOGIN,
                isLoggedIn: true,
                token: response,
              });
              this.patchState({ action: AuthAction.INIT });
              this.refreshProfile();
              this.router.navigate(['/']);
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: AuthAction.LOGIN_FAILED,
              });
              this.patchState({ action: AuthAction.INIT });

              this.logout();
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly loginFromVerify = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.login(options).pipe(
          tapResponse(
            (response: any) => {
              this.localStorageService.setItem('token', JSON.stringify(response));
              this.patchState({
                verifyLoggedIn: true,
                token: response,
              });
              this.patchState({ action: AuthAction.LOGIN });
              this.patchState({ action: AuthAction.INIT });
              this.helperUtils.displaySuccessToast('Almost there! Please create a profile');
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: AuthAction.LOGIN_FAILED,
              });
              this.patchState({ action: AuthAction.INIT });
              this.logout();
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly register = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.register(options).pipe(
          tapResponse(
            (response: any) => {
              this.router.navigate(['/auth/check-inbox']);
              this.patchState({ action: AuthAction.REGISTER });
              this.patchState({ action: AuthAction.INIT });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: AuthAction.REGISTER_FAILED,
              });
              this.patchState({ action: AuthAction.INIT });
              this.logout();
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchProfile = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        this.patchState({ loading: true });
        return this.authService.getProfile(options).pipe(
          tapResponse(
            (response: PagedList<Profile>) => {
              if (response.results.length > 0) {
                this.patchState({
                  profile: response.results[0],
                  isAdmin: response.results[0].groups.includes('Administrators'),
                  loading: false,
                });
                // ? FETCH FAVOURITES
                if (isPlatformBrowser(this.platform)) {
                  this.state$
                    .pipe(
                      take(1),
                      tap({
                        next: (state) => {
                          this.fetchFavourites({
                            id: state.profile.id,
                          });
                        },
                      }),
                    )
                    .subscribe();
                }
              } else {
                this.logout();
                this.helperUtils.displayErrorMessageToast('Please click the verification link in your email inbox and create a profile to login');
              }
            },
            (error: HttpErrorResponse) => {
              this.logout();
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly updateProfile = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.updateProfile(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Profile has been successfully updated!');
              this.refreshProfile();
            },
            (error: HttpErrorResponse) => {
              this.logout();
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly createProfile = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.createProfile(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                profile: response,
                isLoggedIn: true,
              });
              this.patchState({
                action: AuthAction.CREATE_PROFILE,
              });
              this.patchState({ action: AuthAction.INIT });
              this.helperUtils.displaySuccessToast('Profile successfully created! welcome to capx2!');
              this.router.navigate(['/']);
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: AuthAction.CREATE_PROFILE_FAILED,
              });
              this.patchState({ action: AuthAction.INIT });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly sendResetPasswordLink = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.sendResetPasswordLink(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Email Sent Successfully! Please check your inbox for details');
              this.router.navigate(['/auth/account']);
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly resetPassword = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.resetPassword(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Your password has been updated successfully');
              this.router.navigate(['/auth/account']);
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly changePassword = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.changePassword(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Your password has been updated successfully');
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly sendVerificationEmail = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.sendVerificationEmail(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Verification Email has been sent!');
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly verifyRegistration = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.verifyRegistration(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Account has been successfully verified! please login to proceed');
            },
            (error: HttpErrorResponse) => {
              this.router.navigate(['/']);
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchFavourites = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.getFavourites(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({ favourites: response });
            },
            (error: HttpErrorResponse) => {
              this.patchState({ favourites: [] });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly addFavourite = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        this.pixel.track('AddToWishlist', {
          contents: options?.id ?? 'Unknown Property ID',
        });
        return this.authService.addFavourite(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Property added to favourites');
              this.state$
                .pipe(
                  take(1),
                  tap({
                    next: (state) => {
                      this.fetchFavourites({
                        id: state.profile.id,
                      });
                    },
                  }),
                )
                .subscribe();
            },
            (error: HttpErrorResponse) => {
              this.helperUtils.displayErrorResponseToast(error);
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly removeFavourite = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.removeFavourite(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Property removed from favourites');

              this.state$
                .pipe(
                  take(1),
                  tap({
                    next: (state) => {
                      this.fetchFavourites({
                        id: state.profile.id,
                      });
                    },
                  }),
                )
                .subscribe();
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly contactUs = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.authService.contactUs(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('We have received your email, we will respond shortly.');
              this.patchState({ action: AuthAction.CONTACT });
              this.patchState({ action: AuthAction.INIT });
            },
            (error: HttpErrorResponse) => {
              if (error.status >= 400) {
              }

              if (error.status === 201) {
                this.helperUtils.displaySuccessToast('We have received your email, we will respond shortly.');
              }

              this.patchState({ action: AuthAction.CONTACT });
              this.patchState({ action: AuthAction.INIT });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  refreshProfile(): any {
    // this.helperUtils.displaySuccessToast('Welcome Back!');
    // this.router.navigate(['/']);
    const token = JSON.parse(this.localStorageService.getItem('token'));
    // console.log(token);
    if (token) {
      this.fetchProfile({ id: token.user_id });
    }
  }

  public logout(): any {
    this.patchState({
      isLoggedIn: false,
      token: null,
      profile: null,
      isAdmin: false,
      loading: false,
      favourites: null,
    });
    this.authService.logout();
  }
}
