<div *ngIf="false" fxFlex fxLayoutAlign="center" class="pt-4">
  <div fxLayout="column" fxLayoutGap="32px">
    <div *ngFor="let item of Ads(numberOfAds).fill(1)">
      <div
        fxShow.gt-lg
        fxHide
        [ngStyle]="{
          'background-color': isDevMode ? 'gainsboro' : 'transparent'
        }"
        style="width: 300px"
      >
        <!-- <ng-adsense [adFormat]="'vertical'"></ng-adsense> -->
      </div>
    </div>
    <div *ngFor="let item of Square(squareAds).fill(1)">
      <div
        fxShow.gt-lg
        fxHide
        [ngStyle]="{
          'background-color': isDevMode ? 'gainsboro' : 'transparent'
        }"
        style="height: 250px"
      >
        <!-- <ng-adsense [adFormat]="'square'"></ng-adsense> -->
      </div>
    </div>
  </div>
</div>
