import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-commercial-to-let',
  templateUrl: './commercial-to-let.component.html',
  styleUrls: ['./commercial-to-let.component.scss'],
})
export class CommercialToLetComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  public lengthOfLeaseOptions = [
    { name: '1 Year', value: '1' },
    { name: '2 Years', value: '2' },
    { name: '3 Years', value: '3' },
    { name: '4 Years', value: '4' },
    { name: '5 Years', value: '5' },
    { name: 'Negociable', value: '6' },
  ];

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      price: ['', Validators.required],
      poa: [null],
      avaliabilityImmediate: [false],
      avaliability: [null, Validators.required],
      extentGrossLettableArea: [null],
      occupancy: [1],
      lengthOfLeaseRequired: ['1'],
      beneficialOccupation: [false],
      tenantInstallationAllowance: [false],
      buildingType: [1],
      generator: [false],
      airconditioning: [false],
      lifts: [false],
      disabilityAccess: [false],
      kitchenette: [false],
      canteen: [false],
      rates: [''],
      levies: [''],
      securityFeatures: [null],
      opsCosts: [''],
      undercoverParkings: [null, Validators.required],
      openParkings: [null, Validators.required],
      zoning: ['Mixed Use', Validators.required],
      documents: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });
    this.subscriptions.push(formSubscription);

    const avaliabilitySubscription = this.featuresForm.controls.avaliability.valueChanges.subscribe((change) => {
      if (new Date(change).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) {
        return;
      }
      this.featuresForm.controls.avaliabilityImmediate.setValue(false);
    });

    const priceSubscription = this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });
    this.subscriptions.push(avaliabilitySubscription);
    this.subscriptions.push(priceSubscription);
  }

  avaliabilityChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.avaliability.setValue(new Date());
    } else {
      this.featuresForm.controls.avaliability.setValue(null);
    }
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
