import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number_suffix',
})
export class NumberSuffixPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    // hundreds
    if (input <= 999) {
      return input;
    }
    // thousands
    else if (input >= 1000 && input <= 999999) {
      return input / 1000 + 'K';
    }
    // millions
    else if (input >= 1000000 && input <= 999999999) {
      return input / 1000000 + 'M';
    }
    // billions
    else if (input >= 1000000000 && input <= 999999999999) {
      return input / 1000000000 + 'B';
    } else {
      return input;
    }
  }
}
