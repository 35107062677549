import { formatCurrency, formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Property } from '@app/core/stores/property/property.model';

@Injectable({ providedIn: 'root' })
export class PropertyUtils {
  public cleanProperty(property: any, options?: any): any {
    const diff = Math.abs(new Date().getTime() - new Date(property.created_date).getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (diffDays <= 5) {
      Object.assign(property, { new: true });
    }

    if (!options?.edit) {
      property.title = property.title.toUpperCase();
    }
    property.description = this.cleanMeasurement(property.description);
    if (property.title === '') {
      property.title = property.property_type.name + ' Property ' + property.listing_type.name;
      if (!options?.edit) {
        property.title = property.title.toUpperCase();
      }
    }

    if (!options?.edit) {
      if (property?.price > 0) {
        property.price /= 100;

        switch (property.listing_type.name) {
          case 'On Auction':
            property.formattedPrice = 'POA';
            break;
          case 'To Let':
            if (property.property_type.name === 'Residential') {
              property.formattedPrice = `${formatCurrency(property?.price, 'en-US', 'R ')} /month`;
              break;
            }
            if (property.property_type.name === 'Commercial') {
              // ! FIX MESSY DATA - NEEDS TO BE DONE IN THE BACKEND AS IT AFFECTS SEARCH RESULTS
              // if (property.price > 999) {
              //   let size = property.attributes.filter((x: any) => x.attribute[0].name === 'Building Size');
              //   if (size) {
              //     size = size[0].value;
              //     size = size.replace('m²', '').trim();
              //     if (property?.price && property?.price !== 0) {
              //       property.price = (property.price / size).toFixed(2);
              //     }
              //   }
              // }
              property.formattedPrice = `${formatCurrency(property?.price, 'en-US', 'R ')} /m²`;
              break;
            }
            if (property.property_type.name === 'Agricultural') {
              property.formattedPrice = `${formatCurrency(property?.price, 'en-US', 'R ')} /ha`;
              break;
            }
            if (property.property_type.name === 'Industrial') {
              property.formattedPrice = `${formatCurrency(property?.price, 'en-US', 'R ')} /m²`;
              break;
            }
          default:
            property.formattedPrice = `${formatCurrency(property?.price, 'en-US', 'R ')}`;
        }
      } else {
        property.formattedPrice = 'POA';
      }
    }

    property.title = this.cleanMeasurement(property.title);
    return property;
  }

  public cleanMeasurement(value: string): string {
    value = value.split('SQM').join('m²');
    value = value.split('M²').join('m²');
    value = value.split('M2').join('m²');
    value = value.split('m2').join('m²');
    value = value.split('Sqm').join('m²');
    value = value.split('sqm').join('m²');
    return value;
  }

  formatPropertyAttributesNewSearch = (response: any, skipFormatting: boolean) => {
    try {
      if (skipFormatting) {
        return response;
      }

      response?.results?.forEach((property: any) => {
        property?.attributes?.forEach((attribute: any) => {
          if (attribute.value == '0.0' || attribute.value == '0') {
            attribute.value = null;
          }

          if (attribute.value && attribute.value.includes('.0')) {
            attribute.value = attribute.value.split('.0').join('');
          }

          if (attribute.value) {
            attribute.value = this.cleanMeasurement(attribute.value);
          }

          switch (attribute.attribute[0].name) {
            case 'Building Size':
              if (attribute.value) {
                attribute.value = `${formatNumber(+attribute.value, 'en-US', '1.0-0')} m²`;
              }
              break;
            case 'Land Size':
              if (attribute.value) {
                attribute.value = `${formatNumber(+attribute.value, 'en-US', '1.0-0')} m²`;
              }
              break;
            case 'Garden Services':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Security Service':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Domestic Worker':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Wifi':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'DSTV':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Water':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Electricity':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Auction Date':
              if (attribute.value) {
                attribute.value = new Date(attribute.value).toDateString();
              }
              break;
            case 'Residential Building Type':
              if (attribute.value) {
                attribute.attribute[0].name = 'Type';
                attribute.value = +attribute.value === 1 ? 'Flat / Apartment' : +attribute.value === 2 ? 'Complex' : 'House';
              }
              break;
            case 'Power Type':
              if (attribute.value) {
                attribute.value = +attribute.value === 1 ? 'KVA' : 'AMPS';
              }
              break;
            case 'Boreholes':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Riperian Rights':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Water Rights':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Superlink access':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Property Type':
              if (attribute.value) {
                attribute.attribute[0].name = 'Type';
                attribute.value = +attribute.value === 1 ? 'Freehold' : +attribute.value === 2 ? 'Sectional Title' : 'Share Block';
              }
              break;
            case 'Power':
              if (attribute.value) {
                attribute.value = +attribute.value === 1 ? '100' : +attribute.value === 2 ? '125' : '150';
              }
              break;
            case 'Building Type':
              if (attribute.value) {
                attribute.attribute[0].name = 'Type';
                attribute.value = +attribute.value === 1 ? 'Stand-alone' : 'Building';
              }
              break;
            case 'Secure estate':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Pool':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Garden':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Pet friendly':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Canteen':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Kitchenette':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Lifts':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Air Conditioning':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Generator':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Disability access':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Occupancy':
              if (attribute.value) {
                attribute.value = +attribute.value === 1 ? 'Vacant' : +attribute.value === 2 ? 'Tenanted' : 'Multi Tenanted';
              }
              break;
            case 'Rental per m2':
              if (attribute.value) {
                attribute.name = 'Rental per m²';
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'Rental per ha':
              if (attribute.value) {
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'Extent':
              if (attribute.value) {
                attribute.value = `${attribute.value} ha`;
              }
              break;
            case 'Price per m2':
              if (attribute.value) {
                attribute.attribute[0].name = 'Price per m²';
                attribute.value = `R $.{attribute.value}`;
              }
              break;
            case 'Price per ha':
              if (attribute.value) {
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'Average Rainfall':
              if (attribute.value) {
                attribute.value = `${attribute.value} mm`;
              }
              break;
            case 'Phase3 Power':
              if (attribute.value) {
                attribute.attribute[0].name = '3 Phase Power';
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Avaliability':
              if (attribute.value) {
                attribute.attribute[0].name = 'Avaliable';
                attribute.value = new Date(attribute.value).toDateString();
              }
              break;
            case 'Lease Expiry Date':
              if (attribute.value) {
                attribute.value = new Date(attribute.value).toDateString();
              }
              break;
            case 'Ops costs':
              if (attribute.value) {
                attribute.attribute[0].name = 'Operation Costs';
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'Price':
              if (attribute.value) {
                attribute.value = null;
              }
              break;
            case 'Extent (Gross Lettable Area)':
              if (attribute.value) {
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'Levies':
              if (attribute.value) {
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'Size':
              if (attribute.value) {
                attribute.value = `${formatNumber(+attribute.value, 'en-US', '1.0-0')} m²`;
              }
              break;
            case 'Rates':
              if (attribute.value) {
                attribute.value = attribute.value.split('"').join('');
                if (attribute.value < 5) {
                  attribute.value = null;
                  break;
                }
                attribute.value = `R ${attribute.value}`;
              }
              break;
            case 'GBA Extent':
              if (attribute.value) {
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'ERF Extent':
              if (attribute.value) {
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'GLA':
              if (attribute.value) {
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'Extent of office component':
              if (attribute.value) {
                attribute.name = 'Extent of office';
                attribute.attribute[0].name = 'Extent of office';
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'Floor load capacity':
              if (attribute.value) {
                attribute.value = `${attribute.value} kg/m²`;
              }
              break;
            case 'Extent of yard component':
              if (attribute.value) {
                attribute.name = 'Extent of yard';
                attribute.attribute[0].name = 'Extent of yard';
                attribute.value = `${attribute.value} m²`;
              }
              break;
            case 'Vacant vs let':
              if (attribute.value) {
                attribute.name = 'Vacant / Let';
                attribute.attribute[0].name = 'Vacant / Let';
              }
              break;
            case 'Freehold vs Sectional Title':
              if (attribute.value) {
                attribute.name = 'Freehold / Sectional Title';
                attribute.attribute[0].name = 'Freehold / Sectional Title';
              }
              break;
            case 'Bedrooms':
              if (attribute.value) {
                if (attribute.value == 0) {
                  attribute.value = null;
                }
              }
              break;
            case 'Bathrooms':
              if (attribute.value) {
                if (attribute.value == 0) {
                  attribute.value = null;
                }
              }
              break;
            case 'Height to eaves':
              if (attribute.value) {
                if (!isNaN(+attribute.value)) {
                  attribute.value = `${attribute.value} m`;
                }
              }
              break;
            case 'Beneficial occupation':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Tenant Installation Allowance':
              if (attribute.value) {
                if (attribute.value === '1') {
                  attribute.value = 'Yes';
                } else {
                  attribute.value = 'No';
                }
              }
              break;
            case 'Length of lease required':
              if (attribute.value) {
                attribute.value = `${attribute.value} Year${+attribute.value > 1 ? 's' : ''}`;
              }
              break;
            default:
              break;
          }
        });
      });
    } catch (exception) {
      console.error(exception);
    }

    return response;
  };

  formatPropertyAttributes = (response: any, skipFormatting: boolean) => {
    try {
      // * JOIN VALUES FOR PROPERTY ATTRIBUTES
      response?.results?.forEach((property: any) => {
        property.attributes = property.attributes.map((attribute: { id: any }) => ({
          ...attribute,
          attribute_value: property.property_attribute.find((propertyAttrbute: { attribute: any }) => propertyAttrbute.attribute == attribute.id),
        }));
      });

      if (skipFormatting) {
        return response;
      }

      response?.results?.forEach((property: Property) => {
        property?.attributes?.forEach((attribute: any) => {
          switch (attribute.attribute_option.name) {
            case 'Yes / No':
              attribute.attribute_value.value = attribute.attribute_value.value == 1 ? 'Yes' : 'No';
              break;
            case 'Occupancy':
              // ? SAID BY LUKE
              if (property.listing_type.name === 'To Let') {
                attribute.attribute_value.value = null;
                break;
              }

              const occupancyValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
              attribute.attribute_value.value = occupancyValue[0].name;
              break;
            case 'Length of lease required':
              const lengthOfLeaseRequiredValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
              attribute.attribute_value.value = lengthOfLeaseRequiredValue[0].value;
              break;
            case 'Value Input':
              if (attribute.attribute_value.value) {
                if (attribute.attribute_value.value.includes('000Z')) {
                  attribute.attribute_value.value = new Date(attribute.attribute_value.value).toDateString();
                  break;
                }
                if (attribute.attribute_value.value === '0.0') {
                  attribute.attribute_value.value = null;
                  break;
                }
                if (attribute.attribute_value.value.includes('.0')) {
                  attribute.attribute_value.value = attribute.attribute_value.value.split('.0').join('');
                  break;
                }
              }
              break;
            default:
              break;
          }

          if (attribute.attribute_value.value) {
            attribute.attribute_value.value = this.cleanMeasurement(attribute.attribute_value.value);
          }

          switch (attribute.name) {
            case 'Rental per m2':
              if (attribute.attribute_value.value) {
                attribute.name = 'Rental per m²';
                attribute.attribute[0].name = 'Rental per m²';
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Rental per ha':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Extent':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} ha`;
              }
              break;
            case 'Price per m2':
              if (attribute.attribute_value.value) {
                attribute.name = 'Price per m²';
                attribute.attribute[0].name = 'Price per m²';
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Price per ha':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Average Rainfall':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} mm`;
              }
              break;
            case 'Phase3 Power':
              if (attribute.attribute_value.value) {
                attribute.name = '3 Phase Power';
                attribute.attribute[0].name = '3 Phase Power';
              }
              break;
            case 'Avaliability':
              if (attribute.attribute_value.value) {
                attribute.name = 'Avaliable';
                attribute.attribute_value.value = new Date(attribute.attribute_value.value).toDateString();
              }
              break;
            case 'Ops costs':
              if (attribute.attribute_value.value) {
                attribute.name = 'Operation Costs';
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Price':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = null;
              }
              break;
            case 'Extent (Gross Lettable Area)':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'Levies':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Size':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${formatNumber(+attribute.attribute_value.value, 'en-US', '1.0-0')} m²`;
              }
              break;
            case 'Rates':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = attribute.attribute_value.value.split('"').join('');

                if (attribute.attribute_value.value < 5) {
                  attribute.attribute_value.value = null;
                  break;
                }

                attribute.attribute_value.value = `R ${attribute.attribute_value.value}`;
              }
              break;
            case 'Residential Building Type':
              if (attribute.attribute_value.value !== null) {
                attribute.name = 'Building Type';
                const ResidentialBuildingTypeValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
                attribute.attribute_value.value = ResidentialBuildingTypeValue[0].value;
              }
              break;
            case 'Building Type':
              if (attribute.attribute_value.value !== null) {
                const BuildingTypeValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
                attribute.attribute_value.value = BuildingTypeValue[0].value;
              }
              break;
            case 'Property Type':
              if (attribute.attribute_value.value !== null) {
                const PropertyTypeValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
                attribute.attribute_value.value = PropertyTypeValue[0].value;
              }
              break;
            case 'Power Type':
              if (attribute.attribute_value.value !== null) {
                const PowerTypeValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
                attribute.attribute_value.value = PowerTypeValue[0].value;
              }
              break;
            case 'Power':
              if (attribute.attribute_value.value !== null) {
                const PowerValue = attribute.attribute_option.options.filter((x: { key: any }) => x.key == attribute.attribute_value.value);
                attribute.attribute_value.value = PowerValue[0].value;
              }
              break;
            case 'GBA Extent':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'ERF Extent':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'GLA':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'Extent of office component':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'Floor load capacity':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} kg/m²`;
              }
              break;
            case 'Extent of yard component':
              if (attribute.attribute_value.value) {
                attribute.attribute_value.value = `${attribute.attribute_value.value} m²`;
              }
              break;
            case 'Vacant vs let':
              if (attribute.attribute_value.value) {
                attribute.name = 'Vacant / Let';
              }
              break;
            case 'Freehold vs Sectional Title':
              if (attribute.attribute_value.value) {
                attribute.name = 'Freehold / Sectional Title';
              }
              break;
            case 'Bedrooms':
              if (attribute.attribute_value.value) {
                if (attribute.attribute_value.value == 0) {
                  attribute.attribute_value.value = null;
                }
              }
              break;
            case 'Bathrooms':
              if (attribute.attribute_value.value) {
                if (attribute.attribute_value.value == 0) {
                  attribute.attribute_value.value = null;
                }
              }
              break;
            case 'Height to eaves':
              if (attribute.attribute_value.value) {
                if (!isNaN(+attribute.attribute_value.value)) {
                  attribute.attribute_value.value = `${attribute.attribute_value.value} m`;
                }
              }
              break;
            default:
              break;
          }
        });
      });

      return response;
    } catch (err) {
      console.error(err);
      return response;
    }
  };
}
