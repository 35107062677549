import { Component, Input, isDevMode } from '@angular/core';

@Component({
  selector: 'app-adsense-column',
  templateUrl: './adsense-column.component.html',
  styleUrls: ['./adsense-column.component.scss'],
})
export class AdsenseColumnComponent {
  @Input() numberOfAds = 1;
  @Input() squareAds = 0;
  public Ads = Array;
  public Square = Array;
  public isDevMode = isDevMode();
}
