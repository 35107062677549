<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">Specialised Commercial Property Portal</h4>
  </div>
</div>

<div mat-dialog-content>
  <p class="text-center">
    CAPX2 was developed by commercial property people for the commercial property market when it became apparent that other portals tend to focus predominantly on residential property and treat the Commercial and Industrial Market as an
    afterthought. Search by power, height to eaves, truck reticulation, office size. Find the commercial property which is right for you.
  </p>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" class="pb-4">
  <button mat-raised-button color="primary" class="uppercase" [mat-dialog-close]>Ok</button>
</div>
