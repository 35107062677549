<h2 class="text-center my-2">Share</h2>
<div fxLayout fxLayoutAlign="center center">
  <button (click)="copyLink()" mat-button color="primary" class="m-2"><i class="fa fa-link"></i> Copy Link</button>
</div>

<div fxlayout="row wrap">
  <button [url]="url" mat-mini-fab shareButton="facebook" class="m-2" style="background-color: #1877f2">
    <i class="fa fa-facebook"></i>
  </button>
  <button [url]="url" mat-mini-fab shareButton="twitter" class="m-2" style="background-color: #1da1f2">
    <i class="fa fa-twitter"></i>
  </button>
  <button [url]="url" mat-mini-fab shareButton="linkedin" class="m-2" style="background-color: #0a66c2">
    <i class="fa fa-linkedin"></i>
  </button>
  <button [url]="url" mat-mini-fab shareButton="pinterest" class="m-2" style="background-color: #bd081c">
    <i class="fa fa-pinterest"></i>
  </button>
  <button [url]="url" mat-mini-fab shareButton="whatsapp" class="m-2" style="background-color: #25d366">
    <i class="fa fa-whatsapp"></i>
  </button>
  <button [url]="url" mat-mini-fab shareButton="reddit" class="m-2" style="background-color: #ff4500">
    <i class="fa fa-reddit"></i>
  </button>
  <!-- ! SITE OWNER (JIM I THINK) DOES NOT ALLOW SHARING ON TUMBLR -->
  <!-- <button [url]="url" mat-mini-fab shareButton="tumblr" class="m-2" style="background-color: #2c4762">
        <i class="fa fa-tumblr"></i>
    </button> -->
  <button [url]="url" mat-mini-fab shareButton="telegram" class="m-2" style="background-color: #0088cc">
    <i class="fa fa-telegram"></i>
  </button>
</div>
