<div class="swiper-wrapper">
  <swiper [config]="config">
    <ng-template *ngIf="($blogs | async) === null" swiperSlide>
      <app-loader></app-loader>
    </ng-template>

    <ng-template *ngIf="($blogs | async)?.count === -1">
      <app-message-chip></app-message-chip>
    </ng-template>

    <ng-template style="display: grid; grid-template-rows: 1fr; grid-template-columns: repeat(9, 1fr)" swiperSlide *ngFor="let blog of ($blogs | async)?.results">
      <div class="m-1">
        <app-blog-item [blog]="blog"></app-blog-item>
      </div>
    </ng-template>
  </swiper>

  <button mat-mini-fab color="primary" style="z-index: 1" class="blog-prev swiper-button-prev swipe-arrow">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-mini-fab color="primary" style="z-index: 1" class="blog-next swiper-button-next swipe-arrow">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <!-- <div style="max-width: fit-content" class="blog-pagination swiper-pagination bg-primary"></div> -->
</div>
