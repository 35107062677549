import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { ComponentsModule } from '@app/components/components.module';
import { CoreModule } from '@app/core/core.module';
import { NotFoundComponent } from '@app/pages/not-found/not-found.component';
import { SharedModule } from '@app/shared/shared.module';
import { ThemeModule } from '@app/theme/theme.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { PixelModule } from 'ngx-pixel';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app.routing';
import { httpInterceptorProviders } from './core/interceptors';
import { ThemeStore } from './core/stores/theme/theme.store';
import { CustomOverlayContainer } from './core/utils/custom-overlay-container';

const config: InputFileConfig = {
  fileAccept: '*',
};

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    ClipboardModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBnzqHpTtS-FLLMtzJbavj7gDVOyVEYKLw',
    //   libraries: ['places'],
    //   region: 'za',
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgProgressModule,
    NgProgressHttpModule,
    InputFileModule.forRoot(config),
    CoreModule,
    ComponentsModule,
    ThemeModule,
    SharedModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    OverlayModule,
    PixelModule.forRoot({ enabled: true, pixelId: '693941692150015' }),
  ],
  providers: [{ provide: OverlayContainer, useClass: CustomOverlayContainer }, httpInterceptorProviders, ThemeStore],
  bootstrap: [AppComponent],
})
export class AppModule {}
