<mat-chip-list>
  <mat-chip *ngIf="searchFields?.ordering && searchFields?.ordering !== ''">
    {{
      searchFields?.ordering === '-created_date'
        ? 'Newest'
        : searchFields?.ordering === 'created_date'
        ? 'Oldest'
        : searchFields?.ordering === '-views'
        ? 'Popular'
        : searchFields?.ordering === '-price'
        ? 'Price (High to Low)'
        : 'Price (Low to High)'
    }}
  </mat-chip>

  <mat-chip *ngIf="searchFields?.city">
    {{ searchFields?.city | titlecase }}
    <mat-icon color="warn" matChipRemove (click)="remove('city')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.propertyType && searchFields?.propertyType !== ''">
    {{ +searchFields?.propertyType === 2 ? 'Commercial' : +searchFields?.propertyType === 3 ? 'Industrial' : +searchFields?.propertyType === 4 ? 'Agricultural' : 'Residential' }}
    <mat-icon color="warn" matChipRemove (click)="remove('propertyType')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.listingType && searchFields?.listingType !== ''">
    {{ +searchFields?.listingType === 5 ? 'On Auction' : +searchFields?.listingType === 3 ? 'For Sale' : 'To Let' }}
    <mat-icon color="warn" matChipRemove (click)="remove('listingType')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.priceFrom">
    Price > {{ searchFields?.priceFrom | number_suffix }}
    <mat-icon color="warn" matChipRemove (click)="remove('priceFrom')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.priceTo">
    Price < {{ searchFields?.priceTo | number_suffix }}
    <mat-icon color="warn" matChipRemove (click)="remove('priceTo')">cancel </mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.keyword">
    {{ searchFields?.keyword | titlecase }}
    <mat-icon color="warn" matChipRemove (click)="remove('keyword')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.reference">
    {{ searchFields?.reference | titlecase }}
    <mat-icon color="warn" matChipRemove (click)="remove('reference')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.date">
    {{ searchFields?.date === 'week' ? 'Past 7 Days' : (searchFields?.date | titlecase) }}
    <mat-icon color="warn" matChipRemove (click)="remove('date')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.description">
    {{ searchFields?.description | titlecase }}
    <mat-icon color="warn" matChipRemove (click)="remove('description')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.features && searchFields?.features?.length > 0">
    <span *ngFor="let type of searchFields?.features; let last = last" class="mr-1">{{ type.name }}<span *ngIf="!last">,</span></span>
    <mat-icon color="warn" matChipRemove (click)="remove('features')">cancel</mat-icon>
  </mat-chip>

  <mat-chip *ngIf="searchFields?.attributes && searchFields?.attributes?.length > 0">
    <span *ngFor="let type of searchFields?.attributes; let last = last" class="mr-1">{{ type.name }}<span *ngIf="!last">,</span></span>
    <mat-icon color="warn" matChipRemove (click)="remove('attributes')">cancel</mat-icon>
  </mat-chip>
</mat-chip-list>
