import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PagedList } from '@app/core/models/paged-list.model';
import { Property } from '@app/core/stores/property/property.model';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { v4 as uuidv4 } from 'uuid';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-properties-carousel',
  templateUrl: './properties-carousel.component.html',
  styleUrls: ['./properties-carousel.component.scss'],
})
export class PropertiesCarouselComponent implements OnChanges {
  @Input() properties: PagedList<Property> = null;
  @Input() public config: SwiperOptions = null;
  public id = uuidv4();

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.config) {
      this.config = {
        observer: true,
        spaceBetween: 16,
        keyboard: false,
        grabCursor: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        },
        lazy: false,
        preloadImages: false,
        speed: 500,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          960: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        },
      };
    }

    this.config.navigation = {
      nextEl: `.prop-carousel-next-` + this.id,
      prevEl: `.prop-carousel-prev-` + this.id,
    };
  }
}
