import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class HelperUtils {
  constructor(private snackbar: MatSnackBar, private router: Router, @Inject(PLATFORM_ID) private platformId: any, private localStorageService: LocalStorageService) {}

  public queryStringToObject(str): any {
    return JSON.parse('{"' + decodeURI(str).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  }

  public scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    }
  }

  convertToSlug(text: string): any {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  getIDFromDocumentMovedResponse(response: string): string {
    if (response.includes('Document Moved')) {
      const val = response.split('"');
      if (val[1]) {
        return val[1];
      }
    }
    return '';
  }
  getIDFromDocumentMoved(error: HttpErrorResponse): string {
    console.log(error, error?.error?.text?.includes('Document Moved'));
    if (error?.error?.text?.includes('Document Moved')) {
      const val = error?.error?.text.split('"');
      if (val[1]) {
        return val[1];
      }
    }
    return '';
  }

  showPreloader(): any {
    // setTimeout(() => {
    //     document.getElementById('preloader').classList.remove('hide');
    //     document.getElementById('preloader').classList.add('show');
    // }, 0);
  }

  hidePreloader(): any {
    // setTimeout(() => {
    //     document.getElementById('preloader').classList.remove('show');
    //     document.getElementById('preloader').classList.add('hide');
    // }, 0);
  }

  toTitleCase(message: string): any {
    if (!message) {
      return '';
    }
    return message.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public getIDFromUrl(url: any): any {
    if (!url) return null;
    if (isNaN(+url)) {
      return url.slice(0, -1).split('/').pop();
    }
    return url;
  }

  public objectToQueryString(obj): any {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  public openSnackbar(message: string, error: boolean, time: number) {
    const messageClass = error ? 'snackbar-error' : 'snackbar-success';
    this.snackbar.open(this.toTitleCase(message), '×', {
      panelClass: [messageClass],
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: time * 1000,
    });
  }

  public displayNoPermissionError(): any {
    this.router.navigate(['/auth/account']).then(() => {
      this.openSnackbar('You do not have permission to view that page', true, 8);
    });
  }

  public displayRequireLoginToast(): any {
    this.router.navigate(['/auth/account']).then(() => {
      this.openSnackbar('You need to be logged in to view that page', true, 8);
    });
  }

  public displayErrorMessageToast(message: string): any {
    this.openSnackbar(message, true, 8);
  }

  public displayErrorResponseToast(errorResponse: HttpErrorResponse): any {
    if (errorResponse.status < 400) {
      return;
    }

    if (errorResponse.headers?.get('content-type') === 'application/json') {
      if (typeof errorResponse.error == 'string') {
        this.openSnackbar(errorResponse.error, true, 8);
      } else {
        this.openSnackbar(`${Object.values(errorResponse?.error).join('\n') ?? ''}`, true, 8);
      }
    } else {
      this.openSnackbar(`${errorResponse?.error?.substring(0, 300) ?? 'Unknown Error'}`, true, 16);
    }
  }

  public displayRejectedFileErrorMessage(): any {
    this.openSnackbar('File has been rejected. Please make sure it is the right file type and smaller than the file size limit.', true, 8);
  }

  public getUserID(): any {
    return JSON.parse(this.localStorageService.getItem('token'))?.user_id ?? undefined;
  }

  public displaySuccessToast(message: string): any {
    this.openSnackbar(message, false, 8);
  }

  public getErrorMessage(error: any): any {
    let message: string = (error as HttpErrorResponse)?.error?.error;
    message = message === undefined ? this.toTitleCase('An unknown error occured') : message;
    return message;
  }
}
