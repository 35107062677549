import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-commercial-for-sale',
  templateUrl: './commercial-for-sale.component.html',
  styleUrls: ['./commercial-for-sale.component.scss'],
})
export class CommercialForSaleComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      price: ['', Validators.required],
      poa: [null],
      extentGrossLettableArea: [null, Validators.required],
      occupancy: [2],
      leaseExpiryDate: [null],
      totalParkings: [null, Validators.required],
      documents: [null],
      generator: [false],
      airconditioning: [false],
      lifts: [false],
      disabilityAccess: [false],
      kitchenette: [false],
      canteen: [false],
      rates: [''],
      levies: [''],
      undercoverParkings: [null, Validators.required],
      openParkings: [null, Validators.required],
      zoning: ['Mixed Use', Validators.required],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });

    const priceSubscription = this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });

    const occupancySubscription = this.featuresForm.controls.occupancy.valueChanges.subscribe((value) => {
      if (value === '1') {
        this.featuresForm.controls.leaseExpiryDate.setValue(null);
        this.featuresForm.controls.leaseExpiryDate.disable();
      } else {
        this.featuresForm.controls.leaseExpiryDate.setValue(null);
        this.featuresForm.controls.leaseExpiryDate.enable();
      }
    });
    this.subscriptions.push(occupancySubscription);
    this.subscriptions.push(priceSubscription);
    this.subscriptions.push(formSubscription);
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
