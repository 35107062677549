import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AppSettings, Settings } from '@app/core/app.settings';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss'],
})
export class HeaderImageComponent implements OnInit, OnDestroy {
  @Input() backgroundImage: string;
  @Input() bgImageAnimate: any;
  @Input() contentOffsetToTop: boolean;
  @Input() contentMinHeight: any;
  @Input() title: any;
  @Input() desc: any;
  @Input() isHomePage = false;
  @Input() fullscreen = false;

  public bgImage: SafeStyle;
  public settings: Settings;
  constructor(public appSettings: AppSettings, private sanitizer: DomSanitizer) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgImage = true;
    });
  }

  ngOnInit(): any {
    if (this.contentOffsetToTop) {
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
    if (this.backgroundImage) {
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.backgroundImage + ')');
    }
  }

  ngOnDestroy(): any {
    setTimeout(() => {
      this.settings.headerBgImage = false;
      this.settings.contentOffsetToTop = false;
    });
  }
}
