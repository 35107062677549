<div class="px-3 bg-accent">
  <div class="theme-container">
    <div class="content">
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.lt-md="center center" fxFlex="100" fxFlex.gt-sm="33">
          <a class="logo" fxLayoutAlign="start" routerLink="/">
            <app-logo [height]="100" [theme]="1"></app-logo>
          </a>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" class="my-1">
            <mat-icon color="primary">person</mat-icon>
            <span class="mx-2 place fw-600" routerLink="/about">About Us</span>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" class="my-1">
            <mat-icon color="primary">call</mat-icon>
            <span class="mx-2 place fw-600" routerLink="/contact">Contact Us</span>
          </div>
        </div>

        <div fxFlex="22" fxLayout="column" fxLayoutAlign="start end" class="pt-3">
          <div *ngFor="let item of locationsCol1" class="pt-3">
            <span class="place fw-600" (click)="search(item)">
              {{ item }}
            </span>
          </div>
        </div>

        <div fxFlex="22" fxLayout="column" fxLayoutAlign="start end" class="pt-3">
          <div *ngFor="let item of locationsCol2" class="pt-3">
            <span class="place fw-600" (click)="search(item)">
              {{ item }}
            </span>
          </div>
        </div>

        <div fxFlex="22" fxLayout="column" fxLayoutAlign="start end" class="pt-3">
          <div *ngFor="let item of locationsCol3" class="pt-3">
            <span class="place fw-600" (click)="search(item)">
              {{ item }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" class="pt-4 pb-2" fxLayoutAlign="space-between center">
      <div fxFlex="33" fxFlex.xs="100">
        <a fxFlex="100" ngClass.xs="mt-1"> Copyright © 2022 CAPX2 </a>
      </div>
      <div fxFlex="33" fxFlex.xs="100" class="text-center">
        <a fxFlex="100">
          Developed By
          <a class="m-0 p-0 place fw-600" href="http://iconis.co.za/" target="_blank">ICONIS</a>
        </a>
      </div>
      <div style="text-align: end" fxFlex="33" fxFlex.xs="100">
        <a fxFlex="100">Version {{ version }}</a>
      </div>
    </div>
  </div>
</div>
