import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { Menu } from '@app/theme/components/menu/menu.model';
import { MenuService } from '@app/theme/components/menu/menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  @Input() menuParentId: number;
  public menuItems: Array<Menu>;
  private subscriptions: Subscription[] = [];

  constructor(public menuService: MenuService, private authStore: AuthComponentStore) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): any {
    const authStoreSubscription = this.authStore.state$.subscribe(() => {
      this.menuItems = this.menuService.getVerticalMenuItems();
      this.menuItems = this.menuItems.filter((item) => item.parentId == this.menuParentId);
    });
    this.subscriptions.push(authStoreSubscription);
  }

  onClick(menuId): any {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuService.getVerticalMenuItems(), menuId);
  }
}
