import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { PropertyUtils } from '@app/core/services/property-utils.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { Property, PropertyState } from './property.model';
import { PropertyService } from './property.service';

export enum PropertyAction {
  INIT,
  ADD_VIEW,
  ONE_PROPERTY,
  MANY_PROPERTIES,
  FEATURED_PROPERTIES,
  RELATED_PROPERTIES,
  SUBMIT_BULK_UPLOAD,
  SUBMIT_BULK_UPLOAD_FAIL,
  CREATED_ADDRESS,
  MY_PROPERTIES,
  CREATED_PROPERTY,
  SUBMIT_FAILED,
  BULK_PROPERTIES,
  UPDATED_PROPERTY,
}

export const DEFAULT_STATE: PropertyState = {
  properties: null,
  editProperty: null,
  createdPropertyID: null,
  featuredProperties: null,
  relatedProperties: null,
  property: null,
  action: PropertyAction.INIT,
  cities: [],
  createdAddress: null,
  editPropertyImages: [],
  editPropertyDocuments: [],
  myProperties: null,
  bulkProperties: null,
  fetchingProperties: false,
  propertyLeads: null,
};

@Injectable({ providedIn: 'root' })
export class PropertyComponentStore extends ComponentStore<PropertyState> {
  constructor(private propertyService: PropertyService, private helperUtils: HelperUtils, private router: Router, private propertyUtils: PropertyUtils) {
    super(DEFAULT_STATE);
  }

  readonly bulkUpload = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.bulkUpload(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                action: PropertyAction.SUBMIT_BULK_UPLOAD,
              });
              this.helperUtils.displaySuccessToast('Bulk Data has been successfully uploaded. You will receive an email once your properties have been processed.');
              this.patchState({ action: PropertyAction.INIT });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: PropertyAction.SUBMIT_BULK_UPLOAD_FAIL,
              });
              this.patchState({ action: PropertyAction.INIT });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly createProperty = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.createProperty(options).pipe(
          tapResponse(
            (response: any) => {
              const id = this.helperUtils.getIDFromUrl(response.id);
              this.patchState({ createdPropertyID: id });
              this.patchState({
                action: PropertyAction.CREATED_PROPERTY,
              });
              this.patchState({ action: PropertyAction.INIT });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                action: PropertyAction.SUBMIT_FAILED,
              });
              this.patchState({
                action: PropertyAction.INIT,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly assignSubscription = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.assignSubscription(options).pipe(
          tapResponse(
            () => {},
            (error: HttpErrorResponse) => {
              if (error.status === 401) {
                // ! SETTIMEOUT TO OVERRIDE POTENTIAL MIDDLEWARE ERROR MESSAGE
                setTimeout(() => {
                  this.helperUtils.displayErrorMessageToast('Could not assign property to selected subscription. Please select another subscription and try again');
                }, 100);
              }
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly appendEditImages = this.updater((state, image: any) => ({
    ...state,
    editPropertyImages: [...(state?.editPropertyImages ?? []), ...[image]],
  }));

  readonly appendEditDocuments = this.updater((state, document: any) => ({
    ...state,
    editPropertyDocuments: [...(state?.editPropertyDocuments ?? []), ...[document]],
  }));

  readonly fetchMany = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getMany(options).pipe(
          tapResponse(
            (response: PagedList<any>) => {
              response = this.propertyUtils.formatPropertyAttributes(response, false);
              response.results.forEach((property: any) => {
                property = this.propertyUtils.cleanProperty(property);
                property.property_image = property.property_image.filter((x: any) => x.is_active);
              });
              this.patchState({
                properties: response,
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({ fetchingProperties: false });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                properties: new PagedList<Property>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({ fetchingProperties: false });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchLeadsForProperty = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getLeadsForProperty(options).pipe(
          tapResponse(
            (response: PagedList<any>) => {
              response.results = [...new Map(response.results.map((item) => [item?.lead?.email, item])).values()];
              response.count = response?.results?.length;
              this.patchState({
                propertyLeads: response,
              });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchManyNewSearch = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getManyNewSearch(options).pipe(
          tapResponse(
            (response: PagedList<Property>) => {
              response = this.propertyUtils.formatPropertyAttributesNewSearch(response, false);
              response.results.forEach((property: Property) => {
                property = this.propertyUtils.cleanProperty(property);
              });
              this.patchState({
                properties: response,
                action: PropertyAction.MANY_PROPERTIES,
                fetchingProperties: false,
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                properties: new PagedList<Property>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({ fetchingProperties: false });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchManyNoFeatures = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getManyNoFeatures(options).pipe(
          tapResponse(
            (response: PagedList<Property>) => {
              response.results.forEach((property: Property) => {
                property = this.propertyUtils.cleanProperty(property);
              });
              this.patchState({
                properties: response,
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({ fetchingProperties: false });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                properties: new PagedList<Property>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({ fetchingProperties: false });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchMyProperties = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getMany(options).pipe(
          tapResponse(
            (response: PagedList<any>) => {
              response.results.forEach((property: any) => {
                property = this.propertyUtils.cleanProperty(property);
                property.property_image = property.property_image.filter((x: any) => x.is_active);
              });
              this.patchState({
                myProperties: response,
                action: PropertyAction.MY_PROPERTIES,
              });
              this.patchState({
                action: PropertyAction.INIT,
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                properties: new PagedList<Property>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
                action: PropertyAction.MANY_PROPERTIES,
              });
              this.patchState({
                action: PropertyAction.INIT,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchBulkProperties = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getBulkProperties(options).pipe(
          tapResponse(
            (response: PagedList<Property>) => {
              // response.results.forEach((property: Property) => {
              //     property = this.cleanProperty(property);
              // });

              this.patchState({
                bulkProperties: response,
                action: PropertyAction.BULK_PROPERTIES,
              });
              this.patchState({
                action: PropertyAction.INIT,
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                bulkProperties: new PagedList<Property>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
                action: PropertyAction.BULK_PROPERTIES,
              });
              this.patchState({
                action: PropertyAction.INIT,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchOne = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getOne(options).pipe(
          tapResponse(
            (response: any) => {
              const data: PagedList<Property> = {
                count: 1,
                next: null,
                previous: null,
                results: [response],
              };

              let skipFormatting = options?.edit ?? false;

              response = this.propertyUtils.formatPropertyAttributes(data, skipFormatting);
              response = response.results[0];

              response.property_image = response.property_image.filter((x: any) => x.is_active);

              response = this.propertyUtils.cleanProperty(response, options);

              this.patchState({
                property: response,
                action: PropertyAction.ONE_PROPERTY,
              });

              this.patchState({ action: PropertyAction.INIT });
            },
            (error: HttpErrorResponse) => {
              this.router.navigate(['/']);
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchOneNewSearch = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getOneNewSearch(options).pipe(
          tapResponse(
            (response: any) => {
              const data: PagedList<Property> = {
                count: 1,
                next: null,
                previous: null,
                results: [response],
              };

              let skipFormatting = options?.edit ?? false;

              // response.images = response.images.filter((x: any) => x.is_active);

              response = this.propertyUtils.formatPropertyAttributesNewSearch(data, skipFormatting);
              response = response.results[0];

              response = this.propertyUtils.cleanProperty(response, options);

              this.patchState({
                property: response,
                action: PropertyAction.ONE_PROPERTY,
              });

              this.patchState({ action: PropertyAction.INIT });
            },
            (error: HttpErrorResponse) => {
              this.router.navigate(['/']);
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchFeaturedProperties = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getFeaturedProperties(options).pipe(
          tapResponse(
            (response: any) => {
              response = response ?? [];
              let data: PagedList<Property> = {
                count: response?.length ?? 0,
                next: null,
                previous: null,
                results: response,
              };
              data = this.propertyUtils.formatPropertyAttributes(data, false);
              data.results.forEach((property: Property) => {
                property = this.propertyUtils.cleanProperty(property);
              });
              this.patchState({
                featuredProperties: data,
                action: PropertyAction.FEATURED_PROPERTIES,
              });
            },
            (error: HttpErrorResponse) => {
              let data: PagedList<Property> = {
                count: -1,
                next: null,
                previous: null,
                results: [],
              };
              this.patchState({
                featuredProperties: data,
                action: PropertyAction.FEATURED_PROPERTIES,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchFeaturedPropertiesForHomePage = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getFeaturedPropertiesForHomePage(options).pipe(
          tapResponse(
            (response: any) => {
              const res = response;
              let data: any = {
                count: res?.length,
                next: null,
                previous: null,
                results: res,
              };
              data = this.propertyUtils.formatPropertyAttributesNewSearch(data, false);
              data.results.forEach((property: Property) => {
                property = this.propertyUtils.cleanProperty(property);
              });
              this.patchState({
                featuredProperties: data,
                action: PropertyAction.FEATURED_PROPERTIES,
              });
            },
            (error: HttpErrorResponse) => {
              let data: PagedList<Property> = {
                count: -1,
                next: null,
                previous: null,
                results: [],
              };

              this.patchState({
                featuredProperties: data,
                action: PropertyAction.FEATURED_PROPERTIES,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchRelatedProperties = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.propertyService.getRelatedProperties(options).pipe(
          tapResponse(
            (response: any) => {
              let data: PagedList<Property> = {
                count: response?.length,
                next: null,
                previous: null,
                results: response,
              };
              data = this.propertyUtils.formatPropertyAttributesNewSearch(data, false);
              data.results.forEach((property: Property) => {
                property = this.propertyUtils.cleanProperty(property);
              });
              this.patchState({
                relatedProperties: data,
                action: PropertyAction.RELATED_PROPERTIES,
              });
            },
            (error: HttpErrorResponse) => {
              let data: PagedList<Property> = {
                count: -1,
                next: null,
                previous: null,
                results: [],
              };
              this.patchState({
                relatedProperties: data,
                action: PropertyAction.RELATED_PROPERTIES,
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchCities = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.getCities(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                cities: response,
              });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly addView = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.propertyService.saveProperty(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                property: response,
                action: PropertyAction.ADD_VIEW,
              });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });
}
