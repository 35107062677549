import { Menu } from '@app/theme/components/menu/menu.model';

export const horizontalMenuItems = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'PROPERTY INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'WEIGHTED AVERAGE LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
  new Menu(10, 'FAQ', '/faq', null, null, false, 0),
  new Menu(11, 'JOIN US TODAY!', '/auth/register', null, null, false, 0),
];

export const horizontalMenuItemsLoggedIn = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'PROPERTY INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'WEIGHTED AVERAGE LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
];

export const horizontalMenuItemsLoggedInAsAdmin = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'PROPERTY INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'WEIGHTED AVERAGE LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
  new Menu(10, 'ADMIN', '/admin', null, null, false, 0),
];

export const verticalMenuItems = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
  new Menu(10, 'FAQ', '/faq', null, null, false, 0),
  new Menu(11, 'JOIN US TODAY!', '/auth/register', null, null, false, 0),
];

export const verticalMenuItemsLoggedIn = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
];

export const verticalMenuItemsLoggedInAsAdmin = [
  new Menu(1, 'HOME', '/', null, null, false, 0),
  new Menu(2, 'BROWSE LISTINGS', '/properties', null, null, true, 0),
  new Menu(100, 'ALL', '/properties/all-locations/all-properties/all-categories?page=1&size=6', null, null, false, 2),
  new Menu(101, 'FOR SALE', '/properties/all-locations/all-properties/for-sale?page=1&size=6', null, null, false, 2),
  new Menu(102, 'TO LET', '/properties/all-locations/all-properties/to-let?page=1&size=6', null, null, false, 2),
  new Menu(102, 'ON AUCTION', '/properties/all-locations/all-properties/on-auction?page=1&size=6', null, null, false, 2),
  // new Menu(9, 'PRICING', '/pricing', null, null, false, 0),
  new Menu(7, 'CALCULATORS', '/calculators', null, null, true, 0),
  new Menu(200, 'ALL', '/calculators', null, null, false, 7),
  new Menu(201, 'INSTALLMENT', '/calculators/property-installment', null, null, false, 7),
  new Menu(202, 'CAP RATE', '/calculators/cap-rate', null, null, false, 7),
  new Menu(203, 'LOAN AMORTISATION', '/calculators/loan-amortisation', null, null, false, 7),
  new Menu(204, 'LEASE EXPIRY', '/calculators/wale', null, null, false, 7),
  new Menu(8, 'BLOG', '/blog', null, null, false, 0),
  new Menu(10, 'ADMIN', '/admin', null, null, false, 0),
];
