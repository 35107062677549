import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PixelService } from 'ngx-pixel';
import { ShareDialogComponent } from './dialog/share-page.dialog.component';

@Component({
  selector: 'app-share-page',
  templateUrl: './share-page.component.html',
  styleUrls: ['./share-page.component.scss'],
})
export class SharePageComponent {
  constructor(public dialog: MatDialog, private pixel: PixelService) {}

  @Input() href = null;

  public openDialog(): void {
    this.pixel.trackCustom('Share', { contents: this.href });
    this.dialog.open(ShareDialogComponent, {
      // width: '250px',
      data: {
        href: this.href,
      },
    });
  }
}
