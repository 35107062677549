<div *ngIf="properties && config" class="properties-carousel swiper-wrapper">
  <swiper style="display: grid; grid-template-rows: 1fr; grid-template-columns: repeat(9, 1fr)" class="swiper-container h-100" [config]="config">
    <ng-template swiperSlide *ngFor="let property of properties.results">
      <app-property-item [property]="property" [viewType]="'grid'"></app-property-item>
    </ng-template>
  </swiper>
  <button mat-mini-fab color="primary" [ngClass]="'prop-carousel-prev-' + id" class="swiper-button-prev swipe-arrow">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-mini-fab color="primary" [ngClass]="'prop-carousel-next-' + id" class="swiper-button-next swipe-arrow">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
