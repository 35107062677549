<div class="mt-3">
  <div class="theme-container">
    <div fxLayout="row wrap" class="services-wrapper">
      <div fxFlex="100" fxFlex.gt-md="33" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <div class="container">
            <div class="col-md-4 px-0">
              <mat-icon class="mat-icon-xlg" color="primary">apartment</mat-icon>
            </div>
          </div>

          <h3 class="capitalize fw-600 my-3">To be the go-to destination for commercial property participants in ZA</h3>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-md="33" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <div class="container">
            <div class="col-md-4 px-0">
              <mat-icon class="mat-icon-xlg" color="primary">paid</mat-icon>
            </div>
          </div>
          <h3 class="capitalize fw-600 my-3">To facilitate quicker commercial property transactions</h3>
        </mat-card>
      </div>

      <div fxFlex="100" fxFlex.gt-md="34" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <div class="container">
            <div class="col-md-4 px-0">
              <mat-icon class="mat-icon-xlg" color="primary">campaign</mat-icon>
            </div>
          </div>
          <h3 class="capitalize fw-600 my-3">To actively promote listings ensuring maximum visibility</h3>
        </mat-card>
      </div>
    </div>
  </div>
</div>
