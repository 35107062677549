import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HelperUtils } from '@app/core/services/helper-utils.service';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-page.dialog.html',
})
export class ShareDialogComponent {
  public url: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(DOCUMENT) private dom: Document,
    private clipboard: Clipboard,
    private helperUtils: HelperUtils,
    public dialogRef: MatDialogRef<ShareDialogComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (this.data.href) {
      this.url = this.dom.baseURI + this.data.href;
      return;
    }

    this.url = this.dom.URL;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  copyLink(): any {
    this.clipboard.copy(this.url);
    this.helperUtils.displaySuccessToast('link has been copied to clipboard!');
  }
}
