import { Component } from '@angular/core';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss'],
})
export class DigitalMarketingComponent {
  openEmailClient(): any {
    window.location.href = 'mailto:support@capx2.com';
  }
}
