<mat-card class="property-item p-0" [ngClass]="[viewType + '-item', 'column-' + column]" [class.full-width-page]="fullWidthPage" fxLayout="row wrap">
  <div fxFlex="100" fxShrink="3" [fxFlex.gt-sm]="viewType === 'list' ? 40 : 100" [fxFlex.sm]="viewType === 'list' ? 50 : 100" class="thumbnail-section">
    <div fxLayout="row wrap" class="property-status">
      <span *ngIf="property?.property_type?.name" class="bg-primary">{{ property?.property_type?.name }}</span>

      <span *ngIf="property?.listing_type?.name" class="bg-accent">{{ property?.listing_type.name }}</span>

      <span *ngFor="let item of property?.classifications" class="bg-warn">{{ item?.name }}</span>

      <span *ngIf="property?.new" style="background-color: red">New</span>

      <!-- TODO CHANGE THIS TO A HIGHER VALUE IN PRODUCTION -->
      <span *ngIf="property?.views > 50" style="background-color: red">Hot</span>
    </div>

    <div *ngIf="property?.images" class="mat-card-image w-100 m-0">
      <swiper style="height: 200px" [config]="config">
        <ng-template swiperSlide style="max-height: 200px" *ngFor="let image of property?.images">
          <img class="swiper-lazy" (click)="propertyClick()" (error)="onImageError($event, image)" [src]="buildImageUrl(image?.image)" />
          <div style="margin-top: 100px" class="swiper-lazy-preloader"></div>
        </ng-template>
      </swiper>
      <!-- <div [ngClass]="'swiper-pagination-' + carouselID" class="swiper-pagination white"></div>
      <button style="z-index: 1" [ngClass]="'prop-item-prev-' + carouselID" mat-icon-button class="swiper-button-prev swipe-arrow">
        <mat-icon style="color: white" class="mat-icon-lg">keyboard_arrow_left</mat-icon>
      </button>
      <button style="z-index: 1" [ngClass]="'prop-item-next-' + carouselID" mat-icon-button class="swiper-button-next swipe-arrow">
        <mat-icon style="color: white" class="mat-icon-lg">keyboard_arrow_right</mat-icon>
      </button> -->
    </div>

    <div class="control-icons">
      <button *ngIf="isFavourite" (click)="removeFavourite()" mat-button matTooltip="Remove From Favorites">
        <mat-icon class="mat-icon-sm" style="color: red">favorite</mat-icon>
      </button>

      <button *ngIf="!isFavourite" mat-button matTooltip="Add To Favorites" (click)="addToFavorites()">
        <mat-icon class="mat-icon-sm">favorite_border</mat-icon>
      </button>
    </div>
  </div>
  <div fxFlex="100" [fxFlex.gt-sm]="viewType === 'list' ? 60 : 100" [fxFlex.sm]="viewType === 'list' ? 50 : 100" class="p-3">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="h-100 text-center">
      <mat-card-content class="mb-0">
        <h1 style="height: 60px" class="title" (click)="propertyClick()">
          <a class="title-text">{{ property?.title }}</a>
        </h1>

        <p *ngIf="property?.addresses" style="max-height: 70px; min-height: 70px; overflow: hidden; padding-left: 50px; padding-right: 50px; font-size: small" fxLayout="row" fxLayoutAlign="center center" class="address text-center">
          <mat-icon class="text-muted p-2" style="font-size: large">location_on</mat-icon>
          {{ property?.addresses[0]?.address2 ?? '' | titlecase }}
          {{ property?.addresses[0]?.city ?? '' | titlecase }}
          {{ property?.addresses[0]?.province ?? '' | titlecase }}
        </p>

        <p *ngIf="!property?.addresses" style="max-height: 70px; min-height: 70px; overflow: hidden; padding-left: 50px; padding-right: 50px; font-size: small" fxLayout="row" fxLayoutAlign="center center" class="address text-center">
          <mat-icon class="text-muted p-2" style="font-size: large">location_on</mat-icon>Location not available
        </p>

        <div fxLayout="row" fxLayoutAlign="center stretch">
          <div class="text-center">
            <h3 class="primary-color price" fxLayout="row">
              <a>{{ property?.formattedPrice }}</a>
            </h3>
          </div>
        </div>

        <!-- ? FEATURES -->
        <div class="features mt-3" *ngFor="let item of property?.attributes">
          <p>
            <span>
              {{ item?.attribute[0]?.name }}
            </span>
            <span fxLayoutAlign="end" *ngIf="item?.value !== 'Yes' && item?.value !== 'No'">
              {{ item?.value ?? 'Unknown' }}
            </span>
            <span fxLayoutAlign="end" *ngIf="item?.value === 'Yes'">
              <mat-icon color="primary" style="font-size: large">check</mat-icon>
            </span>

            <span fxLayoutAlign="end" *ngIf="item?.value === 'No'">
              <mat-icon color="primary" style="font-size: large">close</mat-icon>
            </span>
          </p>
        </div>

        <div class="mt-3" *ngIf="+property?.attributes?.length === 0">
          <p>No Features Listed</p>
        </div>
      </mat-card-content>
      <div class="sticky-footer-helper" fxFlex="grow"></div>
      <mat-card-actions class="m-0 p-0">
        <!-- ? CHANGE FXLAYOUT TO ROW WRAP IF NEEDED -->
        <div fxLayout="column">
          <div class="features mt-3">
            <p>
              <span>
                <mat-icon class="text-muted" style="font-size: large">date_range</mat-icon>
              </span>
              <span>
                {{ property?.created_date | date: 'dd MMMM, yyyy' }}
              </span>
            </p>
          </div>

          <div class="features mt-3">
            <p>
              <span>
                <mat-icon class="text-muted" style="font-size: large">numbers</mat-icon>
              </span>
              <span (click)="copyToClipboard()" style="cursor: pointer; text-decoration: underline">
                {{ property?.reference }}
              </span>
            </p>
          </div>

          <div class="p-2">
            <a mat-stroked-button color="primary" (click)="propertyClick()" class="uppercase">Details</a>
          </div>
        </div>
      </mat-card-actions>
    </div>
  </div>
</mat-card>
