import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { AppSettings, Settings } from '@app/core/app.settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-theme-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;
  public toolbarTypes = [1, 2];
  public toolbarTypeOption: number;
  public headerTypes = ['default', 'image', 'carousel', 'map', 'video'];
  public headerTypeOption: string;
  public searchPanelVariants = [1, 2, 3];
  public searchPanelVariantOption: number;
  public headerFixed = false;
  public showBackToTop = false;
  public scrolledCount = 0;
  public myscript: HTMLScriptElement;
  public settings: Settings;
  private subscriptions: Subscription[] = [];
  public isBrowser = false;

  constructor(private helperUtils: HelperUtils, public appSettings: AppSettings, public router: Router, @Inject(PLATFORM_ID) private platformId: any) {
    this.settings = this.appSettings.settings;
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.toolbarTypeOption = this.settings.toolbar;
    this.headerTypeOption = this.settings.header;
    this.searchPanelVariantOption = this.settings.searchPanelVariant;
  }

  public changeTheme(theme): void {
    this.settings.theme = theme;
  }

  public chooseToolbarType(): void {
    this.settings.toolbar = this.toolbarTypeOption;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  public chooseHeaderType(): void {
    this.settings.header = this.headerTypeOption;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.router.navigate(['/']);
  }

  public chooseSearchPanelVariant(): void {
    this.settings.searchPanelVariant = this.searchPanelVariantOption;
  }

  @HostListener('window:scroll') onWindowScroll(): any {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    scrollTop > 300 ? (this.showBackToTop = true) : (this.showBackToTop = false);

    if (this.settings.stickyMenuToolbar) {
      const topToolbar = document.getElementById('top-toolbar');
      if (topToolbar) {
        if (scrollTop >= topToolbar.clientHeight) {
          this.settings.mainToolbarFixed = true;
        } else {
          this.settings.mainToolbarFixed = false;
        }
      }
    }

    const loadMore = document.getElementById('load-more');
    if (loadMore) {
      if (window.innerHeight > loadMore.getBoundingClientRect().top + 120) {
        if (!this.settings.loadMore.complete) {
          if (this.settings.loadMore.start) {
            if (this.scrolledCount < this.settings.loadMore.step) {
              this.scrolledCount++;
              if (!this.settings.loadMore.load) {
                this.settings.loadMore.load = true;
              }
            } else {
              this.settings.loadMore.start = false;
              this.scrolledCount = 0;
            }
          }
        }
      }
    }
  }

  public scrollToTop(): void {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.helperUtils.hidePreloader();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    });
    this.subscriptions.push(routerSubscription);
  }
}
