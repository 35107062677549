import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HelperUtils } from '@app/core/services/helper-utils.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements AfterViewInit {
  constructor(private helperUtils: HelperUtils, public router: Router, private title: Title) {
    this.title.setTitle('CAPX2 - 404');
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

  ngAfterViewInit(): void {
    this.helperUtils.hidePreloader();
  }
}
