<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">High Quality Lead Generation And Vetting</h4>
  </div>
</div>

<div mat-dialog-content>
  <p class="text-center">
    Save time by getting an understanding who you are dealing with. Selling your property? Its easy to be strung along by time wasters on other portals; Capx2 gives you insight into the person behind the lead. Are they persons of substance
    or straw? Do they own other properties, are they directors in companies, are they convicted fraudsters? Capx2 lets you know.
  </p>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" class="pb-4">
  <button mat-raised-button color="primary" class="uppercase" [mat-dialog-close]>Ok</button>
</div>
