import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-residential-on-auction',
  templateUrl: './residential-on-auction.component.html',
  styleUrls: ['./residential-on-auction.component.scss'],
})
export class ResidentialOnAuctionComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      auctionDate: [null, Validators.required],
      price: ['', Validators.required],
      poa: [null],
      pricePerM2: ['', Validators.required],
      pricePerM2Poa: [null],
      size: [null, Validators.required],
      bedrooms: [null, Validators.required],
      bathrooms: [null, Validators.required],
      totalParkings: [null, Validators.required],
      livingRooms: [null, Validators.required],
      petFriendly: [false, Validators.required],
      garden: [false, Validators.required],
      pool: [false, Validators.required],
      residentialBuildingType: ['2'],
      secureEstate: [false],
      propertyType: ['2'],
      rates: [''],
      levies: [''],
      documents: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });
    this.subscriptions.push(formSubscription);
  }

  propertyTypeChange(event: any): void {
    console.log(event.value == 3);
    if (event.value == 3) {
      this.featuresForm.controls.secureEstate.setValue(null);
      this.featuresForm.controls.secureEstate.disable();
      this.featuresForm.controls.levies.setValue(null);
      this.featuresForm.controls.levies.disable();
    } else if (event.value == 2) {
      this.featuresForm.controls.secureEstate.setValue(null);
      this.featuresForm.controls.secureEstate.enable();
      this.featuresForm.controls.levies.setValue(null);
      this.featuresForm.controls.levies.enable();
    } else if (event.value == 1) {
      this.featuresForm.controls.secureEstate.setValue(null);
      this.featuresForm.controls.secureEstate.disable();
      this.featuresForm.controls.levies.setValue(null);
      this.featuresForm.controls.levies.disable();
    }
  }

  propertyDetailChange(event: any): any {
    if (event.value == 1) {
      this.featuresForm.controls.levies.setValue(null);
      this.featuresForm.controls.levies.disable();
    } else {
      this.featuresForm.controls.levies.setValue(null);
      this.featuresForm.controls.levies.enable();
    }
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  poaPriceHaEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.pricePerM2.setValue(0);
    } else {
      this.featuresForm.controls.pricePerM2.setValue(null);
    }
  }

  onPowerChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.powerOptions.enable();
      return;
    }
    this.featuresForm.controls.powerOptions.disable();
  }

  onHeightToEavesChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.heightToEaves.disable();
      this.featuresForm.controls.heightToEaves.setValue('');
      return;
    }
    this.featuresForm.controls.heightToEaves.enable();
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
