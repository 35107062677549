<mat-card class="pt-0 pb-0">
  <div fxLayout>
    <mat-paginator [showFirstLastButtons]="showFirstLastButtons" [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="paginationChange($event)"> </mat-paginator>
    <div class="go-to-container">
      <mat-label class="go-to-label">Page: </mat-label>
      <mat-form-field>
        <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
          <mat-option *ngFor="let pageNumber of pageNumbers" [value]="pageNumber">{{ pageNumber }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>

<!-- Created by Sameer Khan -->
