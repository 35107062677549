import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhonePipe } from './contact-number.pipe';
import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterNeighborhoodsPipe } from './filter-neighborhoods';
import { FilterStreetsPipe } from './filter-streets.pipe';
import { NumberSuffixPipe } from './number-suffix.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FilterByIdPipe, FilterNeighborhoodsPipe, FilterStreetsPipe, PhonePipe, NumberSuffixPipe],
  exports: [FilterByIdPipe, FilterNeighborhoodsPipe, FilterStreetsPipe, PhonePipe, NumberSuffixPipe],
})
export class PipesModule {}
