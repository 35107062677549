<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">{{ title }}</h4>
  </div>
</div>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions fxLayoutAlign="space-between center" class="pb-4">
  <button mat-button (click)="onDismiss()">NO</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">YES</button>
</div>
