<div>
  <h2 class="text-center">Land</h2>
  <form [formGroup]="featuresForm" fxLayout="row wrap" (ngSubmit)="onSubmit()">
    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Price per ha ( dont forget poa option)</mat-label>
        <input type="number" matInput placeholder="Extent" formControlName="gla" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gla?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Floor Area Ratio</mat-label>
        <input type="number" matInput placeholder="Floor area ratio" formControlName="gla" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gla?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Coverage %</mat-label>
        <input type="number" matInput placeholder="Coverage %" formControlName="gla" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gla?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Height (Number of storeys)</mat-label>
        <input type="number" matInput placeholder="Height (Number of storeys)" formControlName="gla" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gla?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="70" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Extent</mat-label>
        <input type="number" matInput placeholder="Extent" formControlName="gla" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gla?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="30" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select Measurement</mat-label>
        <mat-select matTooltip="Meters squared or hectare" placeholder="Select Measurement" formControlName="glaMeasurement">
          <mat-option *ngFor="let option of ['m²', 'ha']" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Zoning</mat-label>
        <mat-select placeholder="Select Zoning" formControlName="zoning" required>
          <mat-option *ngFor="let option of []" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
