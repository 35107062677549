import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { AuthState } from '@app/core/stores/auth/auth.model';
import { Menu } from '@app/theme/components/menu/menu.model';
import { Subscription } from 'rxjs';
import { horizontalMenuItems, horizontalMenuItemsLoggedIn, horizontalMenuItemsLoggedInAsAdmin, verticalMenuItems, verticalMenuItemsLoggedIn, verticalMenuItemsLoggedInAsAdmin } from './menu';

@Injectable()
export class MenuService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  public authState: AuthState;

  constructor(private location: Location, private authStore: AuthComponentStore) {
    const authStoreSubscription = this.authStore.state$.subscribe((state) => {
      this.authState = state;
    });
    this.subscriptions.push(authStoreSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public getVerticalMenuItems(): Array<Menu> {
    if (this.authState.isLoggedIn) {
      if (this.authState.isAdmin) {
        return verticalMenuItemsLoggedInAsAdmin;
      }

      return verticalMenuItemsLoggedIn;
    }
    return verticalMenuItems;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    if (this.authState.isLoggedIn) {
      if (this.authState.isAdmin) {
        return horizontalMenuItemsLoggedInAsAdmin;
      }

      return horizontalMenuItemsLoggedIn;
    }
    return horizontalMenuItems;
  }

  public expandActiveSubMenu(menu: Array<Menu>): void {
    const url = this.location.path();
    const routerLink = decodeURIComponent(url);
    const activeMenuItem = menu.filter((item) => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        const parentMenuItem = menu.filter((item) => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId: string | number): void {
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId: number): void {
    const currentMenuItem = menu.filter((item) => item.id == menuId)[0];
    menu.forEach((item) => {
      if ((item.id != menuId && item.parentId == currentMenuItem.parentId) || (currentMenuItem.parentId == 0 && item.id != menuId)) {
        const subMenu = document.getElementById('sub-menu-' + item.id);
        const menuItem = document.getElementById('menu-item-' + item.id);
        if (subMenu) {
          if (subMenu.classList.contains('show')) {
            subMenu.classList.remove('show');
            menuItem.classList.remove('expanded');
          }
        }
      }
    });
  }

  public closeAllSubMenus(): void {
    verticalMenuItems.forEach((item) => {
      const subMenu = document.getElementById('sub-menu-' + item.id);
      const menuItem = document.getElementById('menu-item-' + item.id);
      if (subMenu) {
        if (subMenu.classList.contains('show')) {
          subMenu.classList.remove('show');
          menuItem.classList.remove('expanded');
        }
      }
    });
  }
}
