import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  constructor() {
    super(null, null);
  }

  _createContainer(): void {
    try {
      const container = document.createElement('div');
      container.classList.add('cdk-overlay-container');
      document.getElementById('app').appendChild(container);
      this._containerElement = container;
    } catch (err) {
      // console.error('custom overlay load failed', err);
    }
  }
}
