<div class="p-3">
  <div class="theme-container">
    <h1 class="text-center uppercase">Select an upload type</h1>
    <div fxLayout="row" fxLayout.lt-sm="column">
      <div fxFlex="50" class="p-3">
        <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z18 p-3">
          <button mat-button class="w-100" fxFill routerLink="/submit-property/single-property">
            <mat-icon class="mat-icon-xxlg" color="primary">home</mat-icon>
            <h2 class="uppercase">Single Property</h2>
            <p class="capitalize">Upload a single property to CAPX2</p>
          </button>
        </mat-card>
      </div>
      <div fxFlex="50" class="p-3">
        <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z18">
          <button mat-button class="w-100" fxFill disabled routerLink="/submit-property/bulk-upload">
            <mat-icon class="mat-icon-xxlg" color="primary">holiday_village</mat-icon>
            <h2 class="uppercase">Bulk Upload</h2>
            <p class="capitalize">Upload multiple properties at once (In Development)</p>
          </button>
        </mat-card>
      </div>
    </div>
  </div>
</div>
