import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AppSettings, Settings } from '@app/core/app.settings';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.scss'],
})
export class HeaderCarouselComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() slides: Array<any> = [];
  @Input() contentOffsetToTop: any;
  @Input() fullscreen = false;
  public config: SwiperOptions = {};
  public currentSlide: any;
  public settings: Settings;
  constructor(public appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): any {
    if (this.contentOffsetToTop) {
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
  }

  ngAfterViewInit(): void {
    this.initCarousel();
  }

  ngOnChanges(): void {
    if (this.slides.length > 0) {
      this.currentSlide = this.slides[0];
    }
  }

  public initCarousel(): any {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      speed: 500,
      effect: 'slide',
    };
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.settings.contentOffsetToTop = false;
    });
  }

  public onIndexChange(index: number): void {
    this.currentSlide = this.slides[index];
  }
}
