<div>
  <div fxLayout="column" fxFill class="p-3">
    <h1 class="fw-500 text-center">Commercial To Let</h1>
    <p class="text-center" style="color: red">Fields marked with a * are required</p>
  </div>
  <form [formGroup]="featuresForm" fxLayout="row wrap" (ngSubmit)="onSubmit()">
    <div fxFlex="80" fxFlex.gt-xs="80" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Listing Price</mat-label>
        <input type="text" currencyMask matInput placeholder="Enter price per m²" required formControlName="price" autocomplete="off" />
        <mat-error *ngIf="featuresForm.controls.price.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="20" fxFlex.gt-xs="20" fxLayoutAlign="center center" class="px-2" style="height: 50px">
      <mat-checkbox formControlName="poa" (change)="poaPriceEvent($event.checked)"> POA </mat-checkbox>
    </div>

    <div fxFlex="200px" class="mb-4 pl-2" fxLayoutAlign="start center">
      <mat-checkbox (change)="avaliabilityChange($event)" formControlName="avaliabilityImmediate"> Avaliable Immediately</mat-checkbox>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose avaliability date</mat-label>
        <input formControlName="avaliability" matInput [matDatepicker]="picker" required />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="featuresForm?.controls?.avaliability?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>GLA Extent</mat-label>
        <input
          matTooltip="Gross leasable area, or GLA, is the area in a commercial property designed for the exclusive use of a tenant."
          type="number"
          matInput
          placeholder="GLA Extent in m²"
          formControlName="extentGrossLettableArea"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="featuresForm?.controls?.extentGrossLettableArea?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <mat-radio-group formControlName="occupancy">
        <mat-radio-button class="p-2" [checked]="true" value="1"> Vacant </mat-radio-button>

        <mat-radio-button class="p-2" value="2"> Tenanted </mat-radio-button>

        <mat-radio-button class="p-2" value="3"> Multi-Tenanted </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select Lease Length</mat-label>
        <mat-select placeholder="Select Lease Length" formControlName="lengthOfLeaseRequired">
          <mat-option *ngFor="let option of lengthOfLeaseOptions" [value]="option.value">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="beneficialOccupation">Beneficial Occupation </mat-checkbox>
    </div>

    <div fxFlex="100" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="tenantInstallationAllowance">Tenant Installation Allowance Offered </mat-checkbox>
    </div>

    <div fxFlex="100" fxLayoutAlign="center center" class="py-2">
      <mat-radio-group formControlName="buildingType">
        <mat-radio-button fxFlex="50" class="px-3" [checked]="true" value="1"> Stand-Alone </mat-radio-button>

        <mat-radio-button fxFlex="50" class="px-3" value="2"> Building </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Security Features</mat-label>
        <textarea matInput placeholder="Security Features" formControlName="securityFeatures" rows="7"></textarea>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Operation Costs</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="opsCosts" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.opsCosts?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="generator">Generator</mat-checkbox>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="airconditioning">Airconditioning</mat-checkbox>
    </div>

    <div fxFlex="34" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="lifts">Lifts</mat-checkbox>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="disabilityAccess">Disability Access</mat-checkbox>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="kitchenette">Kitchenette</mat-checkbox>
    </div>

    <div fxFlex="34" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="canteen">Canteen</mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Rates</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="rates" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Levies</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="levies" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Undercover Parkings</mat-label>
        <input type="number" [min]="0" matInput placeholder="Undercover Parkings" formControlName="undercoverParkings" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.undercoverParkings?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Open Parkings</mat-label>
        <input type="number" [min]="0" matInput placeholder="Open Parkings" formControlName="openParkings" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.openParkings?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Zoning</mat-label>
        <mat-select placeholder="Select Zoning" formControlName="zoning" required>
          <mat-option *ngFor="let option of ['Industrial', 'Commercial', 'Mixed Use', 'Retail']" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="step-section pb-2">
      <p class="mb-0"><span class="uppercase fw-500">Property Documents</span><span class="text-muted mx-3">(max 8 files, optional)</span></p>
      <input-file (deletedFile)="deleteDocumentEvent($event)" formControlName="documents" fileLimit="8" sizeLimit="2" fileAccept="*"> </input-file>
    </div>
  </form>
</div>
