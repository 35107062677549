import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-agricultural-on-auction',
  templateUrl: './agricultural-on-auction.component.html',
  styleUrls: ['./agricultural-on-auction.component.scss'],
})
export class AgriculturalOnAuctionComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  koppenGeigerClimateClassificationOptions = [
    'Af Equatorial, Fully humid',
    'Am Equatorial, Monsoonal',
    'Aw Equatorial, Winter dry',
    'BWh Arid, Desert, Hot arid',
    'BWk Arid Desert, Cold arid',
    'BSh Arid, Steppe, Hot arid',
    'BSk Arid, Steppe, Cold arid',
    'Csa Warm temperate, Summer dry, Hot summer',
    'Csb Warm temperate, Summer dry, Warm summer',
    'Csc Warm temperate, Summer dry, Cool summer',
    'Cwa Warm temperate, Winter dry, Hot summer',
    'Cwb Warm temperate, Winter dry, Warm summer',
    'Cwc Warm temperate, winter dry, Cool summer',
    'Cfa Warm temperate, Fully humid, Hot summer',
    'Cfb Warm temperate, Fully humid, Warm summer',
    'Cfc Warm temperate, Fully humid, Cool summer',
    'Dsa Snow, Summer dry, Hot summer',
    'Dsb Snow, Summer dry, Warm summer',
    'Dsc Snow, Summer dry, Cool summer',
    'Dsd Snow, Summer dry, Extremely continental',
    'Dwa Snow, Winter dry, Hot summer',
    'Dwb Snow, Winter dry, Warm summer',
    'Dwc Snow, Winter dry, Cool summer',
    'Dwd Snow, Winter dry, Extremely continental',
    'Dfa Snow, Fully humid, Hot summer',
    'Dfb Snow, Fully humid, Warm summer',
    'Dfc Snow, Fully humid, Cool summer',
    'Dfd Snow, Fully humid, Extremely continental',
    'ET Polar, Tundra',
    'EF Polar, Frost',
  ];

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      auctionDate: [null, Validators.required],
      price: ['', Validators.required],
      poa: [null],
      pricePerHa: ['', Validators.required],
      pricePerHaPoa: [false],
      extent: [null, Validators.required],
      currentUse: [null, Validators.required],
      koppenGeigerClimateClassification: ['Af Equatorial, Fully humid', Validators.required],
      improvements: [null, Validators.required],
      waterRights: [false],
      hasDams: [true],
      dams: [null],
      boreHoles: [false],
      riperianRights: [false],
      averageRainfall: [null],
      documents: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });
    this.subscriptions.push(formSubscription);
  }

  hasDamsChangeEvent(checked: boolean): any {
    this.featuresForm.controls.dams.setValue(null);
    if (checked) {
      this.featuresForm.controls.dams.enable();
    } else {
      this.featuresForm.controls.dams.disable();
    }
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  poaPriceHaEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.pricePerHa.setValue(0);
    } else {
      this.featuresForm.controls.pricePerHa.setValue(null);
    }
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
