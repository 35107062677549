<div *ngIf="blog?.status?.name === 'Published' || (this.authState.isAdmin && !this.authState.loading && !this.flag && isBrowser)">
  <mat-card>
    <mat-card-header fxLayoutAlign.lt-md="center center" fxLayoutAlign.gt-sm="start">
      <img *ngIf="!preview" (error)="setDefaultImage()" [attr.src]="blog?.image?.small" mat-card-avatar class="example-header-image" />
      <mat-card-title fxLayoutAlign.gt-sm="start" fxLayoutAlign.lt-md="center center">{{ blog?.name | titlecase }} </mat-card-title>
      <mat-card-subtitle fxLayoutAlign.gt-sm="start" fxLayoutAlign.lt-md="center center"
        >{{ blog?.owner?.profile?.first_name ?? 'an unknown ' | titlecase }}
        {{ blog?.owner?.profile?.last_name ?? 'publisher' | titlecase }}
        on
        {{ blog?.created_date | date }}</mat-card-subtitle
      >
    </mat-card-header>
    <div fxLayout>
      <div *ngIf="!preview" fxLayout="column" fxLayoutAlign="center">
        <mat-card-content>
          <div class="desc mx-3" [innerHTML]="html"></div>
        </mat-card-content>
      </div>
      <div *ngIf="preview" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
        <img alt="Blog Post Thumbnail" (error)="setDefaultImage()" [attr.src]="blog?.image?.small" style="border-radius: 4px; height: 200px; width: 200px" />
        <mat-card-content>
          <div class="text mx-3" [innerHTML]="html"></div>
        </mat-card-content>
      </div>
    </div>
    <mat-card-actions>
      <button *ngIf="preview" mat-button (click)="viewBlog(blog?.id)">READ MORE</button>
      <app-share-page [href]="getBlogLink(blog?.id)"></app-share-page>
      <button *ngIf="authState?.isLoggedIn && authState?.isAdmin" (click)="editBlog()" mat-button>EDIT</button>
      <button color="warn" *ngIf="authState?.isLoggedIn && authState?.isAdmin" (click)="deleteBlog()" mat-button>DELETE</button>
      <button mat-button color="warn" class="uppercase" *ngIf="blog?.status?.name !== 'Published'">(Draft)</button>
    </mat-card-actions>
  </mat-card>
</div>
