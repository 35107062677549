<form [formGroup]="form" class="search-wrapper" [ngClass]="'variant-' + variant">
  <div [fxLayout]="vertical ? 'column' : 'row wrap'">
    <div fxFlex="100" class="p-2">
      <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()" class="w-100">
        <mat-label>Location</mat-label>
        <input matInput placeholder="Search By Location" [matAutocomplete]="auto" formControlName="city" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of locationAutoCompleteSuggestions" [value]="option.name | titlecase">
            {{ option.name | titlecase }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxFlex="25" fxFlex.lt-md="100" class="p-2">
      <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()">
        <mat-label>Min Price</mat-label>
        <input matInput placeholder="Any" formControlName="priceFrom" [matAutocomplete]="minimum" type="number" />
        <mat-autocomplete autoActiveFirstOption #minimum="matAutocomplete">
          <mat-option *ngFor="let option of priceAutoComplete" [value]="option"> R {{ option | number_suffix }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxFlex="25" fxFlex.lt-md="100" class="p-2">
      <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()">
        <mat-label>Max Price</mat-label>
        <input matInput placeholder="Any" formControlName="priceTo" [matAutocomplete]="maximum" type="number" />
        <mat-autocomplete autoActiveFirstOption #maximum="matAutocomplete">
          <mat-option *ngFor="let option of priceAutoComplete" [value]="option"> R {{ option | number_suffix }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxFlex="25" fxFlex.lt-md="100" class="p-2">
      <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()">
        <mat-label>Property Type</mat-label>
        <mat-select placeholder="Select Property Type" formControlName="propertyType">
          <mat-option *ngFor="let option of propertyTypeOptions" [value]="option.value">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25" fxFlex.lt-md="100" class="p-2">
      <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()" class="w-100">
        <mat-label>Listing Type</mat-label>
        <mat-select placeholder="Select Listing Type" formControlName="listingType">
          <mat-option *ngFor="let option of listingTypeOptions" [value]="option.value">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="showMore" [fxLayout]="vertical ? 'column' : 'row wrap'" class="w-100">
      <div fxFlex="50" fxFlex.lt-md="100" class="p-2">
        <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()" class="w-100">
          <mat-label>Reference/Keyword</mat-label>
          <input matInput placeholder="Search By Reference/Keyword" formControlName="keyword" />
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100" class="p-2">
        <mat-form-field [class.container]="theme === 'capx2'" [appearance]="getAppearance()" [floatLabel]="getFloatLabel()">
          <mat-label>Date</mat-label>
          <mat-select placeholder="Search By Date" formControlName="date">
            <mat-option *ngFor="let option of publishDateFilterOptions" [value]="option.value">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="p-2" fxLayout.xs="column" fxLayoutGap="10px">
    <button mat-raised-button color="warn" class="uppercase mx-1" (click)="reset()">CLEAR</button>
    <button *ngIf="!vertical" mat-raised-button color="primary" class="uppercase mx-1" (click)="showMore = !showMore">
      {{ showMore ? 'HIDE' : 'ADVANCED' }}
    </button>
    <button *ngIf="searchOnBtnClick" mat-raised-button color="primary" class="search-btn mx-1" (click)="search()"><mat-icon>search</mat-icon> Search</button>
  </div>
</form>
