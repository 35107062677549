import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SearchService } from '@app/pages/properties/properties-search/search.service';
import packageInfo from 'package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public version = packageInfo.version;

  public locationsCol1 = ['Gauteng', 'Kwazulu Natal', 'Western Cape'];
  public locationsCol2 = ['Free State', 'Mpumalanga', 'Eastern Cape'];
  public locationsCol3 = ['Limpopo', 'North West', 'Northern Cape'];

  constructor(public formBuilder: UntypedFormBuilder, private searchService: SearchService) {}

  public search(filter: string): any {
    this.searchService.searchForm.patchValue({ city: filter });
    this.searchService.search();
  }
}
