import { Component, Input } from '@angular/core';
import { platformService } from '@app/core/services/platform.service';
import { PropertyComponentStore } from '@app/core/stores/property/property.component.store';
import { takeWhile, tap } from 'rxjs';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.scss'],
})
export class FeaturedPropertiesComponent {
  @Input() config: SwiperOptions;
  @Input() onHomePage = false;
  @Input() listingType = 3;
  @Input() propertyType = 5;
  private flag = false;

  $featuredProperties = this.propertyStore
    .select((state) => state.featuredProperties)
    .pipe(
      tap({
        next: (response) => {
          const params = {
            listing_type: this.listingType ?? 3,
            property_type: this.propertyType ?? 5,
            city: '',
            suburb: '',
            province: '',
            country: 'SOUTH AFRICA',
          };

          if (!response && !this.flag) {
            this.flag = true;
            this.propertyStore.fetchFeaturedPropertiesForHomePage(params);
          }
        },
      }),
      takeWhile((response) => !response, true),
    );

  constructor(private propertyStore: PropertyComponentStore, public platform: platformService) {
    this.propertyStore.patchState({ featuredProperties: null });
  }
}
