import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@app/core/services/web.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { Observable } from 'rxjs';
import { Endpoints } from '../../utils/endpoints.dev';
import { Property } from './property.model';

const EXPAND_QUERY_NEW_SEARCH = '?';
const EXPAND_QUERY = '?expand=property_type,listing_type,owner,property_image,status,property_attribute,property_attribute.attribute,property_address,property_document,property_attribute.attribute.attribute_option';
const EXPAND_QUERY_NO_FEATURES = '?status=1&expand=listing_type,property_type,addresses,images,owner,status';

@Injectable({ providedIn: 'root' })
export class PropertyService extends WebService<Property> {
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  bulkUpload(item: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.BULK_UPLOADS, item, { headers: this.headers });
  }

  saveProperty(item: any): Observable<any> {
    return this.httpClient.patch<any>(item.url, item, {
      headers: this.headers,
    });
  }

  updateProperty(request: any): Observable<any> {
    return this.httpClient.put<any>(request.url, request.data, {
      headers: this.headers,
    });
  }

  getByUrl(url: string): Observable<any> {
    return this.httpClient.get(url, {
      headers: this.headers,
    });
  }

  getIDFromUrl(url: string): number {
    return Number.parseInt(url.slice(0, -1).split('/').pop());
  }

  getlistedQuery(request: any, name: string): string {
    let query = '';

    request.forEach((item) => {
      query += `&${name}=${this.getIDFromUrl(item.url)}`;
    });
    return query;
  }

  checkIfProvince(location: string): string {
    if (!location) {
      return '';
    }
    switch (location.toUpperCase()) {
      case 'KWAZULU NATAL':
        return '&province=KwaZulu Natal';
      case 'GAUTENG':
        return '&province=Gauteng';
      case 'WESTERN CAPE':
        return '&province=Western Cape';
      case 'NORTHERN CAPE':
        return '&province=Northern Cape';
      case 'LIMPOPO':
        return '&province=Limpopo';
      case 'NORTH WEST':
        return '&province=North West';
      case 'FREE STATE':
        return '&province=Free State';
      case 'MPUMALANGA':
        return '&province=Mpumalanga';
      case 'EASTERN CAPE':
        return '&province=Eastern Cape';
    }
    return `&city=${location}`;
  }

  getMany(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(
      request?.url ??
        Endpoints.BASE_URL +
          Endpoints.PROPERTIES_MANAGE +
          EXPAND_QUERY +
          `&price_min=${request?.priceFrom ? request.priceFrom * 100 : ''}` +
          `&price_max=${request?.priceTo * 100 ? request.priceTo * 100 : ''}` +
          `&search_term=${request?.keyword ?? ''}` +
          `&published_date=${request?.date ?? ''}` +
          `&ordering=${request?.ordering ?? ''}` +
          `&property_type=${request?.propertyType ?? ''}` +
          `&listing_type=${request?.listingType ?? ''}` +
          `&owner=${request?.owner ?? ''}` +
          `&limit=${request?.size ?? 6}` +
          `&offset=${(request?.page ?? 1) === 1 ? 0 : (request?.page - 1) * request?.size ?? 6}` +
          this.checkIfProvince(request.city) ??
        '',
      {
        headers: this.headers,
      },
    );
  }

  getLeadsForProperty(request: any): Observable<PagedList<any>> {
    return this.httpClient.get<PagedList<any>>(Endpoints.BASE_URL + Endpoints.PROPERTIES_LEADS + '?expand=lead&limit=999' + `&${this.objectToQueryString(request)}`);
  }

  getManyNewSearch(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(
      request?.url ??
        Endpoints.BASE_URL +
          Endpoints.PROPERTIES_SEARCH_FAST +
          EXPAND_QUERY_NEW_SEARCH +
          `&price_min=${request?.priceFrom ? request.priceFrom * 100 : ''}` +
          `&price_max=${request?.priceTo * 100 ? request.priceTo * 100 : ''}` +
          `&search_term=${request?.keyword ?? ''}` +
          `&published_date=${request?.date ?? ''}` +
          `&ordering=${request?.ordering ?? ''}` +
          `&property_type=${request?.propertyType ?? ''}` +
          `&listing_type=${request?.listingType ?? ''}` +
          `&owner=${request?.owner ?? ''}` +
          `&limit=${request?.size ?? 6}` +
          `&offset=${(request?.page ?? 1) === 1 ? 0 : (request?.page - 1) * request?.size ?? 6}` +
          this.checkIfProvince(request.city) ??
        '',
      {
        headers: this.headers,
      },
    );
  }

  getManyNoFeatures(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(
      request?.url ??
        Endpoints.BASE_URL +
          Endpoints.PROPERTIES_MANAGE +
          EXPAND_QUERY_NO_FEATURES +
          `&price_min=${request?.priceFrom ? request.priceFrom * 100 : ''}` +
          `&price_max=${request?.priceTo * 100 ? request.priceTo * 100 : ''}` +
          `&search_term=${request?.keyword ?? ''}` +
          `&published_date=${request?.date ?? ''}` +
          `&ordering=${request?.ordering ?? ''}` +
          `&property_type=${request?.propertyType ?? ''}` +
          `&listing_type=${request?.listingType ?? ''}` +
          `&owner=${request?.owner ?? ''}` +
          `&limit=${request?.size ?? 6}` +
          `&offset=${(request?.page ?? 1) === 1 ? 0 : (request?.page - 1) * request?.size ?? 6}` +
          this.checkIfProvince(request.city) ??
        '',
      {
        headers: this.headers,
      },
    );
  }

  getBulkProperties(request: any): Observable<any> {
    return this.httpClient.get(Endpoints.BASE_URL + Endpoints.BULK_RECORDS + `?${this.objectToQueryString(request)}`);
  }

  getCities(request: any): Observable<any> {
    return this.httpClient.get(Endpoints.BASE_URL + Endpoints.DATA_ADDRESSES_CITIES + '?country=SOUTH AFRICA', {
      headers: this.headers,
    });
  }

  getOne(request: any): Observable<Property> {
    return this.httpClient.get<Property>(request.url ?? Endpoints.BASE_URL + Endpoints.PROPERTIES_MANAGE + `${request.id}/` + EXPAND_QUERY, {
      headers: this.headers,
    });
  }

  getOneNewSearch(request: any): Observable<Property> {
    return this.httpClient.get<Property>(request.url ?? Endpoints.BASE_URL + Endpoints.PROPERTIES_SEARCH_FAST + `${request.id}/`, {
      headers: this.headers,
    });
  }

  getFeaturedProperties(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(request.url ?? Endpoints.BASE_URL + Endpoints.PROPERTIES_FEATURED + EXPAND_QUERY + `&${this.objectToQueryString(request)}`, {
      headers: this.headers,
    });
  }

  getFeaturedPropertiesForHomePage(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(request.url ?? Endpoints.BASE_URL + Endpoints.PROPERTIES_FEATURED_HOME + `?${this.objectToQueryString(request)}`, {
      headers: this.headers,
    });
  }

  getRelatedProperties(request: any): Observable<PagedList<Property>> {
    return this.httpClient.get<PagedList<Property>>(request.url ?? Endpoints.BASE_URL + Endpoints.PROPERTIES_RELATED + EXPAND_QUERY + `&${this.objectToQueryString(request)}`, {
      headers: this.headers,
    });
  }

  createImage(item: any): Observable<any> {
    return this.httpClient.post<any>(Endpoints.BASE_URL + Endpoints.IMAGES, item, { headers: this.headers });
  }

  createProperty(item: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROPERTIES_MANAGE, item, {
      headers: this.headers,
    });
  }

  assignSubscription(item: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROPERTIES_MANAGE + item.property_id + '/assign-subscription', item.data, {
      headers: this.headers,
    });
  }

  createAddress(item: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.ADDRESSES, item, {
      headers: this.headers,
    });
  }

  createDocument(item: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.DOCUMENTS, item, {
      headers: this.headers,
    });
  }

  getUrl(request: any): Observable<any> {
    return this.httpClient.get<any>(request, { headers: this.headers });
  }

  createPropertyAttribute(request: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROPERTY_ATTRIBUTES, request, {
      headers: this.headers,
    });
  }

  removePropertyAttribute(id: number): Observable<any> {
    return this.httpClient.delete(Endpoints.BASE_URL + Endpoints.PROPERTY_ATTRIBUTES + id + '/', {
      headers: this.headers,
    });
  }
}
