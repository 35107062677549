import { Component, Input } from '@angular/core';
import { PropertyComponentStore } from '@app/core/stores/property/property.component.store';
import { takeWhile, tap } from 'rxjs';

@Component({
  selector: 'app-related-properties',
  templateUrl: 'related-properties.component.html',
  styleUrls: ['related-properties.component.scss'],
})
export class RelatedPropertiesComponent {
  @Input() listingType: any = 3;
  @Input() propertyType: any = 5;
  @Input() city = '';
  @Input() suburb = '';
  @Input() province = '';
  private flag = false;

  $relatedProperties = this.propertyStore
    .select((state) => state.relatedProperties)
    .pipe(
      tap({
        next: (response) => {
          if (!response) {
            if (!this.flag) {
              this.propertyStore.fetchRelatedProperties({
                listing_type: this.listingType ?? 3,
                property_type: this.propertyType ?? 5,
                city: this.city?.replace('Metro', '').trim() ?? '',
                suburb: this.suburb?.replace('Metro', '').trim() ?? '',
                province: this.province ?? '',
                country: 'SOUTH AFRICA',
              });
              this.flag = true;
            }
          }
        },
      }),
      takeWhile((response) => !response, true),
    );

  constructor(private propertyStore: PropertyComponentStore) {
    this.propertyStore.patchState({ relatedProperties: null });
  }
}
