<mat-sidenav-container>
  <mat-sidenav *ngIf="isBrowser" [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
    <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>

    <div perfectScrollbar class="vertical-menu-wrapper">
      <app-vertical-menu [menuParentId]="0"></app-vertical-menu>
    </div>

    <div class="divider"></div>
    <app-social-icons [iconSize]="'xlg'" [iconColor]="'primary'" fxLayout="row" fxLayoutAlign="start center"> </app-social-icons>
  </mat-sidenav>

  <mat-sidenav-content class="page-wrapper" fxLayout="column" fxLayoutAlign="start stretch" style="min-height: 100vh">
    <header *ngIf="isBrowser" [ngClass]="'toolbar-' + settings.toolbar" [class.has-bg-video]="settings.headerBgVideo" [class.main-toolbar-fixed]="settings.mainToolbarFixed">
      <app-toolbar1 *ngIf="+settings.toolbar === 1" (menuIconClickEvent)="sidenav.toggle()"></app-toolbar1>
      <app-toolbar2 *ngIf="+settings.toolbar === 2" (menuIconClickEvent)="sidenav.toggle()"></app-toolbar2>
    </header>

    <main [class.content-offset-to-top]="settings.contentOffsetToTop">
      <router-outlet></router-outlet>
    </main>

    <div class="sticky-footer-helper" fxFlex="grow"></div>

    <app-footer *ngIf="isBrowser"></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
      <mat-icon>arrow_upward</mat-icon>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
