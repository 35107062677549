<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">Digital Marketing At Your Fingertips</h4>
  </div>
</div>

<div mat-dialog-content>
  <p class="text-center">
    Either promote your listings effortlessly on the platform, or
    <a class="primary-color link" (click)="openEmailClient()">speak to our team</a>
    about letting us handle the digital marketing of your portfolio.
  </p>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" class="pb-4">
  <button mat-raised-button color="primary" class="uppercase" [mat-dialog-close]>Ok</button>
</div>
