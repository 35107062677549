<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">Largest Commercial And Industrial Platform In ZA</h4>
  </div>
</div>

<div mat-dialog-content>
  <p class="text-center">No other specialised portal has as many commercial, industrial, retail and investment property listings.</p>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" class="pb-4">
  <button mat-raised-button color="primary" class="uppercase" [mat-dialog-close]>Ok</button>
</div>
