import { ComponentType } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '@app/../environments/environment';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { AppSettings } from '@app/core/app.settings';
import { AlertDialogComponent } from '@app/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, tap } from 'rxjs';

export class Data {
  constructor(public properties: any[], public compareList: any[], public favorites: any[], public locations: Location[]) {}
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    [], // locations
  );

  public url = environment.hostName + '/assets/data/';
  public apiKey = 'AIzaSyBnzqHpTtS-FLLMtzJbavj7gDVOyVEYKLw';
  addressAutocomplete: any;
  lat: number;
  lng: number;
  appService: any;
  autoCompleteResponse: any;
  selectedGoogleMapsLocation: any;
  submitForm: any;
  address1: any;
  address2: any;
  address3: any;
  city: any;
  province: any;
  country: any;

  constructor(
    public http: HttpClient,
    private bottomSheet: MatBottomSheet,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    public translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone,
  ) {}

  public getPropertiesByAgentId(agentId: string): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'properties-agentid-' + agentId + '.json');
  }

  public getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.url + 'locations.json');
  }

  public async getAddress(lat = 40.714224, lng = -73.961452): Promise<any> {
    let gmData = null;
    console.log('sending google request');
    const request = this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey).pipe(
      tap({
        next: (res) => {
          gmData = res;
        },
      }),
    );
    await firstValueFrom(request);
    console.log('gmdata is', gmData);
    console.log('google request finished');
    return gmData;
  }

  public getLatLng(address: string): any {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + this.apiKey + '&address=' + address);
  }

  public getFullAddress(lat = 40.714224, lng = -73.961452): any {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey).subscribe((data: any) => {
      return data.results[0].formatted_address;
    });
  }

  public addToCompare(property: any, component: ComponentType<unknown>, direction: any): any {
    if (!this.Data.compareList.filter((item) => item.id === property.id)[0]) {
      this.Data.compareList.push(property);

      this.bottomSheet
        .open(component, { direction })
        .afterDismissed()
        .subscribe((isRedirect) => {
          if (isRedirect) {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  public openConfirmDialog(title: string, message: string): any {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });
    return dialogRef;
  }

  public openAlertDialog(message: string): any {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '400px',
      data: message,
    });
    return dialogRef;
  }

  public getTranslateValue(key: string, param: string = null): any {
    let value = null;
    this.translateService.get(key, { param }).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  public getPropertyTypes(): any {
    return [
      { id: 1, name: 'Office' },
      { id: 2, name: 'House' },
      { id: 3, name: 'Apartment' },
    ];
  }

  public getPropertyStatuses(): any {
    return [
      { id: 1, name: 'For Sale' },
      { id: 2, name: 'For Rent' },
      { id: 3, name: 'Open House' },
      { id: 4, name: 'No Fees' },
      { id: 5, name: 'Hot Offer' },
      { id: 6, name: 'Sold' },
    ];
  }

  public getCities(): any {
    return [
      { id: 1, name: 'New York' },
      { id: 2, name: 'Chicago' },
      { id: 3, name: 'Los Angeles' },
      { id: 4, name: 'Seattle' },
    ];
  }

  public getNeighborhoods(): any {
    return [
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 },
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 },
    ];
  }

  public getProvinces(): any {
    return [
      { id: 1, name: 'Eastern Cape' },
      { id: 2, name: 'Free State' },
      { id: 3, name: 'Gauteng' },
      { id: 4, name: 'KwaZulu-Natal' },
      { id: 5, name: 'Limpopo' },
      { id: 6, name: 'Mpumalanga' },
      { id: 7, name: 'Northern Cape' },
      { id: 8, name: 'North West' },
      { id: 9, name: 'Western Cape' },
    ];
  }

  public getStreets(): any {
    return [
      { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1 },
      { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1 },
      { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
      { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
      {
        id: 5,
        name: 'Chinatown Street #1',
        cityId: 1,
        neighborhoodId: 3,
      },
      {
        id: 6,
        name: 'Chinatown Street #2',
        cityId: 1,
        neighborhoodId: 3,
      },
      { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
      { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
      {
        id: 9,
        name: 'Englewood Street #1',
        cityId: 2,
        neighborhoodId: 5,
      },
      {
        id: 10,
        name: 'Englewood Street #2',
        cityId: 2,
        neighborhoodId: 5,
      },
      {
        id: 11,
        name: 'Riverdale Street #1',
        cityId: 2,
        neighborhoodId: 6,
      },
      {
        id: 12,
        name: 'Riverdale Street #2',
        cityId: 2,
        neighborhoodId: 6,
      },
      {
        id: 13,
        name: 'Hollywood Street #1',
        cityId: 3,
        neighborhoodId: 7,
      },
      {
        id: 14,
        name: 'Hollywood Street #2',
        cityId: 3,
        neighborhoodId: 7,
      },
      {
        id: 15,
        name: 'Sherman Oaks Street #1',
        cityId: 3,
        neighborhoodId: 8,
      },
      {
        id: 16,
        name: 'Sherman Oaks Street #2',
        cityId: 3,
        neighborhoodId: 8,
      },
      {
        id: 17,
        name: 'Highland Park Street #1',
        cityId: 3,
        neighborhoodId: 9,
      },
      {
        id: 18,
        name: 'Highland Park Street #2',
        cityId: 3,
        neighborhoodId: 9,
      },
      {
        id: 19,
        name: 'Belltown Street #1',
        cityId: 4,
        neighborhoodId: 10,
      },
      {
        id: 20,
        name: 'Belltown Street #2',
        cityId: 4,
        neighborhoodId: 10,
      },
      {
        id: 21,
        name: 'Queen Anne Street #1',
        cityId: 4,
        neighborhoodId: 11,
      },
      {
        id: 22,
        name: 'Queen Anne Street #2',
        cityId: 4,
        neighborhoodId: 11,
      },
      {
        id: 23,
        name: 'Green Lake Street #1',
        cityId: 4,
        neighborhoodId: 12,
      },
      {
        id: 24,
        name: 'Green Lake Street #2',
        cityId: 4,
        neighborhoodId: 12,
      },
    ];
  }

  public getFeatures(): any {
    return [
      { id: 1, name: 'Air Conditioning', selected: false },
      { id: 2, name: 'Barbeque', selected: false },
      { id: 3, name: 'Dryer', selected: false },
      { id: 4, name: 'Microwave', selected: false },
      { id: 5, name: 'Refrigerator', selected: false },
      { id: 6, name: 'TV Cable', selected: false },
      { id: 7, name: 'Sauna', selected: false },
      { id: 8, name: 'WiFi', selected: false },
      { id: 9, name: 'Fireplace', selected: false },
      { id: 10, name: 'Swimming Pool', selected: false },
      { id: 11, name: 'Gym', selected: false },
    ];
  }

  public getHomeCarouselSlides(): any {
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  public filterData(data: any[], params: any, sort?: string, page?: number, perPage?: number): any {
    if (params) {
      if (params.propertyType) {
        data = data.filter((property: { propertyType: any }) => property.propertyType === params.propertyType.name);
      }

      if (params.propertyStatus && params.propertyStatus.length) {
        const statuses = [];
        params.propertyStatus.forEach((status: { name: any }) => {
          statuses.push(status.name);
        });
        const properties = [];
        data.filter((property: { propertyStatus: any[] }) =>
          property.propertyStatus.forEach((status: any) => {
            if (statuses.indexOf(status) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          }),
        );
        data = properties;
      }

      if (params.price) {
        if (this.appSettings.settings.currency === 'USD') {
          if (params.priceFrom) {
            data = data.filter((property: { priceDollar: { sale: number; rent: number } }) => {
              if (property.priceDollar.sale && property.priceDollar.sale >= params.priceFrom) {
                return true;
              }
              if (property.priceDollar.rent && property.priceDollar.rent >= params.priceFrom) {
                return true;
              }
              return false;
            });
          }
          if (params.priceTo) {
            data = data.filter((property: { priceDollar: { sale: number; rent: number } }) => {
              if (property.priceDollar.sale && property.priceDollar.sale <= params.priceTo) {
                return true;
              }
              if (property.priceDollar.rent && property.priceDollar.rent <= params.priceTo) {
                return true;
              }
              return false;
            });
          }
        }
        if (this.appSettings.settings.currency === 'EUR') {
          if (params.priceFrom) {
            data = data.filter((property: { priceEuro: { sale: number; rent: number } }) => {
              if (property.priceEuro.sale && property.priceEuro.sale >= params.priceFrom) {
                return true;
              }
              if (property.priceEuro.rent && property.priceEuro.rent >= params.priceFrom) {
                return true;
              }
              return false;
            });
          }
          if (params.priceTo) {
            data = data.filter((property: { priceEuro: { sale: number; rent: number } }) => {
              if (property.priceEuro.sale && property.priceEuro.sale <= params.priceTo) {
                return true;
              }
              if (property.priceEuro.rent && property.priceEuro.rent <= params.priceTo) {
                return true;
              }
              return false;
            });
          }
        }
      }

      if (params.city) {
        data = data.filter((property: { city: any }) => property.city === params.city.name);
      }

      if (params.zipCode) {
        data = data.filter((property: { zipCode: any }) => property.zipCode === params.zipCode);
      }

      if (params.neighborhood && params.neighborhood.length) {
        const neighborhoods = [];
        params.neighborhood.forEach((item: { name: any }) => {
          neighborhoods.push(item.name);
        });
        const properties = [];
        data.filter((property: { neighborhood: any[] }) =>
          property.neighborhood.forEach((item: any) => {
            if (neighborhoods.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          }),
        );
        data = properties;
      }

      if (params.street && params.street.length) {
        const streets = [];
        params.street.forEach((item: { name: any }) => {
          streets.push(item.name);
        });
        const properties = [];
        data.filter((property: { street: any[] }) =>
          property.street.forEach((item: any) => {
            if (streets.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          }),
        );
        data = properties;
      }

      if (params.bedrooms) {
        if (params.bedrooms.from) {
          data = data.filter((property: { bedrooms: number }) => property.bedrooms >= params.bedrooms.from);
        }
        if (params.bedrooms.to) {
          data = data.filter((property: { bedrooms: number }) => property.bedrooms <= params.bedrooms.to);
        }
      }

      if (params.bathrooms) {
        if (params.bathrooms.from) {
          data = data.filter((property: { bathrooms: number }) => property.bathrooms >= params.bathrooms.from);
        }
        if (params.bathrooms.to) {
          data = data.filter((property: { bathrooms: number }) => property.bathrooms <= params.bathrooms.to);
        }
      }

      if (params.garages) {
        if (params.garages.from) {
          data = data.filter((property: { garages: number }) => property.garages >= params.garages.from);
        }
        if (params.garages.to) {
          data = data.filter((property: { garages: number }) => property.garages <= params.garages.to);
        }
      }

      if (params.area) {
        if (params.area.from) {
          data = data.filter((property: { area: { value: number } }) => property.area.value >= params.area.from);
        }
        if (params.area.to) {
          data = data.filter((property: { area: { value: number } }) => property.area.value <= params.area.to);
        }
      }

      if (params.yearBuilt) {
        if (params.yearBuilt.from) {
          data = data.filter((property: { yearBuilt: number }) => property.yearBuilt >= params.yearBuilt.from);
        }
        if (params.yearBuilt.to) {
          data = data.filter((property: { yearBuilt: number }) => property.yearBuilt <= params.yearBuilt.to);
        }
      }

      if (params.features) {
        const arr = [];
        params.features.forEach((feature: { selected: any; name: any }) => {
          if (feature.selected) {
            arr.push(feature.name);
          }
        });
        if (arr.length > 0) {
          const properties = [];
          data.filter((property: { features: any[] }) =>
            property.features.forEach((feature: any) => {
              if (arr.indexOf(feature) > -1) {
                if (!properties.includes(property)) {
                  properties.push(property);
                }
              }
            }),
          );
          data = properties;
        }
      }
    }

    // console.log(data)

    // for show more properties mock data
    for (let index = 0; index < 2; index++) {
      data = data.concat(data);
    }

    this.sortData(sort, data);
    return this.paginator(data, page, perPage);
  }

  public sortData(sort: any, data: any[]): any {
    if (sort) {
      switch (sort) {
        case 'Newest':
          data = data.sort((a: { published: string | number | Date }, b: { published: string | number | Date }) => (new Date(b.published) as any) - (new Date(a.published) as any));
          break;
        case 'Oldest':
          data = data.sort((a: { published: string | number | Date }, b: { published: string | number | Date }) => (new Date(a.published) as any) - (new Date(b.published) as any));
          break;
        case 'Popular':
          data = data.sort((a: { ratingsValue: number; ratingsCount: number }, b: { ratingsValue: number; ratingsCount: number }) => {
            if (a.ratingsValue / a.ratingsCount < b.ratingsValue / b.ratingsCount) {
              return 1;
            }
            if (a.ratingsValue / a.ratingsCount > b.ratingsValue / b.ratingsCount) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (Low to High)':
          if (this.appSettings.settings.currency === 'USD') {
            data = data.sort((a: { priceDollar: { sale: any; rent: any } }, b: { priceDollar: { sale: any; rent: any } }) => {
              if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                return 1;
              }
              if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                return -1;
              }
              return 0;
            });
          }
          if (this.appSettings.settings.currency === 'EUR') {
            data = data.sort(
              (
                a: { priceEuro: { sale: any; rent: any } },
                b: {
                  priceEuro: { sale: any; rent: any };
                  v: { rent: any };
                },
              ) => {
                if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)) {
                  return 1;
                }
                if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)) {
                  return -1;
                }
                return 0;
              },
            );
          }
          break;
        case 'Price (High to Low)':
          if (this.appSettings.settings.currency === 'USD') {
            data = data.sort((a: { priceDollar: { sale: any; rent: any } }, b: { priceDollar: { sale: any; rent: any } }) => {
              if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                return 1;
              }
              if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                return -1;
              }
              return 0;
            });
          }
          if (this.appSettings.settings.currency === 'EUR') {
            data = data.sort(
              (
                a: { priceEuro: { sale: any; rent: any } },
                b: {
                  priceEuro: { sale: any; rent: any };
                  v: { rent: any };
                },
              ) => {
                if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)) {
                  return 1;
                }
                if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)) {
                  return -1;
                }
                return 0;
              },
            );
          }
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items: string | any[], page?: number, perPage?: number): any {
    page = page || 1;
    perPage = perPage || 4;
    const offset = (page - 1) * perPage;
    const paginatedItems = items.slice(offset).slice(0, perPage);
    const totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination: {
        page,
        perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages,
      },
    };
  }
}
