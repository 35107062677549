<div fxLayout="row">
  <!-- <div *ngIf="authState?.isAdmin && authState.isLoggedIn" fxLayout="row" fxLayoutAlign="center center">
        <a mat-icon-button (click)="changeTheme()">
            <mat-icon class="mat-icon-sm p-2" *ngIf="!theme">dark_mode</mat-icon>
            <mat-icon class="mat-icon-sm p-2" *ngIf="theme">light_mode</mat-icon>
        </a>
    </div> -->

  <div *ngIf="authState?.isLoggedIn">
    <a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
      <mat-icon class="mat-icon-sm">person</mat-icon>
      <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">ACCOUNT</span>
      <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu">
      <span (mouseleave)="userMenuTrigger.closeMenu()">
        <div fxLayout="row" fxLayoutAlign="start center" class="user-info">
          <img src="assets/images/userProfileX.png" alt="Capx2 User Avatar" width="40" height="45" />

          <p class="m-0" style="font-weight: bold">
            {{ authState?.profile?.first_name }}
            {{ authState?.profile?.last_name }}<br />
            <small style="font-weight: 500"
              ><i class="text-muted">{{ (authState?.profile?.type?.name ?? 'Unknown') + '
                Account' }}</i></small
            >
          </p>
        </div>
        <div class="divider"></div>
        <a mat-menu-item routerLink="/submit-property">
          <mat-icon class="mat-icon-sm">add_circle</mat-icon>
          <span>List My Property</span>
        </a>
        <a mat-menu-item routerLink="/account/my-properties">
          <mat-icon class="mat-icon-sm">home</mat-icon>
          <span>MY PROPERTIES</span>
        </a>
        <!-- <a mat-menu-item routerLink="/account/bulk-uploads">
                    <mat-icon class="mat-icon-sm">difference</mat-icon>
                    <span>BULK UPLOADS</span>
                </a> -->
        <a mat-menu-item routerLink="/account/favorites">
          <mat-icon class="mat-icon-sm">favorite_border</mat-icon>
          <span [matBadge]="appService.Data.favorites.length" [matBadgeHidden]="appService.Data.favorites.length === 0" matBadgeSize="small" matBadgeColor="warn" matBadgeOverlap="false"> FAVOURITES</span>
        </a>
        <a mat-menu-item routerLink="/account/subscriptions">
          <mat-icon class="mat-icon-sm">subscriptions</mat-icon>
          <span>SUBSCRIPTIONS</span>
        </a>
        <a mat-menu-item routerLink="/account/invoices">
          <mat-icon class="mat-icon-sm">receipt</mat-icon>
          <span>INVOICES</span>
        </a>
        <a mat-menu-item routerLink="/account/profile">
          <mat-icon class="mat-icon-sm">edit</mat-icon>
          <span>EDIT PROFILE</span>
        </a>
        <!-- <a mat-menu-item routerLink="/account/organization">
          <mat-icon class="mat-icon-sm">domain_add</mat-icon>
          <span>ORGANIZATION</span>
        </a> -->
        <a mat-menu-item routerLink="/faq">
          <mat-icon class="mat-icon-sm">help</mat-icon>
          <span>FAQ</span>
        </a>
        <div class="divider"></div>
        <a mat-menu-item routerLink="/auth/logout">
          <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
          <span>LOGOUT</span>
        </a>
      </span>
    </mat-menu>
  </div>

  <div *ngIf="!authState?.isLoggedIn" fxLayout="row" fxLayoutAlign="center end">
    <div>
      <a class="uppercase" mat-button routerLink="/auth/account">
        <mat-icon class="mat-icon-sm">person</mat-icon>
        Register / Login
      </a>
    </div>
  </div>
</div>
