import { Component } from '@angular/core';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { BlogComponentStore } from '@app/core/stores/blog/blog.component.store';
import { takeWhile, tap } from 'rxjs';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-blog-carousel',
  templateUrl: './blog-carousel.component.html',
  styleUrls: ['./blog-carousel.component.scss'],
})
export class BlogCarouselComponent {
  public config: SwiperOptions = {
    spaceBetween: 16,
    grabCursor: true,
    navigation: {
      nextEl: '.blog-next',
      prevEl: '.blog-prev',
    },
    pagination: { clickable: true, el: '.blog-pagination' },
  };

  $auth = this.authStore
    .select((authState) => authState)
    .pipe(
      tap({
        next: (authState) => {
          if (!authState.loading) {
            if (authState.isAdmin) {
              this.blogStore.fetchMany({});
            } else {
              this.blogStore.fetchMany({ status: '19' });
            }
          }
        },
      }),
      takeWhile((authState) => authState.loading),
    )
    .subscribe();

  $blogs = this.blogStore.select((state) => state.blogs).pipe(takeWhile((response) => !response, true));

  constructor(private blogStore: BlogComponentStore, private authStore: AuthComponentStore) {}
}
