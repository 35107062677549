import { Component, Input, isDevMode } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent {
  @Input() public height = 90;
  @Input() public theme = 2;

  public show = false;

  constructor() {
    if (isDevMode()) {
      this.show = true;
    }
  }
}
