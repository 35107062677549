import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings, Settings } from '@app/core/app.settings';
import { Subscription } from 'rxjs';
import { CanonicalService } from './core/services/canonical.service';
import { HelperUtils } from './core/services/helper-utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  public settings: Settings;
  private subscriptions: Subscription[] = [];
  constructor(public appSettings: AppSettings, public router: Router, private helperUtils: HelperUtils, private canonicalService: CanonicalService, @Inject(PLATFORM_ID) private platform) {
    if (isPlatformServer(this.platform)) {
      this.canonicalService.setCanonicalURL();
    }
    this.settings = this.appSettings.settings;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.helperUtils.scrollToTop();
        });
      }
    });
    this.subscriptions.push(routerSubscription);
  }
}
