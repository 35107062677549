<app-message-chip *ngIf="($featuredProperties | async)?.count === -1"></app-message-chip>

<div class="theme-container" *ngIf="($featuredProperties | async)?.count > -1">
  <app-properties-carousel [config]="config" [properties]="$featuredProperties | async"></app-properties-carousel>
</div>

<app-loader *ngIf="($featuredProperties | async) === null"></app-loader>

<div class="theme-container" *ngIf="($featuredProperties | async)?.count === 0">
  <h3 class="text-center p-3">No Featured Properties Found</h3>
</div>
