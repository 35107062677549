export enum Endpoints {
  WEBSITE_URL = 'https://dev.capx2.com',
  AUTH_BASE_URL = 'https://dev-api.capx2.com/api/',
  BASE_URL = 'https://dev-api.capx2.com/api/v1/',
  MEDIA_URL = 'https://capx2storagesan.blob.core.windows.net/media/',
  IMAGES_BASE_URL = 'https://capx2storagesan.blob.core.windows.net/media/__sized__/',
  IMAGE_NOT_FOUND = 'https://capx2storagesan.blob.core.windows.net/media-dev/__sized__/images/1/bb8821b1-2c0d-4222-9d8e-262704bb6079-crop-c0-5__0-5-375x298-70.jpg',
  REGISTER = 'auth/register/',
  VERIFY_REGISTRATION = 'auth/verify-registration/',
  AUTH_TOKEN = 'token',
  AUTH_SEND_RESET_PASSWORD_LINK = 'auth/send-reset-password-link/',
  AUTH_RESET_PASSWORD = 'auth/reset-password/',
  AUTH_CHANGE_PASSWORD = 'auth/change-password/',
  AUTH_SEND_VERIFICATION_EMAIL = 'auth/verify-registration/',
  AUTH_VERIFY_EMAIL = 'auth/verify-email',
  PROPERTIES_MANAGE = 'properties/manage/',
  PROPERTIES_SEARCH_FAST = 'properties/search/',
  PROPERTIES_FEATURES = 'properties/features/',
  PROPERTIES_FEATURED = 'properties/search/featured/',
  PROPERTIES_FEATURED_HOME = 'properties/search/featured/home/',
  PROPERTIES_RELATED = 'properties/search/related/',
  PROPERTIES_LEADS = 'properties/leads/',
  PROPERTY_FEATURES = 'properties/features/',
  PROPERTY_ATTRIBUTES = 'properties/attributes/',
  PROFILES = 'profiles/',
  FAVOURITES = 'profiles/wish-lists/',
  SUPPORT = 'support/',
  DATA_TYPES = 'data/types/',
  ADDRESSES = 'properties/addresses/',
  DOCUMENTS = 'properties/documents/',
  STATUS = 'data/status/',
  LISTING_TYPES = 'data/listing-types/',
  PROP_DATA_MAPPING_FIELD_FEATURES = 'partners/prop-data/mapping/field-features/',
  DATA_SEO_TAGS = 'data/seo-tags/',
  DATA_FEATURES = 'data/features/',
  DATA_ATTRIBUTES = 'data/attributes/',
  MARKETING_LEADS = 'marketing/leads/',
  SAVE_MARKETING_LEADS = 'marketing/leads/save-lead/',
  BLOGS = 'blogs/',
  IMAGES = 'properties/images/',
  USERS = 'auth/users/',
  CURRENCY_ZAR = 'currencies/ZAR/',
  BULK_UPLOADS = 'bulk_uploads/',
  BULK_RECORDS = 'bulk_records/',
  SUBSCRIPTIONS = 'subscriptions/list/',
  DATA_ADDRESSES_CITIES = 'data/addresses/cities/',
  STATS_PROPERTIES_BY_LISTING_TYPE = 'stats/properties_by_listing_type/',
  STATS_PROPERTIES_BY_PROPERTY_TYPE = 'stats/properties_by_property_type/',
  STATS_PROPERTIES_OVER_TIME = 'stats/properties_over_time/',
  STATS_USERS_BY_TYPE = 'stats/users_by_type/',
  STATS_USERS_BY_STATUS = 'stats/users_by_ui_status/',
  STATS_REVENUE_OVER_TIME = 'stats/revenue_over_time/',
  PAYGATE_NOTIFY = 'billing/payments/notify/',
  SUBSCRIPTION_FEATURES = 'subscriptions/features/',
  PROFILE_SUBSCRIPTION_FEATURES = 'profiles/subscription_features/',
  SUBSCRIPTIONS_CREATE = 'subscriptions/create/',
  SUBSCRIPTIONS_LIST = 'subscriptions/list/',
  FAVOURITES_CREATE = 'wish-lists/create/',
  FAVOURITES_DELETE = 'wish-lists/delete/',
  INVOICES = 'billing/invoices/',
  ORGANIZATIONS = 'organizations/',
  ORGANIZATION = 'organization/',
  BROKERS = 'organization/brokers/',
  CLASSIFICATIONS = 'data/classifications/',
}
