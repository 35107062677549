import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@app/core/services/web.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { Endpoints } from '../../utils/endpoints.dev';
import { JwtTokenRequest } from './auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService extends WebService<any> {
  constructor(public httpClient: HttpClient, private localStorageService: LocalStorageService) {
    super(httpClient);
  }

  createProfile(request: any): any {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROFILES, request, { headers: this.headers });
  }

  getProfile(request: any): any {
    return this.httpClient.get(Endpoints.BASE_URL + Endpoints.PROFILES + `?user=${request.id}&expand=type`, { headers: this.headers });
  }

  updateProfile(request: any): any {
    return this.httpClient.put(request?.data?.url ?? Endpoints.BASE_URL + Endpoints.PROFILES + `${request.id}/`, request.data, {
      headers: this.headers,
    });
  }

  register(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.REGISTER, request, {
      headers: this.headers,
    });
  }

  login(request: JwtTokenRequest): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.AUTH_TOKEN, request, {
      headers: this.headers,
    });
  }

  sendResetPasswordLink(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.AUTH_SEND_RESET_PASSWORD_LINK, request, {
      headers: this.headers,
    });
  }

  resetPassword(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.AUTH_RESET_PASSWORD, request, {
      headers: this.headers,
    });
  }

  changePassword(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.AUTH_CHANGE_PASSWORD, request, {
      headers: this.headers,
    });
  }

  sendVerificationEmail(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.AUTH_SEND_VERIFICATION_EMAIL, request, {
      headers: this.headers,
    });
  }

  verifyRegistration(request: any): any {
    return this.httpClient.post(Endpoints.AUTH_BASE_URL + Endpoints.VERIFY_REGISTRATION, request, {
      headers: this.headers,
    });
  }

  getFavourites(request: any): any {
    return this.httpClient.get(
      Endpoints.BASE_URL +
        Endpoints.PROFILES +
        request.id +
        '/wish-lists/?expand=property,property.property_image,property.status,property.property_attribute,property.property_attribute.attribute,property.property_address,property.property_document,property.property_attribute.attribute.attribute_option',
      {
        headers: this.headers,
      },
    );
  }

  addFavourite(request: any): any {
    const formData = new FormData();
    formData.append('property_id', request.property);
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROFILES + request.id + '/' + Endpoints.FAVOURITES_CREATE, formData, {
      headers: this.headers,
    });
  }

  removeFavourite(request: any): any {
    return this.httpClient.delete(Endpoints.BASE_URL + Endpoints.PROFILES + request.id + '/' + Endpoints.FAVOURITES_DELETE + `?property_id=${request.property}`, {
      headers: this.headers,
    });
  }

  contactUs(request: any): any {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.MARKETING_LEADS, request, { headers: this.headers });
  }

  logout(): any {
    this.localStorageService.removeItem('token');
  }
}
