import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SearchService } from '@app/pages/properties/properties-search/search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-properties-toolbar',
  templateUrl: './properties-toolbar.component.html',
  styleUrls: ['./properties-toolbar.component.scss'],
})
export class PropertiesToolbarComponent implements OnDestroy {
  @Input() isHomePage = false;
  @Input() showSidenavToggle = false;
  @Output() sidenavToggleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCountEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSortingEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeViewTypeEvent: EventEmitter<any> = new EventEmitter<any>();

  public viewType = 'grid';
  public viewCol = 25;
  public counts = [6, 9, 12, 15, 18];
  public count: any;
  public sortings = ['Sort by Default', 'Newest', 'Oldest', 'Popular', 'Price (Low to High)', 'Price (High to Low)'];
  public sort: any;
  private subscriptions: Subscription[] = [];

  constructor(private searchService: SearchService) {
    this.count = this.isHomePage ? this.counts[0] : this.counts[1];
    this.sort = this.sortings[0];

    if (this.searchService.searchForm.value.ordering) {
      this.sort = this.searchService.searchForm.value.ordering;
      this.formatSort();
    }

    const searchServiceSubscription = this.searchService.searchForm.valueChanges.subscribe(() => {
      this.sort = this.searchService.searchForm.value.ordering;
      this.formatSort();
    });
    this.subscriptions.push(searchServiceSubscription);
  }

  formatSort(): void {
    this.sort =
      this.sort == '-created_date' ? 'Newest' : this.sort == 'created_date' ? 'Oldest' : this.sort == '-views' ? 'Popular' : this.sort == '-price' ? 'Price (High to Low)' : this.sort == 'price' ? 'Price (Low to High)' : 'Sort by Default';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public changeCount(count): void {
    this.count = count;
    this.changeCountEvent.emit(count);
  }

  public changeSorting(sort): void {
    this.sort = sort;
    this.changeSortingEvent.emit(sort);
  }

  public changeViewType(viewType, viewCol): void {
    this.viewType = viewType;
    this.viewCol = viewCol;
    this.changeViewTypeEvent.emit({ viewType, viewCol });
  }

  public sidenavToggle(): void {
    this.sidenavToggleEvent.emit();
  }
}
