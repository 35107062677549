import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { catchError, concatMap, EMPTY, Observable, switchMap } from 'rxjs';
import { OptionsState } from './options.models';
import { OptionsService } from './options.service';

const DEFAULT_STATE: OptionsState = {
  categories: null,
  listingTypes: [],
  dataTypes: [],
  status: [],
  footerSearch: '',
};

@Injectable({ providedIn: 'root' })
export class OptionsComponentStore extends ComponentStore<OptionsState> {
  constructor(private optionsService: OptionsService, private helperUtils: HelperUtils) {
    super(DEFAULT_STATE);
  }

  readonly fetchManyDataTypes = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.optionsService.getManyDataTypes(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({ dataTypes: response.results });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchManyStatus = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.optionsService.getManyStatus(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({ status: response.results });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchManyListingTypes = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      switchMap((options: any) => {
        return this.optionsService.getManyListingTypes(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                listingTypes: response.results,
              });
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });
}
