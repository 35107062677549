<div>
  <div fxLayout="column" fxFill class="p-3">
    <h1 class="fw-500 text-center">Industrial For Sale</h1>
    <p class="text-center" style="color: red">Fields marked with a * are required</p>
  </div>
  <form [formGroup]="featuresForm" fxLayout="row wrap">
    <div fxFlex="80" fxFlex.gt-xs="80" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Listing Price</mat-label>
        <input type="text" currencyMask matInput placeholder="Enter absolute price" required formControlName="price" autocomplete="off" />
        <mat-error *ngIf="featuresForm.controls.price.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="20" fxFlex.gt-xs="20" fxLayoutAlign="center center" class="px-2" style="height: 50px">
      <mat-checkbox formControlName="poa" (change)="poaPriceEvent($event.checked)"> POA </mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Extent of Yard</mat-label>
        <input type="number" matInput placeholder="Extent of Yard" formControlName="extentOfYardComponent" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.extentOfYardComponent?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="140px" class="mb-4 pl-2" fxLayoutAlign="start center">
      <mat-checkbox formControlName="Phase3Power" (change)="onPowerChange($event)">3 Phase Power</mat-checkbox>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select Power Type</mat-label>
        <mat-select placeholder="Select Power Type" formControlName="powerType">
          <mat-option *ngFor="let option of powerOptions" [value]="option.value">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>GBA Extent</mat-label>
        <input matTooltip="GBA, or Gross Building Area, is a unit of measurement unit used in commercial property valuations." type="number" matInput placeholder="GBA Extent" formControlName="gbaExtent" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.gbaExtent?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Height to eaves</mat-label>
        <input matInput placeholder="Height of eaves" formControlName="heightToEaves" required />
        <mat-error *ngIf="featuresForm?.controls?.heightToEaves?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100px" class="mb-4 pr-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="heightToEavesVariable" (change)="onHeightToEavesChange($event)">Variable </mat-checkbox>
    </div>

    <div fxFlex="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox matTooltip="Property can accommodate heavy duty vehicles and machinery" formControlName="superlinkAccess">Interlink/ Superlink Access</mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>ERF Extent</mat-label>
        <input matTooltip="The extent of land registered in a deeds registry as an erf, lot, plot or stand." type="number" matInput placeholder="ERF Extent" formControlName="erfExtent" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.erfExtent?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>GLA Extent</mat-label>
        <input
          matTooltip="Gross leasable area, or GLA, is the area in a commercial property designed for the exclusive use of a tenant."
          type="number"
          matInput
          placeholder="GLA Extent"
          formControlName="extentGrossLettableArea"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="featuresForm?.controls?.extentGrossLettableArea?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Extent of office components</mat-label>
        <input type="number" matInput placeholder="Extent of office components" formControlName="extentOfOfficeComponent" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Dock levellers</mat-label>
        <input
          matTooltip="A dock leveller is an equipment used to level up the difference in height between a warehouse floor and delivery vehicle."
          type="number"
          matInput
          placeholder="Dock levellers"
          formControlName="dockLevellers"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>En grade roller shutter doors</mat-label>
        <input type="number" matInput placeholder="En grade roller shutter doors" formControlName="enGradeRollerShutterDoors" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2" fxLayoutAlign="center space-between">
      <mat-radio-group formControlName="freeholdVsSectionalTitle">
        <mat-radio-button matTooltip="Freehold, or 'full title', is where you purchase full ownership rights to a property, including the building and the land upon which it's built." fxFlex="50" [checked]="true" value="Freehold">
          Freehold
        </mat-radio-button>

        <mat-radio-button matTooltip="Sectional title simply describes the separate ownership of a unit within a complex or development" fxFlex="50" value="Sectional Title"> Sectional Title </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Security Features</mat-label>
        <textarea matInput placeholder="Security Features" formControlName="securityFeatures" rows="7"></textarea>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Floor load capacity</mat-label>
        <input
          matTooltip="The floor load capacity is the maximum weight a floor is engineered to support over a given area."
          type="number"
          matInput
          placeholder="Floor load capacity in kg/m²"
          formControlName="floorLoadCapacity"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2" fxLayoutAlign="center space-between">
      <mat-radio-group formControlName="vacantVsLet">
        <mat-radio-button matTooltip="Vacant land is land that has no buildings on it and is not being used." fxFlex="50" [checked]="true" value="Vacant"> Vacant </mat-radio-button>

        <mat-radio-button matTooltip="Let/ Investment land is land that is being used to generate income." fxFlex="50" value="Let (Investment)"> Let (Investment) </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Rates</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="rates" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Levies</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="levies" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Undercover Parkings</mat-label>
        <input type="number" [min]="0" matInput placeholder="Undercover Parkings" formControlName="undercoverParkings" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.undercoverParkings?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Open Parkings</mat-label>
        <input type="number" [min]="0" matInput placeholder="Open Parkings" formControlName="openParkings" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.openParkings?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Zoning</mat-label>
        <mat-select placeholder="Select Zoning" formControlName="zoning" required>
          <mat-option *ngFor="let option of ['Industrial', 'Commercial', 'Mixed Use', 'Retail']" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="step-section pb-2">
      <p class="mb-0"><span class="uppercase fw-500">Property Documents</span><span class="text-muted mx-3">(max 8 files, optional)</span></p>
      <input-file (deletedFile)="deleteDocumentEvent($event)" formControlName="documents" fileLimit="8" sizeLimit="2" fileAccept="*"> </input-file>
    </div>
  </form>
</div>
