<div fxLayout="row" fxLayoutAlign="center center">
  <div class="logo" *ngIf="theme === 1">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 2700.000000 1253.000000" attr.height="{{ height }}" preserveAspectRatio="xMidYMid meet">
      <style>
        .cls-3 {
          fill: #dcdcdc;
        }

        .cls-3 {
          fill: #ffffff;
        }

        .cls-4 {
          fill: #e24217;
        }
      </style>
      <metadata>CAPX2:: Leading Propery Management Technology</metadata>
      <title>CAPX2:: Leading Propery Management Technology</title>
      <g transform="translate(0.000000,1253.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <!-- ? C -->
        <path
          class="cls-3"
          d="M2985 9474 c-16 -2 -73 -9 -125 -15 -911 -102 -1396 -607 -1574 -1639 -67 -384 -87 -663 -93 -1283 -14 -1376 111 -2097 443 -2563 238 -336 587 -515 1151 -591 189 -26 798 -26 968 0 430 65 655 156 909 366 232 192 420 512 511 866 65 254 85 463 85 903 l0 312 -748 0 -749 0 -6 -277 c-19 -789 -94 -1060 -316 -1144 -47 -17 -73 -20 -161 -17 -95 3 -112 6 -172 36 -36 18 -83 51 -103 74 -78 85 -138 268 -169 508 -37 287 -41 429 -41 1400 0 971 4 1113 41 1400 31 240 91 423 169 508 21 23 67 56 103 74 64 32 71 33 187 33 111 0 124 -2 177 -28 95 -46 159 -135 193 -268 36 -134 46 -253 52 -576 l6 -323 748 0 749 0 0 254 c0 382 -19 575 -82 821 -36 141 -73 241 -128 350 -198 387 -540 641 -1015 755 -225 54 -303 62 -655 65 -179 2 -338 1 -355 -1z"
        />
        <!-- ? A -->
        <path
          class="cls-3"
          d="M6775 9348 c-3 -7 -320 -1329 -705 -2938 -385 -1609 -703 -2933 -706 -2942 -5 -17 38 -18 809 -18 l814 0 21 133 c11 72 47 304 79 515 l58 382 607 0 607 0 6 -42 c4 -24 39 -254 78 -513 l72 -470 827 -3 826 -2 -8 32 c-18 65 -1430 5865 -1430 5872 0 3 -439 6 -975 6 -772 0 -977 -3 -980 -12z m1193 -2473 l208 -1230 -420 -3 c-230 -1 -421 0 -423 2 -5 5 404 2447 415 2481 l6 20 4 -20 c2 -11 97 -573 210 -1250z"
        />
        <!-- ? P -->
        <path
          class="cls-3"
          d="M10350 6405 l0 -2955 775 0 775 0 0 1124 0 1124 448 5 c479 4 533 9 767 64 703 165 1116 610 1229 1323 80 501 15 1056 -167 1430 -200 410 -584 703 -1049 800 -188 39 -234 40 -1520 40 l-1258 0 0 -2955z m2008 1820 c195 -47 330 -187 386 -400 28 -108 40 -282 27 -412 -26 -278 -117 -445 -289 -532 -95 -48 -166 -60 -384 -68 l-198 -6 0 723 0 723 198 -6 c119 -4 222 -13 260 -22z"
        />
        <!-- ? X -->
        <path
          class="cls-4"
          d="M14471 9358 c7 -13 386 -646 842 -1408 456 -762 830 -1389 830 -1395 0 -5 -395 -703 -878 -1550 -482 -847 -880 -1546 -882 -1553 -4 -9 119 -12 596 -12 l600 0 607 1013 c334 556 608 1013 609 1015 1 1 276 -454 611 -1013 l609 -1015 597 0 c346 0 598 4 598 9 0 5 -392 697 -871 1537 -479 841 -876 1539 -881 1550 -9 19 103 211 827 1419 460 769 839 1404 842 1411 4 12 -101 14 -683 12 l-689 -3 -472 -829 c-259 -456 -476 -833 -481 -838 -5 -5 -207 340 -487 832 l-479 840 -688 0 -689 0 12 -22z m2650 -2261 c166 -292 302 -536 302 -542 0 -6 -140 -246 -313 -533 l-313 -523 -313 523 c-173 288 -314 527 -315 531 -1 5 140 256 312 558 270 475 315 548 326 533 7 -9 148 -256 314 -547z"
        />
        <!-- ? 2 -->
        <path
          class="cls-4"
          d="M20260 6419 l0 -4200 21 6 c12 4 875 242 1918 530 l1896 523 0 3143 0 3144 -1910 527 c-1050 290 -1913 527 -1917 528 -5 0 -8 -1890 -8 -4201z m2134 2785 c280 -72 486 -353 586 -802 86 -389 76 -990 -26 -1476 -32 -154 -3 -54 -375 -1300 -176 -588 -318 -1071 -316 -1072 2 -2 176 20 388 49 l384 53 3 -440 c1 -241 0 -441 -2 -444 -3 -2 -308 -67 -678 -143 -370 -76 -685 -141 -700 -145 l-28 -6 0 471 0 470 396 1303 c218 717 407 1350 420 1408 47 204 67 403 68 670 1 262 -6 312 -57 403 -53 94 -162 102 -216 14 -43 -70 -53 -138 -58 -395 l-5 -244 -47 6 c-44 6 -513 46 -534 46 -16 0 -1 604 18 740 61 447 248 753 510 836 56 18 196 17 269 -2z"
        />
        <!-- ? circle -->
        <path
          class="cls-3"
          d="M25242 10475 c-171 -41 -341 -176 -419 -332 -58 -114 -77 -208 -70 -342 8 -178 65 -308 187 -431 123 -122 253 -179 431 -187 202 -10 360 50 500 187 127 126 189 278 189 465 0 125 -19 206 -70 305 -88 167 -246 293 -422 335 -77 18 -253 18 -326 0z m336 -101 c185 -61 328 -212 377 -400 19 -75 19 -204 0 -278 -51 -197 -222 -365 -416 -411 -197 -46 -385 8 -530 154 -115 116 -169 240 -169 395 0 251 162 468 410 547 87 28 232 25 328 -7z"
        />
        <!-- ? T -->
        <path class="cls-3" d="M24930 10050 c0 -18 7 -20 85 -20 l85 0 0 -225 0 -225 30 0 30 0 0 225 0 225 85 0 c78 0 85 2 85 20 0 19 -7 20 -200 20 -193 0 -200 -1 -200 -20z" />
        <!-- ? M -->
        <path
          class="cls-3"
          d="M25360 9826 l0 -246 30 0 30 0 2 195 3 195 74 -195 c72 -189 75 -195 102 -195 27 0 30 7 108 203 l80 202 1 -202 0 -203 25 0 25 0 0 245 0 245 -38 0 -37 0 -80 -205 c-52 -133 -83 -201 -88 -193 -5 7 -41 99 -81 203 l-71 190 -43 3 -42 3 0 -245z"
        />
      </g>
    </svg>
  </div>

  <div class="logo" *ngIf="theme === 2">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 2700.000000 1253.000000" attr.height="{{ height }}" preserveAspectRatio="xMidYMid meet">
      <style>
        .cls-1 {
          fill: #284151;
        }

        .cls-2 {
          fill: #e24217;
        }
      </style>
      <metadata>CAPX2:: Leading Propery Management Technology</metadata>
      <title>CAPX2:: Leading Propery Management Technology</title>
      <g transform="translate(0.000000,1253.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <!-- ? C -->
        <path
          class="cls-1"
          d="M2985 9474 c-16 -2 -73 -9 -125 -15 -911 -102 -1396 -607 -1574 -1639 -67 -384 -87 -663 -93 -1283 -14 -1376 111 -2097 443 -2563 238 -336 587 -515 1151 -591 189 -26 798 -26 968 0 430 65 655 156 909 366 232 192 420 512 511 866 65 254 85 463 85 903 l0 312 -748 0 -749 0 -6 -277 c-19 -789 -94 -1060 -316 -1144 -47 -17 -73 -20 -161 -17 -95 3 -112 6 -172 36 -36 18 -83 51 -103 74 -78 85 -138 268 -169 508 -37 287 -41 429 -41 1400 0 971 4 1113 41 1400 31 240 91 423 169 508 21 23 67 56 103 74 64 32 71 33 187 33 111 0 124 -2 177 -28 95 -46 159 -135 193 -268 36 -134 46 -253 52 -576 l6 -323 748 0 749 0 0 254 c0 382 -19 575 -82 821 -36 141 -73 241 -128 350 -198 387 -540 641 -1015 755 -225 54 -303 62 -655 65 -179 2 -338 1 -355 -1z"
        />
        <!-- ? A -->
        <path
          class="cls-1"
          d="M6775 9348 c-3 -7 -320 -1329 -705 -2938 -385 -1609 -703 -2933 -706 -2942 -5 -17 38 -18 809 -18 l814 0 21 133 c11 72 47 304 79 515 l58 382 607 0 607 0 6 -42 c4 -24 39 -254 78 -513 l72 -470 827 -3 826 -2 -8 32 c-18 65 -1430 5865 -1430 5872 0 3 -439 6 -975 6 -772 0 -977 -3 -980 -12z m1193 -2473 l208 -1230 -420 -3 c-230 -1 -421 0 -423 2 -5 5 404 2447 415 2481 l6 20 4 -20 c2 -11 97 -573 210 -1250z"
        />
        <!-- ? P -->
        <path
          class="cls-1"
          d="M10350 6405 l0 -2955 775 0 775 0 0 1124 0 1124 448 5 c479 4 533 9 767 64 703 165 1116 610 1229 1323 80 501 15 1056 -167 1430 -200 410 -584 703 -1049 800 -188 39 -234 40 -1520 40 l-1258 0 0 -2955z m2008 1820 c195 -47 330 -187 386 -400 28 -108 40 -282 27 -412 -26 -278 -117 -445 -289 -532 -95 -48 -166 -60 -384 -68 l-198 -6 0 723 0 723 198 -6 c119 -4 222 -13 260 -22z"
        />
        <!-- ? X -->
        <path
          class="cls-2"
          d="M14471 9358 c7 -13 386 -646 842 -1408 456 -762 830 -1389 830 -1395 0 -5 -395 -703 -878 -1550 -482 -847 -880 -1546 -882 -1553 -4 -9 119 -12 596 -12 l600 0 607 1013 c334 556 608 1013 609 1015 1 1 276 -454 611 -1013 l609 -1015 597 0 c346 0 598 4 598 9 0 5 -392 697 -871 1537 -479 841 -876 1539 -881 1550 -9 19 103 211 827 1419 460 769 839 1404 842 1411 4 12 -101 14 -683 12 l-689 -3 -472 -829 c-259 -456 -476 -833 -481 -838 -5 -5 -207 340 -487 832 l-479 840 -688 0 -689 0 12 -22z m2650 -2261 c166 -292 302 -536 302 -542 0 -6 -140 -246 -313 -533 l-313 -523 -313 523 c-173 288 -314 527 -315 531 -1 5 140 256 312 558 270 475 315 548 326 533 7 -9 148 -256 314 -547z"
        />
        <!-- ? 2 -->
        <path
          class="cls-2"
          d="M20260 6419 l0 -4200 21 6 c12 4 875 242 1918 530 l1896 523 0 3143 0 3144 -1910 527 c-1050 290 -1913 527 -1917 528 -5 0 -8 -1890 -8 -4201z m2134 2785 c280 -72 486 -353 586 -802 86 -389 76 -990 -26 -1476 -32 -154 -3 -54 -375 -1300 -176 -588 -318 -1071 -316 -1072 2 -2 176 20 388 49 l384 53 3 -440 c1 -241 0 -441 -2 -444 -3 -2 -308 -67 -678 -143 -370 -76 -685 -141 -700 -145 l-28 -6 0 471 0 470 396 1303 c218 717 407 1350 420 1408 47 204 67 403 68 670 1 262 -6 312 -57 403 -53 94 -162 102 -216 14 -43 -70 -53 -138 -58 -395 l-5 -244 -47 6 c-44 6 -513 46 -534 46 -16 0 -1 604 18 740 61 447 248 753 510 836 56 18 196 17 269 -2z"
        />
        <!-- ? circle -->
        <path
          class="cls-1"
          d="M25242 10475 c-171 -41 -341 -176 -419 -332 -58 -114 -77 -208 -70 -342 8 -178 65 -308 187 -431 123 -122 253 -179 431 -187 202 -10 360 50 500 187 127 126 189 278 189 465 0 125 -19 206 -70 305 -88 167 -246 293 -422 335 -77 18 -253 18 -326 0z m336 -101 c185 -61 328 -212 377 -400 19 -75 19 -204 0 -278 -51 -197 -222 -365 -416 -411 -197 -46 -385 8 -530 154 -115 116 -169 240 -169 395 0 251 162 468 410 547 87 28 232 25 328 -7z"
        />
        <!-- ? T -->
        <path class="cls-1" d="M24930 10050 c0 -18 7 -20 85 -20 l85 0 0 -225 0 -225 30 0 30 0 0 225 0 225 85 0 c78 0 85 2 85 20 0 19 -7 20 -200 20 -193 0 -200 -1 -200 -20z" />
        <!-- ? M -->
        <path
          class="cls-1"
          d="M25360 9826 l0 -246 30 0 30 0 2 195 3 195 74 -195 c72 -189 75 -195 102 -195 27 0 30 7 108 203 l80 202 1 -202 0 -203 25 0 25 0 0 245 0 245 -38 0 -37 0 -80 -205 c-52 -133 -83 -201 -88 -193 -5 7 -41 99 -81 203 l-71 190 -43 3 -42 3 0 -245z"
        />
      </g>
    </svg>
  </div>
  <mat-chip-list *ngIf="show">
    <mat-chip> <mat-icon class="p-1">construction</mat-icon>DEV </mat-chip>
  </mat-chip-list>
</div>
