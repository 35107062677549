import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-industrial-on-auction',
  templateUrl: './industrial-on-auction.component.html',
  styleUrls: ['./industrial-on-auction.component.scss'],
})
export class IndustrialOnAuctionComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  powerOptions = [
    { name: 'KVA', value: '1' },
    { name: 'AMPS', value: '2' },
  ];

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      auctionDate: [null, Validators.required],
      price: ['', Validators.required],
      poa: [null],
      extentOfYardComponent: [null, Validators.required],
      Phase3Power: [true],
      powerType: [{ disabled: true }],
      heightToEaves: ['', Validators.required],
      heightToEavesVariable: [false],
      superlinkAccess: [false],
      gbaExtent: [null, Validators.required],
      erfExtent: [null, Validators.required],
      extentGrossLettableArea: [null, Validators.required],
      extentOfOfficeComponent: [null],
      dockLevellers: [null],
      enGradeRollerShutterDoors: [null],
      freeholdVsSectionalTitle: ['Freehold'],
      securityFeatures: [null],
      floorLoadCapacity: [null],
      vacantVsLet: ['Vacant'],
      rates: [''],
      levies: [''],
      undercoverParkings: [null],
      openParkings: [null],
      zoning: ['Mixed Use'],
      documents: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });

    this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });

    this.subscriptions.push(formSubscription);
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  onPowerChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.powerType.enable();
      return;
    }
    this.featuresForm.controls.powerType.disable();
  }

  onHeightToEavesChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.heightToEaves.setValue('Variable');
    } else {
      this.featuresForm.controls.heightToEaves.setValue('');
    }
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
