<div class="header-image-wrapper" [dir]="settings.rtl ? 'rtl' : 'ltr'" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-' + settings.toolbar">
  <div class="bg bg-accent"></div>
  <div class="mask"></div>

  <div
    class="header-image-content theme-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-sm="center center"
    [class.offset-bottom]="contentOffsetToTop"
    [class.home-page]="isHomePage"
    [style.min-height.px]="contentMinHeight"
  >
    <div fxHide.lt-md>
      <img src="./../../../assets/images/hero2.jpg" style="width: 100vw; max-width: 1200px; padding-top: 72px" />
    </div>

    <div fxHide.gt-sm>
      <img src="./../../../assets/images/mobile.jpg" style="width: 100vw; max-width: 1200px; padding-top: 36px" />
    </div>

    <h1 *ngIf="title" class="title">{{ title }}</h1>
    <p *ngIf="desc" class="desc capitalize">{{ desc }}</p>
    <div *ngIf="isHomePage" ngClass.gt-xs="mt-4" ngClass.xs="m-3">
      <a mat-raised-button routerLink="/about">ABOUT US</a>
      <a mat-raised-button routerLink="/contact">CONTACT US</a>
    </div>
  </div>
</div>
