import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-residential-to-let',
  templateUrl: './residential-to-let.component.html',
  styleUrls: ['./residential-to-let.component.scss'],
})
export class ResidentialToLetComponent implements OnInit, OnDestroy {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      price: ['', Validators.required],
      poa: [null],
      rentalPerM2: ['', Validators.required],
      rentalPerM2Poa: [null],
      size: [null, Validators.required],
      bedrooms: [null, Validators.required],
      bathrooms: [null, Validators.required],
      totalParkings: [null, Validators.required],
      livingRooms: [null, Validators.required],
      petFriendly: [false, Validators.required],
      garden: [false, Validators.required],
      pool: [false, Validators.required],
      residentialBuildingType: ['3'],
      secureEstate: [false],
      propertyType: ['1'],
      rates: [''],
      levies: [''],
      electricity: [false],
      water: [false],
      wifi: [false],
      dstv: [false],
      domesticWorker: [false],
      securityService: [false],
      gardenService: [false],
      documents: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);

    this.featuresForm.controls.price.valueChanges.subscribe((newValue) => {
      if (newValue == 0) {
        this.featuresForm.controls.poa.setValue(true);
        return;
      }
      this.featuresForm.controls.poa.setValue(false);
    });

    const formSubscription = this.featuresForm.valueChanges.subscribe(() => {
      this.onSubmit();
    });
    this.subscriptions.push(formSubscription);
  }

  poaPriceEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.price.setValue(0);
    } else {
      this.featuresForm.controls.price.setValue(null);
    }
  }

  poaPriceHaEvent(checked: boolean): void {
    if (checked) {
      this.featuresForm.controls.rentalPerM2.setValue(0);
    } else {
      this.featuresForm.controls.rentalPerM2.setValue(null);
    }
  }

  onSubmit(): any {
    this.featuresFormSubmitEvent.emit(this.featuresForm);
  }
}
