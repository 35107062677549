<div class="map-wrapper" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-' + settings.toolbar">
  <!-- <agm-map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [scrollwheel]="false"
    [mapTypeControl]="false"
    [fullscreenControl]="true"
    [streetViewControl]="true"
    [styles]="settings.theme === 'orange-dark' ? mapStyles : null"
    (mapReady)="onMapReady($event)"
    (mapClick)="onMapClick($event)"
    (zoomChange)="onZoomChange($event)"
  >
    <agm-marker *ngFor="let location of locations; let i = index" [latitude]="location.lat" [longitude]="location.lng" [animation]="markerAnimation" (markerClick)="onMarkerClick($event, location.propertyId)">
      <agm-snazzy-info-window [closeWhenOthersOpen]="true" wrapperClass="map-info-window">
        <ng-template>
          <a *ngIf="property" [routerLink]="['/properties', property.id]" class="text-center">
            <img [src]="property.gallery[0].small" alt="Capx2 Header Map" class="d-block w-100" />
            <p class="my-1">{{ property.title }}</p>
            <p *ngIf="settings.currency === 'USD'" class="fw-500 my-1">
              <span *ngIf="property.priceDollar.sale">{{ property.priceDollar.sale | currency: 'USD':'symbol':'1.0' }}</span>
              <span *ngIf="property.priceDollar.rent">{{ property.priceDollar.rent | currency: 'USD':'symbol':'1.0' }} /month</span>
            </p>
            <p *ngIf="settings.currency === 'EUR'" class="fw-500 my-1">
              <span *ngIf="property.priceEuro.sale">{{ property.priceEuro.sale | currency: 'EUR':'symbol':'1.0' }}</span>
              <span *ngIf="property.priceEuro.rent">{{ property.priceEuro.rent | currency: 'EUR':'symbol':'1.0' }} /month</span>
            </p>
          </a>
          <div *ngIf="!property" class="p-3"></div>
        </ng-template>
      </agm-snazzy-info-window>
    </agm-marker>
  </agm-map> -->
</div>
