import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, Settings } from '@app/core/app.settings';
import { AppService } from '@app/core/services/app.service';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { AuthState } from '@app/core/stores/auth/auth.model';
import { Endpoints } from '@app/core/utils/endpoints.dev';
import { Subscription } from 'rxjs';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { v4 as uuidv4 } from 'uuid';
import { HelperUtils } from '../../core/services/helper-utils.service';
import { WebService } from '../../core/services/web.service';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'],
})
export class PropertyItemComponent extends WebService<any> implements OnInit, OnDestroy, OnChanges {
  @Input() property: any;
  @Input() viewType = 'grid';
  @Input() viewColChanged = false;
  @Input() fullWidthPage = true;
  @Input() features: Array<any> = [];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  public column = 4;
  public featuresList = [];
  public isLoggedIn = false;
  public config: SwiperOptions = {};
  public settings: Settings;
  private subscriptions: Subscription[] = [];
  public isFavourite = false;
  private authState: AuthState;

  constructor(public appSettings: AppSettings, public appService: AppService, private router: Router, private helperUtils: HelperUtils, private authStore: AuthComponentStore, public clipboard: Clipboard) {
    super(null);
    this.settings = this.appSettings.settings;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.property.currentValue) {
      return;
    }
    if (this.property.classifications?.length > 0) {
      this.property.classifications = [this.property.classifications[0]];
    }

    if (this.property?.attributes?.length > 0) {
      // this.property.attributes = this.property.attributes.filter((x: any) => x.attribute[0].show_on_horizontal)
      this.property.attributes = this.property.attributes.sort((a: { order: number }, b: { order: number }) => a.order - b.order);
      this.property.attributes = this.property.attributes.filter((x: { value: any }) => x.value && x.value !== '0.0');
      this.property.attributes = this.property.attributes.slice(0, 5);
    }

    if (this.property?.addresses?.length === 0) {
      this.property.addresses[0] = { address2: 'No Location Avaliable' };
    }

    // ! TODO FIX
    this.property.images = this.property?.images?.slice(0, 1);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.property.reference);
    this.helperUtils.displaySuccessToast('Reference number copied to clipboard!');
  }

  ngOnInit(): void {
    this.initCarousel();

    const authStoreSubscription = this.authStore.state$.subscribe((state) => {
      this.authState = state;
      if (!state.loading && state.favourites) {
        state?.favourites?.forEach((element: { property: { reference: string } }) => {
          if (element?.property?.reference === this.property?.reference) {
            this.isFavourite = true;
          }
        });
      }
    });

    this.subscriptions.push(authStoreSubscription);
  }

  public buildImageUrl(url: string): string {
    try {
      let parts = url.split('.');
      return Endpoints.IMAGES_BASE_URL + parts[0] + '-crop-c0-5__0-5-375x298-70.' + parts[1];
    } catch {
      return '';
    }
  }

  propertyClick(): void {
    this.router.navigate(['/properties/view/', this.helperUtils.getIDFromUrl(this.property.id)]);
  }

  public getColumnCount(value: number): void {
    if (value === 25) {
      this.column = 4;
    } else if (value === 33.3) {
      this.column = 3;
    } else if (value === 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }

  public carouselID = uuidv4();

  public initCarousel(): any {
    this.config = {
      navigation: {
        nextEl: `.prop-item-next-${this.carouselID}`,
        prevEl: `.prop-item-prev-${this.carouselID}`,
      },
      autoHeight: true,
      pagination: {
        el: `.swiper-pagination-${this.carouselID}`,
        clickable: true,
      },
      grabCursor: true,
      speed: 500,
      preloadImages: true,
      lazy: true,
    };
  }

  public addToCompare(): any {}

  public onCompare(): any {}

  public removeFavourite(): any {
    this.authState.favourites.forEach((element) => {
      if (element.property.reference === this.property.reference) {
        this.authStore.removeFavourite({
          id: this.authState.profile.id,
          property: this.helperUtils.getIDFromUrl(this.property.id),
        });
        this.isFavourite = false;
      }
    });
  }

  public onImageError(event, image: any): any {
    if (this.property?.images?.length > 1) {
      this.property.images = this.property.images.filter((x: any) => x !== image);
      return;
    }
    event.target.src = Endpoints.IMAGE_NOT_FOUND;
    // event.target.src =
    //     'https://capx2storagesan.blob.core.windows.net/media-dev/__sized__/images/1/0bde76fa-9bd9-4d7f-94a5-446312229838-crop-c0-5__0-5-322x213.png';
  }

  public addToFavorites(): any {
    if (this.authState.isLoggedIn) {
      this.isFavourite = true;
      this.authStore.addFavourite({
        id: this.authState.profile.id,
        property: this.helperUtils.getIDFromUrl(this.property.id),
      });
    } else {
      this.helperUtils.displayErrorMessageToast('You need to be logged in to add a favourite property');
    }
  }

  public onFavorites(): any {}
}
