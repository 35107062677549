import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-header-controls',
  templateUrl: './dialog-header-controls.component.html',
  styleUrls: ['./dialog-header-controls.component.scss'],
})
export class DialogHeaderControlsComponent {
  @Input() dialogRef: any;
  @Input() showFullscreenIcon = true;
  public isFullScreen = false;
  constructor() {}

  public toggleFullScreen(): any {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      this.dialogRef.addPanelClass('fullscreen');
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    } else {
      this.dialogRef.removePanelClass('fullscreen');
      document.getElementsByTagName('html')[0].style.overflowY = null;
    }
  }

  public close(): any {
    this.dialogRef.close();
    document.getElementsByTagName('html')[0].style.overflowY = null;
  }
}
