import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { Observable, tap } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isBrowser = false;

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService, private helperUtils: HelperUtils, @Inject(PLATFORM_ID) plat: any) {
    if (isPlatformBrowser(plat)) {
      this.isBrowser = true;
    }
  }

  public addRequestToLog(url: any, user_data: any, request_data: any, type: any, method: any) {
    this.httpClient
      .post('https://capx2-logger.bossbyz.dev/api/logs', {
        data: {
          url,
          user_data,
          request_data,
          type,
          method,
        },
      })
      .subscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBrowser) {
      // ADD AUTH FOR CAPX2 API REQUESTS
      if (!request.url.includes('api.capx2') || request.url.includes('capx2-logger.bossbyz.dev')) {
        return next.handle(request);
      }

      const token = JSON.parse(this.localStorageService.getItem('token')) ?? null;

      if (token?.token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token?.token,
          },
        });
        this.addRequestToLog(request?.url + '' ?? 'Unknown', JSON.stringify(token), JSON.stringify(request.body), 'request', request.method);
      } else {
        this.addRequestToLog(request?.url + '' ?? 'Unknown', 'Not logged in', JSON.stringify(request.body), 'request', request.method);
      }

      return next.handle(request).pipe(
        tap({
          next: (value: any) => {
            this.addRequestToLog(value.url ?? 'Unknown', JSON.stringify(token) ?? 'Not logged in', JSON.stringify(value.body), 'response', request?.method ?? 'Unknown');
          },
          error: (err: HttpErrorResponse) => {
            this.addRequestToLog(err?.url ?? 'Unknown', JSON.stringify(token) ?? 'Not logged in', JSON.stringify(err), 'response', request?.method ?? 'Unknown');
            if (err.status >= 400 && !request.url.includes('capx2-logger.bossbyz.dev')) {
              this.helperUtils.displayErrorResponseToast(err);
            }
          },
        }),
      );
    } else {
      // SSR, DO NOT ADD AUTH
      return next.handle(request);
    }
  }
}
