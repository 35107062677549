<div>
  <div fxLayout="column" fxFill class="p-3">
    <h1 class="fw-500 text-center">Agricultural To Let</h1>
    <p class="text-center" style="color: red">Fields marked with a * are required</p>
  </div>
  <form [formGroup]="featuresForm" fxLayout="row wrap" (ngSubmit)="onSubmit()">
    <div fxFlex="100">
      <div fxFlex class="px-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Listing Price</mat-label>
          <input type="text" currencyMask matInput placeholder="Enter price per month" required formControlName="price" autocomplete="off" />
          <mat-error *ngIf="featuresForm.controls.price.errors?.required">Field is required </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="100px" fxLayoutAlign="end center" class="px-2" style="height: 50px">
        <mat-checkbox formControlName="poa" (change)="poaPriceEvent($event.checked)"> POA </mat-checkbox>
      </div>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Rental per ha</mat-label>
        <input type="text" currencyMask matInput placeholder="Rental per ha" formControlName="rentalPerHa" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.rentalPerHa?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100px" fxLayoutAlign="end center" class="px-2" style="height: 50px">
      <mat-checkbox formControlName="rentalPerHaPoa" (change)="poaPriceHaEvent($event.checked)"> POA </mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Extent in ha</mat-label>
        <input type="number" matInput placeholder="Extent in ha" formControlName="extent" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.extent?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Current Use</mat-label>
        <input matInput placeholder="Current Use" formControlName="currentUse" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.currentUse?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100">
      <div fxFlex class="px-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Koppen Geiger Climate Classification</mat-label>
          <mat-select placeholder="Select Koppen Geiger Climate Classification" formControlName="koppenGeigerClimateClassification" required>
            <mat-option *ngFor="let option of koppenGeigerClimateClassificationOptions" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="featuresForm?.controls?.koppenGeigerClimateClassification?.errors?.required">Field is required </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="150px" fxLayoutAlign="end center" style="height: 50px">
        <a href="http://stepsa.org/climate_koppen_geiger.html" target="_blank" mat-button color="primary" class="m-2"><i class="fa fa-link"></i> Reference</a>
      </div>
    </div>

    <div fxFlex="100px" fxLayoutAlign="start center" class="px-2" style="height: 50px">
      <mat-checkbox formControlName="hasDams" (change)="hasDamsChangeEvent($event.checked)"> Dams</mat-checkbox>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Number of dams</mat-label>
        <input type="number" matInput placeholder="Number of dams" formControlName="dams" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.dams?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Improvements</mat-label>
        <input matInput placeholder="Improvements" formControlName="improvements" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.improvements?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Average Rainfall</mat-label>
        <input type="number" matInput placeholder="Average Rainfall" formControlName="averageRainfall" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="waterRights">Water Rights</mat-checkbox>
    </div>

    <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="boreHoles">Boreholes</mat-checkbox>
    </div>

    <div fxFlex="34" fxFlex.lt-md="100" fxLayoutAlign="center center" class="pb-3">
      <mat-checkbox formControlName="riperianRights">Riperian Rights</mat-checkbox>
    </div>

    <div fxFlex="100" class="step-section pb-2">
      <p class="mb-0"><span class="uppercase fw-500">Property Documents</span><span class="text-muted mx-3">(max 8 files, optional)</span></p>
      <input-file (deletedFile)="deleteDocumentEvent($event)" formControlName="documents" fileLimit="8" sizeLimit="2" fileAccept="*"> </input-file>
    </div>
  </form>
</div>
