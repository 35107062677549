import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/../environments/environment';
import { PagedList } from '@app/core/models/paged-list.model';
import { Observable } from 'rxjs';
import { IService } from './web.service.interface';

@Injectable()
export abstract class WebService<T> implements IService<T> {
  protected httpClient: HttpClient;
  public headers: HttpHeaders;

  protected networkDelayRate = 0;

  constructor(http: HttpClient) {
    this.httpClient = http;
    this.networkDelayRate = environment.simulation.defaultDelayRate;

    this.headers = new HttpHeaders({
      // ? BREAKS FORMDATA REQUESTS - DO NOT INCLUDE
      // 'Content-Type': 'application/json',
    });
  }

  protected objectToQueryString(obj): any {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  create(item: T): Observable<T> {
    throw new Error('Method not implemented.');
  }
  remove(item: T): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  save(item: T): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getOne(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }
  getMany(request: any): Observable<PagedList<T>> {
    throw new Error('Method not implemented.');
  }

  protected buildHttpSearchParams(request: any): HttpParams {
    let params = new HttpParams().set('PageNumber', request.pageNumber || 0).set('PageSize', request.pageSize || 25);

    if (!(request.filter === null || request.filter === undefined)) {
      for (const x in request.filter) {
        params = params.set(x, request.filter[x]);
      }
    }

    return params;
  }

  protected ResolveQueryParams(obj): string {
    let result = '';
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        for (const subKey in obj[key]) {
          if (obj[key].hasOwnProperty(subKey)) {
            result = result.concat('' + subKey + '=' + obj[key][subKey] + '&');
          }
        }
      }
    }
    return result;
  }

  protected buildHttpRequestParamsForArray(request: any, arrayIdentifier?: string): string {
    let params = '';

    if (!(request === null || request === undefined)) {
      for (const x in request) {
        if (!(request[x] == undefined || request[x].length == 0)) {
          params += `${arrayIdentifier}=${request[x]}&`;
        }
      }
    }
    // remove the leading &/
    params = params.substr(0, params.length - 1);
    return params;
  }

  protected buildHttpRequestParams(request: any, arrayIdentifier?: string): string {
    let params = '';

    if (!(request === null || request === undefined) && Array.isArray(request)) {
      for (const x in request) {
        if (!(request[x] == undefined || request[x].length == 0)) {
          params = `${arrayIdentifier}${params}&${request[x]}`;
        }
      }
      // remove the leading /
      params = params.substr(1, params.length - 1);
      return params;
    }

    if (!(request === null || request === undefined)) {
      for (const x in request) {
        if (!(request[x] == undefined || request[x].length == 0)) {
          params = `${params}/${request[x]}`;
        }
      }
    }

    // remove the leading /
    params = params.substr(1, params.length - 1);
    return params;
  }
}
