import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  @Input() dividers = true;
  @Input() iconSize = 'sm';
  @Input() iconColor = '';
}
