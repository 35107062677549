import { Component, isDevMode, OnDestroy } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { AuthState } from '@app/core/stores/auth/auth.model';
import { ThemeStore } from '@app/core/stores/theme/theme.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnDestroy {
  public theme = false;
  private subscriptions: Subscription[] = [];
  public authState: AuthState = null;

  constructor(public appService: AppService, private authStore: AuthComponentStore, private themeStore: ThemeStore, private localStorageService: LocalStorageService) {
    // * ENABLE THEME CHANGING
    // if (this.localStorageService.getItem('theme') === 'capx2-dark') {
    //     this.theme = true;
    // }

    // * DISABLE THEME CHANGING
    this.theme = true;

    // if (isDevMode()) {
    //   this.theme = false;
    // }

    setTimeout(() => {
      this.changeTheme();
    }, 0);

    const authStoreSubscription = this.authStore.state$.subscribe((state) => {
      this.authState = state;
    });

    this.subscriptions.push(authStoreSubscription);
  }

  changeTheme(): void {
    this.theme = !this.theme;

    const themeName = this.theme ? (this.appService.appSettings.settings.theme = 'capx2-dark') : (this.appService.appSettings.settings.theme = 'capx2');

    this.themeStore.changeTheme(themeName);
    this.localStorageService.setItem('theme', this.appService.appSettings.settings.theme);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
