import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CurrencyComponent } from '@app/theme/components/currency/currency.component';
import { FooterComponent } from '@app/theme/components/footer/footer.component';
import { HeaderCarouselComponent } from '@app/theme/components/header-carousel/header-carousel.component';
import { HeaderImageComponent } from '@app/theme/components/header-image/header-image.component';
import { HeaderMapComponent } from '@app/theme/components/header-map/header-map.component';
import { HeaderVideoComponent } from '@app/theme/components/header-video/header-video.component';
import { LangComponent } from '@app/theme/components/lang/lang.component';
import { LogoComponent } from '@app/theme/components/logo/logo.component';
import { HorizontalMenuComponent } from '@app/theme/components/menu/horizontal-menu/horizontal-menu.component';
import { MenuService } from '@app/theme/components/menu/menu.service';
import { VerticalMenuComponent } from '@app/theme/components/menu/vertical-menu/vertical-menu.component';
import { SocialIconsComponent } from '@app/theme/components/social-icons/social-icons.component';
import { Toolbar1Component } from '@app/theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from '@app/theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from '@app/theme/components/user-menu/user-menu.component';
import { BaseLayoutComponent } from '@app/theme/layout/base-layout.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [
    BaseLayoutComponent,
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    HeaderMapComponent,
    HeaderVideoComponent,
    HeaderVideoComponent,
    CurrencyComponent,
    FooterComponent,
    LangComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    SocialIconsComponent,
    Toolbar1Component,
    Toolbar2Component,
    UserMenuComponent,
  ],
  declarations: [
    BaseLayoutComponent,
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    HeaderMapComponent,
    HeaderVideoComponent,
    HeaderVideoComponent,
    CurrencyComponent,
    FooterComponent,
    LangComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    SocialIconsComponent,
    Toolbar1Component,
    Toolbar2Component,
    UserMenuComponent,
  ],
  providers: [MenuService],
})
export class ThemeModule {}
