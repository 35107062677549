export interface Attribute {
  url: string;
  order: number;
  changed_date: string;
  published_date: null;
  created_date: string;
  slug: null;
  tags: null;
  name: string;
  description: null;
  migration_key: number;
  prop_data: number;
  show_on_home: boolean;
  show_on_search: boolean;
  show_on_detail: boolean;
  show_on_vertical: boolean;
  show_on_horizontal: boolean;
  tenant_directory: string;
  owner: string;
  changed_by: string;
  attribute_type: string;
}

export interface AttributeState {
  attributes: any[];
  action: AttributeAction;
}

export enum AttributeAction {
  INIT,
  ATTRIBUTE_CREATION_FAILED,
}
