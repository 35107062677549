import { AfterViewInit, Component } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-our-agents',
  templateUrl: './our-agents.component.html',
  styleUrls: ['./our-agents.component.scss'],
})
export class OurAgentsComponent implements AfterViewInit {
  public agents: any = [];
  public config: SwiperOptions = {};
  constructor(public appService: AppService) {}

  ngAfterViewInit(): void {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    };
  }
}
