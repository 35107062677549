<div class="px-3">
  <div class="theme-container">
    <div class="get-in-touch bg-primary" ngClass.lt-md="mt-3">
      <!-- <img src="assets/factory.png" alt="operator" fxShow="false" fxShow.gt-xs> -->

      <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" fxLayout.xs="column wrap">
        <div fxFlex="25" fxShow.gt-sm fxShow="false" fxLayoutAlign="center center">
          <mat-icon class="mat-icon-large"> factory</mat-icon>
        </div>

        <div fxFlex="25" fxFlex.lt-md="33" fxLayoutAlign="center center">
          <h2 class="capitalize uppercase text-center">LIST YOUR PROPERTY PRIVATELY</h2>
        </div>

        <div fxLayout="row" fxFlex.lt-md="33" fxFlex="25" fxLayoutAlign="center center">
          <mat-icon class="mat-icon-xlg mx-2">call</mat-icon>
          <div fxLayout="column">
            <p class="mb-0">CALL US NOW</p>
            <h2 class="ws-nowrap">086 144 4769</h2>
          </div>
        </div>

        <div fxFlex="25" fxFlex.lt-md="34" fxLayoutAlign="center center">
          <a mat-flat-button routerLink="/contact" class="uppercase m-2">get in touch</a>
        </div>
      </div>
    </div>
  </div>
</div>
