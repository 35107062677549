import { Component, OnInit } from '@angular/core';
import { AppSettings, Settings } from '@app/core/app.settings';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  public currencies = ['USD', 'EUR', 'ZAR'];
  public currency: any;
  public settings: Settings;
  constructor(public appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.currency = this.settings.currency;
  }

  public changeCurrency(currency: string): void {
    this.currency = currency;
    this.settings.currency = currency;
  }
}
