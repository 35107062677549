<div class="mt-4">
  <div class="theme-container">
    <div fxLayout="row wrap">
      <div fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center center" ngClass.gt-sm="pr-3" ngClass.lt-md="pb-4 px-3">
        <button (click)="buying()" mat-raised-button color="primary" class="capitalize w-100 wrap">Are you a Buyer / Tenant? Browse our listings</button>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center center" ngClass.gt-sm="pl-3" ngClass.lt-md="px-3">
        <button (click)="selling()" mat-raised-button color="primary" class="capitalize w-100 wrap">Are you a Seller / Landlord? Add Your listing</button>
      </div>
    </div>
  </div>
</div>
