import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Endpoints } from '@app/core/utils/endpoints.dev';
@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom) {}
  setCanonicalURL(url?: string): void {
    let canURL: string = url == undefined ? this.dom.URL : url;

    if (canURL.startsWith('/')) {
      canURL = Endpoints.WEBSITE_URL + canURL;
    }

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canURL);
    this.dom.head.appendChild(link);
  }
}
