import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@app/core/services/web.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { Observable } from 'rxjs';
import { Endpoints } from '../../utils/endpoints.dev';
import { Blog } from './blog.models';

@Injectable({ providedIn: 'root' })
export class BlogService extends WebService<Blog> {
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  create(item: Blog): Observable<Blog> {
    return this.httpClient.post<Blog>(Endpoints.BASE_URL + Endpoints.BLOGS, item, {
      headers: this.headers,
    });
  }

  save(item: any): Observable<any> {
    return this.httpClient.patch(Endpoints.BASE_URL + Endpoints.BLOGS + item.id + '/', item.data, {
      headers: this.headers,
    });
  }

  remove(item: any): Observable<any> {
    // ? SET STATUS TO DELETED
    item.status = 45;
    const post = { status: item.status };
    return this.httpClient.patch(Endpoints.BASE_URL + Endpoints.BLOGS + item.id + '/', post, { headers: this.headers });
    // return this.httpClient.delete<any>(item.url, { headers: this.headers });
  }

  getOne(id: string): Observable<Blog> {
    return this.httpClient.get<Blog>(Endpoints.BASE_URL + Endpoints.BLOGS + id + '/?expand=*', {
      headers: this.headers,
    });
  }

  getMany(request: any): Observable<PagedList<Blog>> {
    return this.httpClient.get<PagedList<Blog>>(request.url ?? Endpoints.BASE_URL + Endpoints.BLOGS + `?expand=*&limit=999&status=${request.status ?? ''}`, {
      headers: this.headers,
    });
  }
}
