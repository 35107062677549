<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1">086 144 4769</span>
</span>
<span *ngIf="dividers" class="v-divider"></span>
<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1">41 Richefond Circle, Umhlanga , Durban</span>
</span>
<span *ngIf="dividers" class="v-divider"></span>
<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span class="px-1">support@capx2.com</span>
</span>
