import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DigitalMarketingComponent } from '@app/components/what-we-offer-dialog/digital-marketing/digital-marketing.component';
import { HighQualityVettingComponent } from '@app/components/what-we-offer-dialog/high-quality-vetting/high-quality-vetting.component';
import { LargestPlatformComponent } from '@app/components/what-we-offer-dialog/largest-platform/largest-platform.component';
import { SpecializedPropertyPortalComponent } from '@app/components/what-we-offer-dialog/specialized-property-portal/specialized-property-portal.component';

@Component({
  selector: 'app-what-we-offer',
  templateUrl: './what-we-offer.component.html',
  styleUrls: ['./what-we-offer.component.scss'],
})
export class WhatWeOfferComponent {
  constructor(public dialog: MatDialog) {}

  openDialog(selectedCard: number): any {
    switch (selectedCard) {
      case 2:
        this.showDialog(HighQualityVettingComponent);
        break;
      case 3:
        this.showDialog(LargestPlatformComponent);
        break;
      case 4:
        this.showDialog(DigitalMarketingComponent);
        break;
      default:
        this.showDialog(SpecializedPropertyPortalComponent);
        break;
    }
  }

  showDialog(component: any): any {
    this.dialog.open(component, { maxWidth: '640px', width: '90%' });
  }
}
