<div class="mt-3 mb-3">
  <div class="theme-container">
    <mat-card>
      <swiper [config]="config">
        <ng-template swiperSlide *ngFor="let client of clients">
          <div class="client-item mx-5">
            <img [height]="client.height" [src]="client.image" />
          </div>
        </ng-template>
      </swiper>
    </mat-card>
  </div>
</div>
