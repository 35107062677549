<mat-toolbar id="top-toolbar" class="top-toolbar">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxHide="false" fxHide.gt-md>
        <button mat-button (click)="sidenavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
      <app-social-icons fxHide.lt-sm fxLayout="row"></app-social-icons>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <app-user-menu></app-user-menu>
    </div>
  </div>
</mat-toolbar>
<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="logo" routerLink="/">
      <app-logo [height]="70" [theme]="theme"></app-logo>
    </a>
    <div fxShow="false" fxShow.gt-md class="horizontal-menu">
      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <a routerLink="/submit-property" mat-mini-fab color="primary" fxHide="false" fxHide.gt-xs>
        <mat-icon>add</mat-icon>
      </a>
      <a routerLink="/submit-property" mat-raised-button color="primary" fxShow="false" fxShow.gt-xs class="uppercase">List My Property</a>
    </div>
  </div>
</mat-toolbar>
