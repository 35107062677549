<div class="testimonials">
  <div class="px-0">
    <h1 class="text-center uppercase mt-5">What people are saying</h1>
    <div class="header-underline"></div>
    <mat-card>
      <swiper class="h-100 m-3" style="display: grid; grid-template-rows: 1fr; grid-template-columns: repeat(9, 1fr)" #testimonialSwiper [config]="config">
        <ng-template swiperSlide *ngFor="let testimonial of testimonials">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="text-center">
              <div style="font-size: 18px">“{{ testimonial.text }}”</div>
            </div>
            <h3 class="author">{{ testimonial.author }}</h3>
            <p>{{ testimonial.position }}</p>
          </div>
        </ng-template>
      </swiper>
      <button mat-mini-fab color="primary" style="z-index: 1" class="testimonial-prev swiper-button-prev swipe-arrow">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button mat-mini-fab color="primary" style="z-index: 1" class="testimonial-next swiper-button-next swipe-arrow">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </mat-card>
  </div>
</div>
