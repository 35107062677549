import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent {
  public config: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 3,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    speed: 500,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 3,
      },
      960: {
        slidesPerView: 3,
      },
    },
  };

  public clients = [
    // {
    //     name: 'virtualAgent',
    //     image: 'assets/images/clients/virtualAgent.png',
    // },
    {
      name: 'zendesk',
      image: 'assets/images/clients/zendesk.png',
      height: 88,
    },
    // {
    //     name: 'in2assets',
    //     image: 'assets/images/clients/in2assets.png',
    // },
    { name: 'strauss', image: 'assets/images/clients/strauss.png', height: 77 },
    { name: 'experian', image: 'assets/images/clients/experian.png', height: 50 },
  ];
}
