<div class="mt-3">
  <div class="theme-container">
    <div fxLayout="row wrap" class="services-wrapper">
      <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <mat-icon class="mat-icon-xlg" color="primary">factory</mat-icon>
          <h3 class="capitalize fw-600 my-3 title">Specialised Commercial Property Portal</h3>
          <a (click)="openDialog(1)" class="primary-color link capitalize">read more</a>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <mat-icon class="mat-icon-xlg" color="primary">high_quality</mat-icon>
          <h3 class="capitalize fw-600 my-3 title">High Quality Lead generation and vetting</h3>
          <a (click)="openDialog(2)" class="primary-color link capitalize">read more</a>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <mat-icon class="mat-icon-xlg" color="primary">trending_up</mat-icon>
          <h3 class="capitalize fw-600 my-3 title">Largest commercial and Industrial platform in ZA</h3>
          <a (click)="openDialog(3)" class="primary-color link capitalize">read more</a>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
        <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">
          <mat-icon class="mat-icon-xlg" color="primary">keyboard_alt</mat-icon>
          <h3 class="capitalize fw-600 my-3 title">Digital Marketing at your fingertips</h3>
          <a (click)="openDialog(4)" class="primary-color link capitalize">read more</a>
        </mat-card>
      </div>
    </div>
  </div>
</div>
