import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private router: Router) {
    this.searchForm = this.fb.group({
      city: null,
      priceFrom: null,
      priceTo: null,
      listingType: '',
      propertyType: '',
      keyword: null,
      date: null,
      description: null,
      ordering: null,
      // * PAGINATION
      size: 6,
      page: 1,
    });
  }

  public buildSearchURL(): any {
    let city: string = this.searchForm.value.city ?? 'all-locations';
    city = city.replace(/ /g, '-');
    city = city.toLowerCase();
    if (city === '') {
      city = 'all-locations';
    }
    let listingType = this.searchForm.value.listingType ?? 'all-categories';
    let propertyType = this.searchForm.value.propertyType ?? 'all-properties';
    listingType = listingType == 2 ? 'to-let' : listingType == 3 ? 'for-sale' : listingType == 5 ? 'on-auction' : 'all-categories';
    propertyType = propertyType == 5 ? 'residential' : propertyType == 2 ? 'commercial' : propertyType == 4 ? 'agricultural' : propertyType == 3 ? 'industrial' : 'all-properties';

    const params = {};

    Object.assign(params, this.searchForm.value.priceFrom === null ? null : { priceFrom: this.searchForm.value.priceFrom });
    Object.assign(params, this.searchForm.value.priceTo === null ? null : { priceTo: this.searchForm.value.priceTo });
    Object.assign(params, this.searchForm.value.ordering === null ? null : { ordering: this.searchForm.value.ordering });
    Object.assign(params, this.searchForm.value.keyword === null ? null : { keyword: this.searchForm.value.keyword });
    Object.assign(params, this.searchForm.value.date === null ? null : { date: this.searchForm.value.date });
    Object.assign(params, this.searchForm.value.description === null ? null : { description: this.searchForm.value.description });
    Object.assign(params, this.searchForm.value.page === null ? null : { page: this.searchForm.value.page });

    if (this.searchForm.value.size > 36) {
      this.searchForm.patchValue({ size: 36 });
    }

    Object.assign(params, this.searchForm.value.size === null ? null : { size: this.searchForm.value.size });

    if (Object.entries(params).length === 0) {
      return {
        url: `/properties/${city}/${propertyType}/${listingType}`,
      };
    }
    return {
      url: `/properties/${city}/${propertyType}/${listingType}`,
      queryParams: params,
    };
  }

  public readSearchURL(params: object): any {
    let obj = Object.assign({}, params);
    for (const [key, value] of Object.entries(params)) {
      if (value === 'all' || value === 'any' || value === 'all-properties' || value === 'all-locations' || value === 'all-categories') {
        switch (key) {
          case 'city':
            obj = Object.assign({}, obj, { city: null });
            break;
          case 'listingType':
            obj = Object.assign({}, obj, { listingType: '' });
            break;
          case 'propertyType':
            obj = Object.assign({}, obj, { propertyType: '' });
            break;
          default:
            break;
        }
      } else {
        switch (key) {
          case 'city':
            let city: string = value.toString();
            city = city.replace('-', ' ');
            city = new TitleCasePipe().transform(city);
            obj = Object.assign({}, obj, { city });
            break;
          case 'listingType':
            let listingTypeID = value === 'for-sale' ? 3 : value === 'on-auction' ? 5 : value === 'to-let' ? 2 : '';
            obj = Object.assign({}, obj, {
              listingType: listingTypeID.toString(),
            });
            break;
          case 'propertyType':
            let propertyTypeID = value === 'residential' ? 5 : value === 'commercial' ? 2 : value === 'agricultural' ? 4 : value === 'industrial' ? 3 : '';
            obj = Object.assign({}, obj, {
              propertyType: propertyTypeID.toString(),
            });
            break;
          default:
            break;
        }
      }
    }
    return obj;
  }

  public resetSearchForm(): any {
    this.searchForm.patchValue({
      city: null,
      priceFrom: null,
      priceTo: null,
      listingType: '',
      propertyType: '',
      ordering: null,
      keyword: null,
      date: null,
      description: null,
    });
  }

  public search() {
    let route = this.buildSearchURL();
    this.router.navigate([route?.url], { queryParams: route?.queryParams });
  }
}
