import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  public featuresForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.featuresForm = this.fb.group({});
  }

  public setFeaturesForm(form: UntypedFormGroup): any {
    this.featuresForm = form;
  }

  public resetSearchForm(): any {
    this.featuresForm.reset();
  }
}
