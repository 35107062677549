import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSettings, Settings } from '@app/core/app.settings';

@Component({
  selector: 'app-header-video',
  templateUrl: './header-video.component.html',
  styleUrls: ['./header-video.component.scss'],
})
export class HeaderVideoComponent implements OnInit, OnDestroy {
  @Input() backgroundVideo: any;
  @Input() contentOffsetToTop: boolean;
  @Input() contentMinHeight: any;
  @Input() title: any;
  @Input() desc: any;
  @Input() isHomePage = false;
  @Input() fullscreen = false;
  public settings: Settings;

  constructor(public appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgVideo = true;
    });
  }

  ngOnInit(): void {
    if (this.contentOffsetToTop) {
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
    const vid = document.getElementById('bgVideo') as HTMLVideoElement;
    vid.muted = true;
  }

  ngOnDestroy(): any {
    setTimeout(() => {
      this.settings.headerBgVideo = false;
      this.settings.contentOffsetToTop = false;
    });
  }
}
