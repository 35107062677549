import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { SearchService } from '@app/pages/properties/properties-search/search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-properties-search-results-filters',
  templateUrl: './properties-search-results-filters.component.html',
  styleUrls: ['./properties-search-results-filters.component.scss'],
})
export class PropertiesSearchResultsFiltersComponent implements OnDestroy {
  @Output() removeSearchFieldEvent: EventEmitter<any> = new EventEmitter<any>();

  public searchFields: any;
  private subscriptions: Subscription[] = [];

  constructor(private searchService: SearchService) {
    this.searchFields = this.searchService.searchForm.value;
    const searchServiceSubscription = this.searchService.searchForm.valueChanges.subscribe(() => {
      this.searchFields = this.searchService.searchForm.value;
    });
    this.subscriptions.push(searchServiceSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public remove(field: any): void {
    this.removeSearchFieldEvent.emit(field);
  }
}
