export interface TokenRequest {
  username: string;
  password: string;
}
export interface RefreshTokenRequest {
  refresh: string;
}
export interface JwtTokenRequest {
  username: string;
  password: string;
}
export enum AuthAction {
  INIT,
  LOGIN,
  LOGIN_FAILED,
  REGISTER,
  REGISTER_FAILED,
  CREATE_PROFILE,
  CREATE_PROFILE_FAILED,
  CONTACT,
}
export interface AuthState {
  action: AuthAction;
  token: any;
  isLoggedIn: boolean;
  profile: any;
  isAdmin: boolean;
  createdUser: any;
  createdProfile: any;
  verifyLoggedIn: boolean;
  verifyToken: string;
  loading: boolean;
  containerLoaded: boolean;
  favourites: any;
}

export interface WishList {
  id: WishList;
  url: string;
  order: number;
  changed_date: string;
  published_date: null;
  created_date: string;
  is_active: boolean;
  tenant_directory: string;
  owner: string;
  changed_by: string;
  property: any;
}

export interface Profile {
  balance: number;
  id: number;
  code: string;
  first_name: string;
  last_name: string;
  gender: number;
  date_of_birth: string;
  identification_number: string;
  bio: null;
  website: null;
  phone1: string;
  phone2: null;
  phone3: null;
  order: number;
  groups: any[];
  changed_date: string;
  published_date: null;
  created_date: string;
  status: number;
  type: number;
  identification_type: number;
  user: number;
  tenant_directory: number;
  addresses: any[];
  wish_list: WishList[];
  subscriptions: any[];
}

export interface RegisterRequest {
  is_superuser: boolean;
  email: string;
  migration_key: number;
  is_staff: boolean;
  is_active: boolean;
  groups: Array<any>;
  user_permissions: Array<any>;
}
export interface JwtTokenResponse {
  refresh: string;
  access: string;
}
export interface UserRequest {
  is_superuser: boolean;
  email: string;
  migration_key: number;
  is_staff: boolean;
  is_active: boolean;
  groups: any[];
  user_permissions: any[];
}
