import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '@app/pages/properties/properties-search/search.service';

@Component({
  selector: 'app-buying-selling',
  templateUrl: './buying-selling.component.html',
  styleUrls: ['./buying-selling.component.scss'],
})
export class BuyingSellingComponent {
  constructor(private searchService: SearchService, private router: Router) {}

  buying(): void {
    this.searchService.search();
  }

  selling(): void {
    this.router.navigate(['/submit-property']);
  }
}
