import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class platformService {
  public isBrowser = false;

  constructor(@Inject(PLATFORM_ID) platform: any) {
    if (isPlatformBrowser(platform)) {
      this.isBrowser = true;
    }
  }
}
