import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-chip',
  templateUrl: './message-chip.component.html',
  styleUrls: ['./message-chip.component.scss'],
})
export class MessageChipComponent {
  @Input() message = null;
}
