import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@app/core/services/web.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { Observable } from 'rxjs';
import { Endpoints } from '../../utils/endpoints.dev';
import { DataType, ListingType } from './options.models';

@Injectable({ providedIn: 'root' })
export class OptionsService extends WebService<any> {
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  getManyDataTypes(request: any): Observable<PagedList<DataType>> {
    return this.httpClient.get<PagedList<DataType>>(request.url ?? Endpoints.BASE_URL + Endpoints.DATA_TYPES + '?limit=999', {
      headers: this.headers,
    });
  }

  getManyStatus(request: any): Observable<PagedList<DataType>> {
    return this.httpClient.get<PagedList<DataType>>(request.url ?? Endpoints.BASE_URL + Endpoints.STATUS, {
      headers: this.headers,
    });
  }

  getManyListingTypes(request: any): Observable<PagedList<ListingType>> {
    return this.httpClient.get<PagedList<ListingType>>(request.url ?? Endpoints.BASE_URL + Endpoints.LISTING_TYPES + '?limit=999', {
      headers: this.headers,
    });
  }
}
