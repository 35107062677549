import { Component, Input, OnInit } from '@angular/core';
import { AppSettings, Settings } from '@app/core/app.settings';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent implements OnInit {
  @Input() step = 1;
  public settings: Settings;
  constructor(public appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): any {
    this.settings.loadMore.step = this.step;
  }

  public startLoad(): any {
    this.settings.loadMore.start = true;
    this.settings.loadMore.load = true;
  }
}
