import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogHeaderControlsComponent } from '@app/components/dialog-header-controls/dialog-header-controls.component';
import { AppSettings } from '@app/core/app.settings';
import { DirectivesModule } from '@app/core/directives/directives.module';
import { AppService } from '@app/core/services/app.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';

@NgModule({
  imports: [CommonModule, DirectivesModule],
  exports: [DialogHeaderControlsComponent, DirectivesModule],
  declarations: [DialogHeaderControlsComponent],
  providers: [AppSettings, AppService, LocalStorageService],
})
export class CoreModule {}
