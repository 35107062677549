import { Component, ViewChild } from '@angular/core';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent {
  @ViewChild('testimonialSwiper', { static: false }) swiper?: SwiperComponent;

  public testimonials = [
    {
      text: '10 /10 ; Enjoy browsing the site to source new properties and advertise my vacancies',
      author: 'Mohamed , S.',
      position: 'LandLord',
      image: 'assets/images/profile/adam.jpg',
    },
    {
      text: `Fantastic website, finally it's easy to get a sense of what warehousing space is going for in our area`,
      author: 'Brian , L.',
      position: 'Business Owner',
      image: 'assets/images/profile/ashley.jpg',
    },
    {
      text: 'Its great to have a specialised marketplace which is suited the types of properties we deal with daily',
      author: 'Matthew , A.',
      position: 'Commercial Property Broker',
      image: 'assets/images/profile/bruno.jpg',
    },
  ];

  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 16,
    grabCursor: true,
    navigation: {
      nextEl: '.testimonial-next',
      prevEl: '.testimonial-prev',
    },
    pagination: { clickable: true, el: '.testimonial-pagination' },
  };
}
