import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/components/confirm-dialog/confirm-dialog.component';
import { AuthComponentStore } from '@app/core/stores/auth/auth.component.store';
import { AuthState } from '@app/core/stores/auth/auth.model';
import { BlogComponentStore } from '@app/core/stores/blog/blog.component.store';
import { Endpoints } from '@app/core/utils/endpoints.dev';
import { Subscription } from 'rxjs';
import { HelperUtils } from '../../core/services/helper-utils.service';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss'],
})
export class BlogItemComponent implements OnChanges, OnDestroy {
  @Input() preview = true;
  @Input() blog: any;
  public html: any;
  public authState: AuthState;
  private subscriptions: Subscription[] = [];
  public flag = false;
  public isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platform: any,
    public router: Router,
    public dialog: MatDialog,
    private blogStore: BlogComponentStore,
    private sanitizer: DomSanitizer,
    private authStore: AuthComponentStore,
    private helperUtils: HelperUtils,
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;
      const authStoreSubscription = this.authStore.state$.subscribe((state) => {
        this.authState = state;
      });
      this.subscriptions.push(authStoreSubscription);
    }
  }

  setDefaultImage(): void {
    Object.assign(this.blog, {
      image: {
        small: Endpoints.IMAGE_NOT_FOUND,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * REQUIRED
  ngOnChanges(changes: SimpleChanges): void {
    if (this.blog?.blog_content) {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.blog?.blog_content);
    }

    if (!this.authState.loading && this.blog) {
      if (!this.preview && this.blog?.status?.name !== 'Published' && !this.authState.isAdmin && !this.flag) {
        this.flag = true;
        this.helperUtils.displayNoPermissionError();
      }
    }
  }

  viewBlog(id: any): any {
    this.router.navigate([`/blog/view/${this.helperUtils.getIDFromUrl(id)}`]);
  }

  getBlogLink(id: any): any {
    return ('blog/view/' + this.helperUtils.getIDFromUrl(id)).toString();
  }

  editBlog(): void {
    this.router.navigate([`/blog/edit/${this.blog.id}`]);
  }

  deleteBlog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: 'Delete this post?',
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.blogStore.remove(this.blog);
        this.router.navigate(['/blog']);
      }
    });
    this.subscriptions.push(dialogSubscription);
  }
}
