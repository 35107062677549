import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CompareOverviewComponent } from '@app/components/compare-overview/compare-overview.component';
import { FeaturedPropertiesComponent } from '@app/components/featured-properties/featured-properties.component';
import { PropertiesCarouselComponent } from '@app/components/properties-carousel/properties-carousel.component';
import { PropertiesSearchResultsFiltersComponent } from '@app/components/properties-search-results-filters/properties-search-results-filters.component';
import { PropertiesToolbarComponent } from '@app/components/properties-toolbar/properties-toolbar.component';
import { DigitalMarketingComponent } from '@app/components/what-we-offer-dialog/digital-marketing/digital-marketing.component';
import { HighQualityVettingComponent } from '@app/components/what-we-offer-dialog/high-quality-vetting/high-quality-vetting.component';
import { LargestPlatformComponent } from '@app/components/what-we-offer-dialog/largest-platform/largest-platform.component';
import { SpecializedPropertyPortalComponent } from '@app/components/what-we-offer-dialog/specialized-property-portal/specialized-property-portal.component';
import { BlogCarouselComponent } from '@app/pages/blogs/blog-carousel/blog-carousel.component';
import { PropertiesSearchComponent } from '@app/pages/properties/properties-search/properties-search.component';
import { SubmitPropertySelectionComponent } from '@app/pages/property-upload-selection-menu/submit-property-selection.component';
import { AgriculturalForSaleComponent } from '@app/pages/submit-property-features/agricultural-for-sale/agricultural-for-sale.component';
import { AgriculturalOnAuctionComponent } from '@app/pages/submit-property-features/agricultural-on-auction/agricultural-on-auction.component';
import { AgriculturalToLetComponent } from '@app/pages/submit-property-features/agricultural-to-let/agricultural-to-let.component';
import { CommercialForSaleComponent } from '@app/pages/submit-property-features/commercial-for-sale/commercial-for-sale.component';
import { CommercialOnAuctionComponent } from '@app/pages/submit-property-features/commercial-on-auction/commercial-on-auction.component';
import { CommercialToLetComponent } from '@app/pages/submit-property-features/commercial-to-let/commercial-to-let.component';
import { IndustrialForSaleComponent } from '@app/pages/submit-property-features/industrial-for-sale/industrial-for-sale.component';
import { IndustrialOnAuctionComponent } from '@app/pages/submit-property-features/industrial-on-auction/industrial-on-auction.component';
import { IndustrialToLetComponent } from '@app/pages/submit-property-features/industrial-to-let/industrial-to-let.component';
import { LandComponent } from '@app/pages/submit-property-features/land/land.component';
import { ResidentialForSaleComponent } from '@app/pages/submit-property-features/residential-for-sale/residential-for-sale.component';
import { ResidentialOnAuctionComponent } from '@app/pages/submit-property-features/residential-on-auction/residential-on-auction.component';
import { ResidentialToLetComponent } from '@app/pages/submit-property-features/residential-to-let/residential-to-let.component';
import { BuyingSellingComponent } from '@app/components/buying-selling/buying-selling.component';
import { SharedModule } from '@app/shared/shared.module';
import { InputFileModule } from 'ngx-input-file';
import { BlogItemComponent } from './blog-item/blog-item.component';
import { LoaderComponent } from './loader/loader.component';
import { MatPaginatorGotoComponent } from './mat-paginator-goto/mat-paginator-goto.component';
import { MessageChipComponent } from './message-chip/message-chip.component';
import { PropertyItemComponent } from './property-item/property-item.component';
import { RelatedPropertiesComponent } from './related-properties/related-properties.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedModule, InputFileModule],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CompareOverviewComponent,
    FeaturedPropertiesComponent,
    PropertyItemComponent,
    PropertyItemComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    RelatedPropertiesComponent,
    WhatWeOfferComponent,
    BlogCarouselComponent,
    BlogItemComponent,
    LoaderComponent,
    MatPaginatorGotoComponent,
    LandComponent,
    CommercialForSaleComponent,
    CommercialToLetComponent,
    CommercialOnAuctionComponent,
    AgriculturalForSaleComponent,
    AgriculturalToLetComponent,
    AgriculturalOnAuctionComponent,
    ResidentialForSaleComponent,
    ResidentialToLetComponent,
    ResidentialOnAuctionComponent,
    SubmitPropertySelectionComponent,
    IndustrialForSaleComponent,
    IndustrialToLetComponent,
    IndustrialOnAuctionComponent,
    InputFileModule,
    MessageChipComponent,
    BuyingSellingComponent,
  ],
  declarations: [
    WhatWeOfferComponent,
    CompareOverviewComponent,
    FeaturedPropertiesComponent,
    PropertyItemComponent,
    PropertyItemComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    RelatedPropertiesComponent,
    BlogCarouselComponent,
    BlogItemComponent,
    LoaderComponent,
    MatPaginatorGotoComponent,
    LandComponent,
    CommercialForSaleComponent,
    CommercialToLetComponent,
    CommercialOnAuctionComponent,
    AgriculturalForSaleComponent,
    AgriculturalToLetComponent,
    AgriculturalOnAuctionComponent,
    ResidentialForSaleComponent,
    ResidentialToLetComponent,
    ResidentialOnAuctionComponent,
    SubmitPropertySelectionComponent,
    IndustrialForSaleComponent,
    IndustrialToLetComponent,
    IndustrialOnAuctionComponent,
    MessageChipComponent,
    SpecializedPropertyPortalComponent,
    LargestPlatformComponent,
    DigitalMarketingComponent,
    HighQualityVettingComponent,
    BuyingSellingComponent,
  ],
})
export class ComponentsModule {}
