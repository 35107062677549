import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import {
  MatLegacyProgressSpinnerDefaultOptions as MatProgressSpinnerDefaultOptions,
  MatLegacyProgressSpinnerModule as MatProgressSpinnerModule,
  MAT_LEGACY_PROGRESS_SPINNER_DEFAULT_OPTIONS as MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
} from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AdsenseColumnComponent } from '@app/components/adsense-column/adsense-column.component';
import { AlertDialogComponent } from '@app/components/alert-dialog/alert-dialog.component';
import { ClientsComponent } from '@app/components/clients/clients.component';
import { CommentsComponent } from '@app/components/comments/comments.component';
import { ConfirmDialogComponent } from '@app/components/confirm-dialog/confirm-dialog.component';
import { ContactsComponent } from '@app/components/contacts/contacts.component';
import { GetInTouchComponent } from '@app/components/get-in-touch/get-in-touch.component';
import { LoadMoreComponent } from '@app/components/load-more/load-more.component';
import { MissionComponent } from '@app/components/mission/mission.component';
import { OurAgentsComponent } from '@app/components/our-agents/our-agents.component';
import { OurServicesComponent } from '@app/components/our-services/our-services.component';
import { RatingComponent } from '@app/components/rating/rating.component';
import { ShareDialogComponent } from '@app/components/share-page/dialog/share-page.dialog.component';
import { SharePageComponent } from '@app/components/share-page/share-page.component';
import { TestimonialsComponent } from '@app/components/testimonials/testimonials.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AdsenseModule } from 'ng2-adsense';
import { CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ShareModule } from 'ngx-sharebuttons';
import { BannerComponent } from '../components/banner/banner.component';
import { SwiperModule } from 'swiper/angular';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: 'R ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true,
};

const DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS: MatProgressSpinnerDefaultOptions = {
  diameter: 100,
  strokeWidth: 3,
};

@NgModule({
  imports: [
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    ShareModule,
    AngularEditorModule,
    NgxChartsModule,
    // MatCurrencyFormatModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-1157683888635915',
      adSlot: '8504996509',
    }),
    SwiperModule,
  ],
  exports: [
    SwiperModule,
    NgxCurrencyModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    LoadMoreComponent,
    RatingComponent,
    CommentsComponent,
    ClientsComponent,
    MissionComponent,
    OurAgentsComponent,
    OurServicesComponent,
    TestimonialsComponent,
    GetInTouchComponent,
    BannerComponent,
    SharePageComponent,
    AngularEditorModule,
    AlertDialogComponent,
    ShareDialogComponent,
    ConfirmDialogComponent,
    AdsenseModule,
    AdsenseColumnComponent,
    NgxChartsModule,
  ],
  declarations: [
    BannerComponent,
    CommentsComponent,
    LoadMoreComponent,
    RatingComponent,
    ClientsComponent,
    MissionComponent,
    OurAgentsComponent,
    OurServicesComponent,
    TestimonialsComponent,
    GetInTouchComponent,
    BannerComponent,
    ContactsComponent,
    SharePageComponent,
    AlertDialogComponent,
    ShareDialogComponent,
    ConfirmDialogComponent,
    AdsenseColumnComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS,
    },
  ],
})
export class SharedModule {}
