import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements AfterViewInit, OnDestroy {
  @Input() zoneID: number;
  public adUUID = uuid();
  public tag: HTMLModElement;
  private referrerURL: string;
  private subscriptions: Subscription[] = [];

  // * FOR AD BLOCKERS
  public hide = true;

  constructor(@Inject(PLATFORM_ID) private platform: any, @Inject(DOCUMENT) private dom: any, public mediaObserver: MediaObserver, private http: HttpClient) {
    this.referrerURL = this.dom.URL;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      const banner = this.http
        .get(
          `https://adserver.capx2.com/www/delivery/asyncspc.php?zones=${
            this.zoneID ?? 5 // * 5 = ZONEID FOR HORIZONTAL BANNER
          }&prefix=revive-0-&loc=${this.referrerURL}&referer=${this.referrerURL}`,
        )
        .pipe(
          tap({
            next: (res) => {
              this.hide = false;
              res['revive-0-0'].html = res['revive-0-0']?.html.replace(`width='728'`, `width='100%'`).replace(`height='90'`, `height='100%' style='border: 0px solid red; border-radius: 4px;'`);
              // * SUPRESS ERROR MESSAGE
              try {
                document.getElementById(this.adUUID).innerHTML = res['revive-0-0']?.html;
              } catch (error) {}
            },
            error: () => {
              this.hide = true;
            },
          }),
        )
        .subscribe();
      this.subscriptions.push(banner);
    }
  }
}
