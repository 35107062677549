<div>
  <div fxLayout="column" fxFill class="p-3">
    <h1 class="fw-500 text-center">Residential To Let</h1>
    <p class="text-center" style="color: red">Fields marked with a * are required</p>
  </div>
  <form [formGroup]="featuresForm" fxLayout="row wrap" (ngSubmit)="onSubmit()">
    <div fxFlex="100">
      <div fxFlex class="px-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Listing Price</mat-label>
          <input type="text" currencyMask matInput placeholder="Enter absolute price" required formControlName="price" autocomplete="off" />
          <mat-error *ngIf="featuresForm.controls.price.errors?.required">Field is required </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="100px" fxLayoutAlign="end center" class="px-2" style="height: 50px">
        <mat-checkbox formControlName="poa" (change)="poaPriceEvent($event.checked)"> POA </mat-checkbox>
      </div>
    </div>

    <div fxFlex class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Price per m²</mat-label>
        <input type="text" currencyMask matInput placeholder="Rental per m²" formControlName="rentalPerM2" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls['rentalPerM2']?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100px" fxLayoutAlign="end center" class="px-2" style="height: 50px">
      <mat-checkbox formControlName="rentalPerM2Poa" (change)="poaPriceHaEvent($event.checked)"> POA </mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Size</mat-label>
        <input type="number" matInput placeholder="Size" formControlName="size" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.size?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="petFriendly"> Pet Friendly</mat-checkbox>
    </div>

    <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="garden"> Garden</mat-checkbox>
    </div>

    <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="pool"> Pool</mat-checkbox>
    </div>

    <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
      <mat-checkbox formControlName="secureEstate"> Secure estate</mat-checkbox>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Bedrooms</mat-label>
        <input type="number" matInput placeholder="Bedrooms" formControlName="bedrooms" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.bedrooms?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Bathrooms</mat-label>
        <input type="number" matInput placeholder="Bathrooms" formControlName="bathrooms" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.bathrooms?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Total Parkings</mat-label>
        <input type="number" matInput placeholder="Total Parkings" formControlName="totalParkings" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.totalParkings?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100">
      <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="electricity"> Electricity</mat-checkbox>
      </div>

      <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="water"> Water</mat-checkbox>
      </div>

      <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="wifi"> Wifi</mat-checkbox>
      </div>

      <div fxFlex="25" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="dstv"> DSTV</mat-checkbox>
      </div>
    </div>

    <div fxFlex="100">
      <div fxFlex="33" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="domesticWorker"> Domestic Worker</mat-checkbox>
      </div>

      <div fxFlex="33" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="securityService"> Security Service</mat-checkbox>
      </div>

      <div fxFlex="34" class="mb-4 pl-2" fxLayoutAlign="center center">
        <mat-checkbox formControlName="gardenService"> Garden Service</mat-checkbox>
      </div>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Living Rooms</mat-label>
        <input type="number" matInput placeholder="Living Rooms" formControlName="livingRooms" required autocomplete="off" />
        <mat-error *ngIf="featuresForm?.controls?.livingRooms?.errors?.required">Field is required </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxLayoutAlign="center space-evenly" class="py-2">
      <mat-radio-group formControlName="residentialBuildingType">
        <mat-radio-button fxFlex="34" [checked]="true" class="px-5" value="'3'"> House </mat-radio-button>
        <mat-radio-button fxFlex="33" class="px-5" checked value="'1'"> Flats/Apartment </mat-radio-button>
        <mat-radio-button fxFlex="33" class="px-5" value="'2'"> Complexes </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Rates</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="rates" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" class="px-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Monthly Levies</mat-label>
        <input type="text" currencyMask matInput placeholder="R per month" formControlName="levies" autocomplete="off" />
      </mat-form-field>
    </div>

    <div fxFlex="100" fxLayoutAlign="center space-evenly" class="py-2">
      <mat-radio-group formControlName="propertyType">
        <mat-radio-button fxFlex="33" class="px-5" [checked]="true" value="'1'"> Freehold </mat-radio-button>

        <mat-radio-button fxFlex="33" class="px-5" value="'2'"> Sectional Title </mat-radio-button>

        <mat-radio-button fxFlex="34" class="px-5" value="'3'"> Share Block </mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="100" class="step-section pb-2">
      <p class="mb-0"><span class="uppercase fw-500">Property Documents</span><span class="text-muted mx-3">(max 8 files, optional)</span></p>
      <input-file (deletedFile)="deleteDocumentEvent($event)" formControlName="documents" fileLimit="8" sizeLimit="2" fileAccept="*"> </input-file>
    </div>
  </form>
</div>
