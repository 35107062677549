import { Injectable } from '@angular/core';
import { LocalStorageService } from './services/local-storage.service';

export class Settings {
  constructor(
    public name: string,
    public theme: string,
    public toolbar: number,
    public stickyMenuToolbar: boolean,
    public header: string,
    public rtl: boolean,
    public searchPanelVariant: number,
    public searchOnBtnClick: boolean,
    public currency: string,
    public mainToolbarFixed: boolean,
    public contentOffsetToTop: boolean,
    public headerBgImage: boolean,
    public headerBgVideo: boolean,
    public loadMore: {
      start: boolean;
      step: number;
      load: boolean;
      page: number;
      complete: boolean;
      result: number;
    },
  ) {}
}

@Injectable()
export class AppSettings {
  public settings: Settings;

  constructor(private localStorageService: LocalStorageService) {
    this.settings = new Settings(
      'CAPX2', // theme name
      this.localStorageService.getItem('theme') ?? 'capx2', // blue, green, red, pink, purple, grey, orange-dark, capx2, capx2-dark
      1, // 1 or 2
      true, // true = sticky, false = not sticky
      'image', // default, image, carousel, map, video
      false, // true = rtl, false = ltr
      1, //  1, 2  or 3
      true, //  true = search on button click
      'ZAR', // USD, EUR

      // NOTE:  don't change additional options values, they used for theme performance
      false,
      false,
      false,
      false,
      {
        start: false,
        step: 1,
        load: false,
        page: 1,
        complete: false,
        result: 0,
      },
    );
  }
}
