import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { Attribute, AttributeAction, AttributeState } from './attribute.model';
import { AttributeService } from './attribute.service';

const DEFAULT_STATE: AttributeState = {
  attributes: null,
  action: AttributeAction.INIT,
};

@Injectable({ providedIn: 'root' })
export class AttributeComponentStore extends ComponentStore<AttributeState> {
  constructor(private attributeService: AttributeService, private helperUtils: HelperUtils) {
    super(DEFAULT_STATE);
  }

  readonly appendAttributes = this.updater((state, newAttributes: Attribute[]) => ({
    ...state,
    attributes: [...(state.attributes ?? []), ...newAttributes],
  }));

  readonly fetchMany = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.attributeService.getMany(options).pipe(
          tapResponse(
            (response: PagedList<Attribute>) => {
              this.appendAttributes(response.results);

              // * RECURSIVE
              if (response.next) {
                this.fetchMany({ url: response.next });
              }
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly removePropertyAttribute = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.attributeService.removePropertyAttribute(options).pipe(
          tapResponse(
            (response: any) => {
              // * RETURNS 201, will not trigger this
            },
            (error: HttpErrorResponse) => {
              if (error.status >= 400) {
                this.helperUtils.displayErrorMessageToast('There was an error deleting property attributes');
              }
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly createPropertyAttribute = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.attributeService.createPropertyAttribute(options).pipe(
          tapResponse(
            (response: any) => {
              // * RETURNS 201, will not trigger this
            },
            (error: HttpErrorResponse) => {
              if (error.status >= 400) {
                this.patchState({
                  action: AttributeAction.ATTRIBUTE_CREATION_FAILED,
                });
                this.helperUtils.displayErrorMessageToast('There was an error creating property attributes');
                this.patchState({
                  action: AttributeAction.INIT,
                });
              }
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });
}
