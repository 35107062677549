import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.scss'],
})
export class SocialIconsComponent {
  @Input() iconSize = '';
  @Input() iconColor = '';
  constructor() {}
}
