import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.scss'],
})
export class LandComponent implements OnInit {
  @Output() featuresFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  public featuresForm: UntypedFormGroup;

  deleteDocumentEvent(event: any): void {
    this.documentDeleteEvent.emit(event);
  }

  constructor(private fb: UntypedFormBuilder, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      extentOfYard: [null, Validators.required],
      extentOfYardMeasurement: ['ha'],
      power: [false],
      powerOptions: [{ value: 'KVA', disabled: true }],
      heightToEaves: ['', Validators.required],
      heightToEavesVariable: [false],
      interlinkAccess: [false],
      gbaExtent: [null, Validators.required],
      gbaExtentMeasurement: ['ha'],
      erfExtent: [null, Validators.required],
      erfExtentMeasurement: ['ha'],
      gla: [null, Validators.required],
      glaMeasurement: ['m²'],
      extentOfOfficeComponents: [null],
      extentOfOfficeComponentsMeasurement: ['ha'],
      dockLevellers: [null],
      enGradeRollerShutterDoors: [null],
      freeholdSectional: [null],
      freeholdSectionalDocuments: [null],
      securityFeatures: [null],
      floorLoadCapacity: [null],
      vacantLet: [null],
      vacantLetDocuments: [null],
      rates: [null],
      levies: [null],
      undercoverParkings: [null],
      openParkings: [null],
      zoning: ['Business 1'],
      zoningCertificates: [null],
    });

    this.featuresService.setFeaturesForm(this.featuresForm);
  }

  onPowerChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.powerOptions.enable();
      return;
    }
    this.featuresForm.controls.powerOptions.disable();
  }

  onHeightToEavesChange(event: any): void {
    if (event.checked) {
      this.featuresForm.controls.heightToEaves.disable();
      this.featuresForm.controls.heightToEaves.setValue('');
      return;
    }
    this.featuresForm.controls.heightToEaves.enable();
  }

  onSubmit(): any {
    if (this.featuresForm.valid) {
      this.featuresFormSubmitEvent.emit(this.featuresForm);
    }
  }
}
