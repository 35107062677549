<div class="section mt-0">
  <div class="mx-1">
    <div class="theme-container">
      <h1 class="section-title">Related properties</h1>

      <app-loader *ngIf="($relatedProperties | async) === null"></app-loader>

      <app-message-chip *ngIf="($relatedProperties | async)?.count === -1"></app-message-chip>

      <div *ngIf="($relatedProperties | async)?.count > 0">
        <app-properties-carousel [properties]="$relatedProperties | async"></app-properties-carousel>
      </div>

      <div class="theme-container" *ngIf="($relatedProperties | async)?.count === 0">
        <h3 class="text-center p-3">No Related Properties Found</h3>
      </div>
    </div>
  </div>
</div>
