import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@app/core/services/web.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { Observable } from 'rxjs';
import { Endpoints } from '../../utils/endpoints.dev';
import { Attribute } from './attribute.model';

@Injectable({ providedIn: 'root' })
export class AttributeService extends WebService<Attribute> {
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  getMany(request: any): Observable<PagedList<Attribute>> {
    return this.httpClient.get<PagedList<Attribute>>(request.url ?? Endpoints.BASE_URL + Endpoints.DATA_ATTRIBUTES + '?expand=attribute_option,attribute_option.options&limit=999', { headers: this.headers });
  }

  createPropertyAttribute(request: any): Observable<any> {
    return this.httpClient.post(Endpoints.BASE_URL + Endpoints.PROPERTY_ATTRIBUTES, request, {
      headers: this.headers,
    });
  }

  removePropertyAttribute(url: string): Observable<any> {
    return this.httpClient.delete(url, {
      headers: this.headers,
    });
  }
}
