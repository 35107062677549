import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@app/core/services/app.service';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { AttributeComponentStore } from '@app/core/stores/attributes/attribute.component.store';
import { AttributeState } from '@app/core/stores/attributes/attribute.model';
import { OptionsComponentStore } from '@app/core/stores/options/options.component.store';
import { OptionsState } from '@app/core/stores/options/options.models';
import { PropertyComponentStore } from '@app/core/stores/property/property.component.store';
import { ThemeStore } from '@app/core/stores/theme/theme.store';
import { Subscription } from 'rxjs';
import * as citiesJson from 'src/assets/data/cities.json';
import { SearchService } from './search.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss'],
})
export class PropertiesSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() variant = 1;
  @Input() vertical = false;
  @Input() searchOnBtnClick = false;
  @Input() removedSearchField: string;
  @Output() searchChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchClickEvent: EventEmitter<any> = new EventEmitter<any>();

  public showMore = false;
  public form: UntypedFormGroup;
  public propertyStatuses = [];
  public cities = Object.values(citiesJson);
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public subscriptions: Subscription[] = [];
  public theme = 'capx2';
  public locationAutoCompleteSuggestions = [];
  public attributeState: AttributeState;
  public optionsState: OptionsState;
  public priceAutoComplete = [1000, 2500, 5000, 10000, 25000, 50000, 100000, 125000, 150000, 250000, 500000, 750000, 1000000, 1500000, 1750000, 2000000, 5000000, 7500000, 10000000, 25000000, 50000000, 75000000, 100000000];
  public publishDateFilterOptions = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Past 7 Days', value: 'week' },
    { name: 'This Month', value: 'month' },
    { name: 'This Year', value: 'year' },
    { name: 'All Time', value: null },
  ];
  public propertyTypeOptions = [];
  public listingTypeOptions = [];

  constructor(
    public appService: AppService,
    public fb: UntypedFormBuilder,
    public helperUtils: HelperUtils,
    private themeStore: ThemeStore,
    private propertyStore: PropertyComponentStore,
    private attributeStore: AttributeComponentStore,
    private optionsStore: OptionsComponentStore,
    private searchService: SearchService,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
    if (isPlatformBrowser(platform)) {
      const optionsStoreSubscription = this.optionsStore.state$.subscribe((state) => {
        this.optionsState = state;

        if (this.optionsState.listingTypes) {
          this.listingTypeOptions = [];
          this.listingTypeOptions.push({ name: 'All', value: '' });
          this.optionsState.listingTypes.sort((a, b) => a.order - b.order);
          this.optionsState.listingTypes.forEach((element) => {
            if (element.show_on_search && element.is_active) {
              this.listingTypeOptions.push({
                name: element.name,
                value: this.helperUtils.getIDFromUrl(element.id).toString(),
              });
            }
          });
        }

        if (this.optionsState.dataTypes) {
          this.propertyTypeOptions = [];
          this.propertyTypeOptions.push({ name: 'All', value: '' });
          const propTypeOptions = this.optionsState.dataTypes.filter((x: any) => +this.helperUtils.getIDFromUrl(x.category) === 2);
          propTypeOptions.forEach((element: any) => {
            if (element.is_active) {
              this.propertyTypeOptions.push({
                name: element.name,
                value: this.helperUtils.getIDFromUrl(element.id).toString(),
              });
            }
          });
        }
      });
      this.subscriptions.push(optionsStoreSubscription);
      this.optionsStore.patchState({ dataTypes: [] });
      this.optionsStore.patchState({ listingTypes: [] });

      this.optionsStore.fetchManyDataTypes({});
      this.optionsStore.fetchManyListingTypes({});

      const attributesStoreSubscription = this.attributeStore.state$.subscribe((state) => {
        this.attributeState = state;
      });

      this.attributeStore.patchState({ attributes: [] });
      this.attributeStore.fetchMany({});
      this.subscriptions.push(attributesStoreSubscription);

      const themeStoreSubscription = this.themeStore.state$.subscribe((state) => {
        this.theme = state.theme;
      });
      this.subscriptions.push(themeStoreSubscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      if (this.vertical) {
        this.showMore = true;
      }

      this.form = this.searchService.searchForm;

      const formSubscription = this.searchService.searchForm.valueChanges.subscribe(() => {
        if (!this.searchOnBtnClick) {
          window.scrollTo(0, 0);
        }
        this.propertyStore.patchState({
          fetchingProperties: true,
          properties: null,
        });
        if (this.searchService.searchForm.value.listingType === null) {
          this.searchService.searchForm.patchValue({
            listingType: '',
          });
        }
        if (this.searchService.searchForm.value.propertyType === null) {
          this.searchService.searchForm.patchValue({
            propertyType: '',
          });
        }
        this.form = this.searchService.searchForm;
      });
      this.subscriptions.push(formSubscription);

      const locationSubscription = this.form.controls.city.valueChanges.subscribe((change: string) => {
        if (change !== '') {
          this.locationAutoCompleteSuggestions = this.cities.filter((x) => x?.name?.toUpperCase().includes(change?.toUpperCase()));
        }
      });
      this.subscriptions.push(locationSubscription);
    }
  }

  ngOnChanges(): void {
    if (isPlatformBrowser(this.platform)) {
      if (this.removedSearchField) {
        if (this.removedSearchField.indexOf('.') > -1) {
          const arr = this.removedSearchField.split('.');
          this.form.controls[arr[0]]['controls'][arr[1]].reset();
        } else if (this.removedSearchField.indexOf(',') > -1) {
          const arr = this.removedSearchField.split(',');
          this.form.controls[arr[0]]['controls'][arr[1]].controls.selected.setValue(false);
        } else {
          this.form.controls[this.removedSearchField].reset();
        }
      }
    }
  }

  public reset(): void {
    this.searchService.resetSearchForm();
  }

  public search(): void {
    this.searchService.searchForm.patchValue({ page: 1 });
    this.searchService.search();
  }

  public getAppearance(): string {
    return this.variant !== 3 ? 'outline' : '';
  }

  public getFloatLabel(): string {
    return this.variant === 1 ? 'always' : '';
  }
}
