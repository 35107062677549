import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HelperUtils } from '@app/core/services/helper-utils.service';
import { PagedList } from '@app/core/models/paged-list.model';
import { BlogsComponent } from '@app/pages/blogs/blogs.component';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, delay } from 'rxjs/operators';
import { Blog, BlogState } from './blog.models';
import { BlogService } from './blog.service';

export const DEFAULT_STATE: BlogState = {
  blogs: null,
  blog: null,
};

@Injectable({ providedIn: 'root' })
export class BlogComponentStore extends ComponentStore<BlogState> {
  constructor(private blogService: BlogService, private helperUtils: HelperUtils, private router: Router) {
    super(DEFAULT_STATE);
  }

  readonly fetchOne = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        this.patchState({ blog: null });
        return this.blogService.getOne(options).pipe(
          tapResponse(
            (response: any) => {
              this.patchState({
                blog: response,
              });
            },
            (error: HttpErrorResponse) => {
              this.router.navigate(['/blog']);
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly fetchMany = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      // * TEST DELAY
      // delay(1000),
      concatMap((options: any) => {
        this.patchState({ blogs: null });
        return this.blogService.getMany(options).pipe(
          tapResponse(
            (response: any) => {
              // REMOVE DELETED BLOGS
              response.results = response.results?.filter((x: any) => x.status.name !== 'Deleted');

              this.patchState({
                blogs: response,
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                blogs: new PagedList<any>({
                  count: -1,
                  next: null,
                  previous: null,
                  results: [],
                }),
              });
            },
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly create = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.blogService.create(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Successfully created blog');
              this.router.navigate(['/blog/']);
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly save = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.blogService.save(options).pipe(
          tapResponse(
            (response: any) => {
              this.helperUtils.displaySuccessToast('Successfully saved blog');
              this.router.navigate(['/blog/']);
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  readonly remove = this.effect((options$: Observable<any>) => {
    return options$.pipe(
      concatMap((options: any) => {
        return this.blogService.remove(options).pipe(
          tapResponse(
            (response: any) => {
              this.fetchMany({});
              this.helperUtils.displaySuccessToast('Successfully deleted blog');
              // this.router.navigate(['/blog/']);
            },
            (error: HttpErrorResponse) => {},
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });
}
