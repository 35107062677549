<mat-toolbar fxLayoutAlign="space-between center">
  <a routerLink="/compare" (click)="hideSheet(true)" mat-raised-button color="primary">Compare - {{ properties.length }}</a>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-icon-button (click)="appService.Data.compareList.length = 0; hideSheet(false)" matTooltip="Clear Compare List">
      <mat-icon color="warn" class="mat-icon-lg">cancel</mat-icon>
    </button>
    <button mat-icon-button (click)="hideSheet(false)" matTooltip="Hide">
      <mat-icon color="primary" class="mat-icon-lg">arrow_drop_down_circle</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-nav-list>
  <a mat-list-item *ngFor="let property of properties" [routerLink]="['/properties', property.id]" (click)="hideSheet(true)">
    <h3 mat-line class="fw-500">{{ property.title }}</h3>
    <i mat-line
      ><mat-icon class="mat-icon-sm text-muted">location_on</mat-icon><small>{{ property.formattedAddress }}</small></i
    >
    <span *ngIf="settings.currency === 'USD'" class="primary-color price fw-500 mx-2">
      <span *ngIf="property.priceDollar.sale">{{ property.priceDollar.sale | currency: 'USD':'symbol':'1.0' }}</span>
      <span *ngIf="property.priceDollar.rent">{{ property.priceDollar.rent | currency: 'USD':'symbol':'1.0' }} /month</span>
    </span>
    <span *ngIf="settings.currency === 'EUR'" class="primary-color price fw-500 mx-2">
      <span *ngIf="property.priceEuro.sale">{{ property.priceEuro.sale | currency: 'EUR':'symbol':'1.0' }}</span>
      <span *ngIf="property.priceEuro.rent">{{ property.priceEuro.rent | currency: 'EUR':'symbol':'1.0' }} /month</span>
    </span>
    <button mat-icon-button color="warn" class="remove" (click)="remove(property, $event)" matTooltip="Remove" matTooltipPosition="after">
      <mat-icon class="mat-icon-sm">close</mat-icon>
    </button>
  </a>
</mat-nav-list>
