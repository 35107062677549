import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ThemeStore } from '@app/core/stores/theme/theme.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
})
export class Toolbar1Component implements OnDestroy {
  @Output() menuIconClickEvent: EventEmitter<any> = new EventEmitter<any>();
  public theme = 1;
  private subscriptions: Subscription[] = [];
  constructor(private themeStore: ThemeStore) {
    const themeStoreSubscription = this.themeStore.state$.subscribe((state) => {
      this.theme = state.theme === 'capx2-dark' ? 1 : 2;
    });
    this.subscriptions.push(themeStoreSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public sidenavToggle(): void {
    this.menuIconClickEvent.emit();
  }
}
