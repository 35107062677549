import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/pages/not-found/not-found.component';
import { BaseLayoutComponent } from '@app/theme/layout/base-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        data: { revalidate: 60 },
      },
      {
        path: 'calculators',
        loadChildren: () => import('./pages/calculators/calculators.module').then((m) => m.CalculatorsModule),
        data: { revalidate: 60 },
      },
      {
        path: 'blog',
        loadChildren: () => import('./pages/blogs/blogs.module').then((m) => m.BlogsModule),
        data: { revalidate: 60 },
      },
      {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
        data: { revalidate: 60 },
      },
      {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule),
        data: { revalidate: 60 },
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
        data: { revalidate: 60 },
      },
      {
        path: 'properties',
        loadChildren: () => import('./pages/properties/properties.module').then((m) => m.PropertiesModule),
        data: { revalidate: 60 },
      },
      {
        path: 'properties/:city/:propertyType/:listingType',
        loadChildren: () => import('./pages/properties/properties.module').then((m) => m.PropertiesModule),
        data: { revalidate: 60 },
      },
      // {
      //     path: "agents",
      //     loadChildren: () =>
      //         import("./pages/agents/agents.module").then(
      //             (m) => m.AgentsModule
      //         ),
      // },
      // {
      //     path: "compare",
      //     loadChildren: () =>
      //         import("./pages/compare/compare.module").then(
      //             (m) => m.CompareModule
      //         ),
      // },
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pricing/pricing.module').then((m) => m.PricingModule),
        data: { revalidate: 60 },
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
        data: { revalidate: 60 },
      },
      {
        path: 'terms-conditions',
        loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then((m) => m.TermsConditionsModule),
        data: { revalidate: 60 },
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
        data: { revalidate: 60 },
      },
      {
        path: 'submit-property',
        loadChildren: () => import('./pages/submit-property/submit-property.module').then((m) => m.SubmitPropertyModule),
        data: { revalidate: 60 },
      },
      {
        path: 'edit-property',
        loadChildren: () => import('./pages/edit-property/edit-property.module').then((m) => m.EditPropertyModule),
        data: { revalidate: 60 },
      },
      // * COMMENT TO DISABLE NAVBAR/FOOTER
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
        data: { revalidate: 60 },
      },
      {
        path: 'checkout',
        loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
        data: { revalidate: 60 },
      },
      {
        path: 'thank-you',
        loadChildren: () => import('./pages/thank-you/thank-you.module').then((m) => m.ThankYouModule),
        data: { revalidate: 60 },
      },
      {
        path: 'transaction-declined',
        loadChildren: () => import('./pages/transaction-failed/transaction-failed.module').then((m) => m.TransactionFailedModule),
        data: { revalidate: 60 },
      },
    ],
  },
  // * UNCOMMENT TO ENABLE NO NAVBAR/FOOTER
  // {
  //     path: 'auth',
  //     loadChildren: () =>
  //         import('./pages/auth/auth.module').then((m) => m.AuthModule),
  // },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      // * KEEP THIS
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'ignore',
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
