import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { ComponentStore } from '@ngrx/component-store';

export interface ThemeState {
  theme: string;
}

@Injectable()
export class ThemeStore extends ComponentStore<ThemeState> {
  constructor(private localStorageService: LocalStorageService) {
    super({ theme: 'capx2' });

    const theme = this.localStorageService.getItem('theme') ?? 'capx2';

    if (theme !== 'capx2') {
      this.changeTheme(theme);
    }
  }

  readonly changeTheme = this.updater((state: ThemeState, theme: string) => {
    return {
      ...state,
      theme,
    };
  });
}
