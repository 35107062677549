export const environment = {
  production: false,
  hostName: 'https://dev-api.capx2.com/api/v1/',
  alerts: {
    enabled: true,
    displayDuration: 7000,
  },
  simulation: {
    enabled: true,
    networkSimulationRate: 0,
    defaultDelayRate: 0,
  },
  defaults: {
    pageSize: 10,
    sessionIdle: 60,
    sessionTimeOut: 240,
  },
  Logging: {
    enabled: true,
    logType: 'console',
    logLevel: 4,
  },
  authTokenKey: 'B8A0EF99-8DC5-4E79-BCE1-2E6BDD330AEC',
  build: {
    name: 'Dev',
    major: '2021',
    minor: '12',
    relTag: 'Rel 1',
  },
};
